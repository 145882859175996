import { useJourneyResultState } from '../stores/journey-planner/JourneySearchState'
import classNames from 'classnames'
import { useActiveTabState } from '../stores/TabNavigationState'
import { TabTypes } from '../types/tab-name-types'
import PanelSection from './layout/panel-section'
import Heading from './common/heading'
import { RouteResults } from './journey-planner/route-results'

export default function JourneyRouteListing({ selectedRouteId, title }: { selectedRouteId: number; title: string }) {
  const journeyResult = useJourneyResultState().get()
  const activeTabState = useActiveTabState()

  const routeListClasses = classNames('c-route-results', {
    'c-route-results--tab-active': activeTabState.get() === TabTypes.LIST,
  })

  let display = <PanelSection>Loading</PanelSection>
  if (journeyResult?.routes?.length) {
    display = (
      <div className={routeListClasses}>
        <div className='c-route-results__headings'>
          <Heading level={2}>Results ({journeyResult.routes.length})</Heading>
          <Heading level={3} icon='car'>
            Routes by car
          </Heading>
        </div>
        <RouteResults showSelectedOnly={false} />
      </div>
    )
  }
  return display
}
