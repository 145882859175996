import React, { forwardRef, useState } from 'react'
import FormField from '../../../forms/form-field'
import Autocomplete from 'react-google-autocomplete'
import { RouteClear } from '../route-clear/index'
import { useGoogleLoadState } from '../../../../stores/GoogleMapState'
import Icon from '../../../common/icon'

export interface RouteInputProps {
  apiKey: string
  onPlaceSelected: ((
    places: google.maps.places.PlaceResult,
    ref: React.RefObject<HTMLInputElement>,
    autocompleteRef: React.RefObject<google.maps.places.Autocomplete>,
  ) => void) &
    ((place: google.maps.places.PlaceResult) => void)
  options: google.maps.places.AutocompleteOptions & {
    types: never[]
    componentRestrictions: {
      country: string
    }
  }
  labelId: string
  label: string
  required: boolean
  inputId: string
  handleClear: React.MouseEventHandler<HTMLButtonElement> | undefined
  markerLabel: string
  hasValue: boolean
  invalidSearch?: boolean
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement> | undefined
  placeholder: string
}

export type Ref = HTMLInputElement

export const RouteInput = forwardRef<Ref, RouteInputProps>(
  (
    {
      labelId,
      label,
      required,
      inputId,
      apiKey,
      options,
      onPlaceSelected,
      handleClear,
      hasValue,
      invalidSearch,
      markerLabel,
      onKeyDown,
      placeholder,
    }: RouteInputProps,
    ref,
  ) => {
    const googleLoadState = useGoogleLoadState().get()
    const [isFocused, setIsFocused] = useState(false)
    const invalidFieldStyle = invalidSearch && hasValue === false ? 'c-text-input--invalid' : ''

    return (
      <div className='c-journey-form__field'>
        <div className='c-journey-form__field-marker'>
          <span className='c-journey-form__marker-label'>{markerLabel}</span>
          <Icon icon='marker' ariaHidden={true} />
        </div>
        <FormField labelId={labelId} label={label} htmlFor={inputId} isRequired={required}>
          <div className='c-input-container'>
            {googleLoadState && (
              <Autocomplete
                className={`c-text-input c-text-input--has-btn ${invalidFieldStyle}`}
                id={inputId}
                ref={ref}
                apiKey={apiKey}
                onPlaceSelected={onPlaceSelected}
                inputAutocompleteValue='off'
                options={options}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
              />
            )}
            {<RouteClear isVisible={(isFocused || hasValue)} handleClear={handleClear} />}
          </div>
        </FormField>
      </div>
    )
  },
)

export default RouteInput
