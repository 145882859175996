import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { useEffect, useState } from 'react'
import useActiveMapItem from '../../hooks/use-active-map-item'
import { getMap } from '../../stores/GoogleMapState'
import { MapFilterIconsMap } from '../../stores/MapFiltersState'
import { MapDataFeature } from '../../types/features'
import { iconPaths, IconType } from '../../types/icon-name-types'
import { mapFilterItemTypes, MapFilterType, MAP_Z_INDEX, RelatedItem } from '../../types/JourneyPlannerTypes'
import { getClosestPointToCenter } from '../../util/geometry-utils'

/**

This is the heart of the displaying of markers lines and polygons overlayed ion the map.

There are 2 ways to plot upon the map.
via Datalayer or a Marker

Both are standard options on a google map.

Context:
In the beginning of the react build the decision was to use only Google Datalayers for all elements on a map.
This meant both static markers along with polygons / lines could be incorporated into one layer of functionality.
However what wasn't taken into account was that the existing (required) feature to be redeveloped in react "clustering"
is not compatible with datalayers.

This has meant that the code below becomes divergent at one point to cater for this issue.

The first being a traditional "Marker".

**/

const COLORS = {
  orange: '#D14600',
  red: '#bc3832',
  blue: '#006FB8',
  black: '#555',
}

//Returns the colour for the cluster marker based on what type of cluster it is
const getMapFilterColours = (type: string) => {
  switch (type) {
    case MapFilterIconsMap.closures:
    case 'closures':
      return COLORS.black
    case MapFilterIconsMap.roadworks:
    case 'roadworks':
      return COLORS.orange
    case MapFilterIconsMap.hazards:
    case 'hazards':
      return COLORS.red
    case MapFilterIconsMap.warnings:
    case 'warnings':
      return COLORS.orange
    default:
      return COLORS.blue
  }
}

let selectedItemFromMap: string | null
let dataLayer: google.maps.Data | null
let markers: Record<string, google.maps.Marker[]>
let markerClusters: MarkerClusterer[] = []

// This is a function that allows the icons being plotted to have both the correct SVG icon associated with it
// It also is the function that determines the size of the icon.  ie isSelected
export const getIcon = (featureType: MapFilterType, isSelected: boolean) => {
  const iconSize = isSelected ? 44 : 30
  const iconName = MapFilterIconsMap[featureType]
  const iconPath = iconName && iconPaths[iconName]
  return {
    url: iconPath || iconPaths['marker'],
    scaledSize: new google.maps.Size(iconSize, iconSize, 'px', 'px'),
  }
}

/*
Getter function that is used in the use-active-map-item hook
Returns the most upto date version of the markers available.
This is not a list of all markers, but just the markers that are currently available after the filtering of things such as region.
This filtering happens on line ****
*/
export const useMapMarkers = () => {
  return markers
}

/*
Getter function that is used in the use-active-map-item hook
Returns the most upto date version of the markers available.
This is not a list of all markers, but just the markers that are currently available after the filtering of things such as region.
This filtering happens on line ****
*/
export const useDataLayer = () => {
  return dataLayer
}

/*
This function is used when the page rerenders and rebuilds the filtered markers.
This is one of the first thing that happens in the renderMarkerCollection
*/
export const clearMarkerCollection = () => {
  dataLayer?.setMap(null)
  dataLayer = null
  markerClusters.forEach((cluster) => {
    cluster.setMap(null)
  })
}

export const styleMultiLine = (selectedItem: google.maps.Data.Feature | null, feature: google.maps.Data.Feature) => {
  const isMultiLine = selectedItem && typeof selectedItem.getProperty === 'function'
  const typeIndex = mapFilterItemTypes.indexOf(feature.getProperty('type'))
  const idIndex = feature.getProperty('id')
  const type = feature.getProperty('type')
  const color = getMapFilterColours(type)
  let zIndexBase
  let isSelected
  if (isMultiLine) {
    isSelected = selectedItem?.getProperty('uniq') === feature.getProperty('uniq')
    zIndexBase =
      selectedItem.getProperty('uniq') === feature?.getProperty('uniq')
        ? MAP_Z_INDEX.markerSelectedBase
        : MAP_Z_INDEX.markerBase
  } else {
    isSelected = false
    zIndexBase = MAP_Z_INDEX.markerBase
  }
  const zIndex = (zIndexBase + typeIndex) * 10000000 + idIndex // e.g. (140+1)*10000000+613513 = 141613513

  return {
    icon: getIcon(type, isSelected),
    zIndex: parseFloat(zIndex),
    strokeWeight: 3,
    strokeColor: color,
  }
}

/*
Upon a render every marker that has been kept after the filter is run goes through this function
While looping through a check is made on whether or not the current marker being evalutated is selected.
If it is selected we can then push through to getIcon a true argument that will then make the item larger than others.
This same evaluation also determines its Z-index. We have many icons grouped together that have varying Z-indexes
We are looking to make sure the sleected has the highest.
*/
export const styleMarker = (selectedItem: google.maps.Marker | null, feature: google.maps.Data.Marker | null) => {
  const markerProperties = selectedItem?.get('properties')
  const typeIndex = mapFilterItemTypes.indexOf(markerProperties.type)
  const idIndex = markerProperties.id
  const zIndexBase = MAP_Z_INDEX.markerBase
  // Trying to create a consistent ordering so they don't rearrange when re-rendered. It seems maps only accepts integers
  const zIndex = (zIndexBase + typeIndex) * 10000000 + idIndex // e.g. (140+1)*10000000+613513 = 141613513
  return {
    icon: getIcon(feature.get('properties').type, markerProperties.uniq === feature.get('properties')?.uniq),
    zIndex: markerProperties.uniq === feature.get('properties')?.uniq ? parseFloat(zIndex) : zIndexBase,
  }
}

/*
This function is the parent of the styleMarker function and determines which of the marker items is actually the one that is regarded as
selected item.
This can be determined in 2 ways the first is via looking through all the markers to a marker that is of a particular type and id.
Used when we are identifying via the the URL parameters.
The other way is via the uniq id on the properties object of the marker.
*/
export function styleSelectedMapMarker({
  uniq,
  type,
  id,
}: { uniq: string; type?: undefined; id?: undefined } | { type: string; id: string | number; uniq?: undefined }) {
  let item: google.maps.Data.Feature | google.maps.Marker | null = null
  const markerArray = markers ? Object.values(markers).flatMap((x) => x) : []

  markerArray?.forEach((m) => {
    if (uniq && uniq === m.get('properties').uniq) {
      item = m
    }

    if (type && id && m.get('properties').id === id && m.get('properties').type === type) {
      item = m
    }
  })

  if (item != null) {
    markerArray?.forEach((m) => {
      const styleObj = styleMarker(item, m)
      m.setIcon(styleObj.icon)
      m.setZIndex(styleObj.zIndex)
    })
  }
}

/*
Datalayers are used for complex features such as lines on the road and areas of regional warning. ie polygons.
*/
export const createDataLayer = (
  map: google.maps.Map,
  style: google.maps.Data.StylingFunction,
  updateUrl: (feature: any) => void,
) => {
  let dataLayer = new google.maps.Data({
    map,
    style,
  })

  // Attach click event to
  dataLayer.addListener('click', (event: google.maps.Data.MouseEvent) => {
    // Update the feature to the currrent selected feature
    updateUrl(event.feature as any)
  })

  return dataLayer
}

/*
This function is exported and called as a hook on various pages and allows pages on initialization to read the current URL
and move the focus to the area. It is also used as the callbakc for triggers such as moving down a list of items on the popout panels.
*/
export function usePanToItem({ type, id, focus }: { type?: string; id?: number; focus?: boolean }) {
  const map = getMap()
  useEffect(() => {
    const markerArray = markers ? Object.values(markers).flatMap((x) => x) : []
    markerArray?.forEach((f) => {
      if (f.get('properties').id === id && f.get('properties').type === type) {
        // Should only ever be one ll as this is a point.

        map.panTo(new google.maps.LatLng(f.get('position').lat(), f.get('position').lng()))
        if (focus && (map.getZoom() || 0) < 11) {
          map.setZoom(11)
        }
      }
    })

    if (focus && type != null && id != null) {
      styleSelectedMapMarker({ type, id })
    }
    //  eslint-disable-next-line
  }, [type, id, markers, map])
}

const panToItem = (map: google.maps.Map, uniq: string | undefined | null, coordinates: number[]) => {
  const panToItem = selectedItemFromMap !== uniq
  let nonNumber = false

  coordinates.forEach((coord) => {
    if (typeof coord !== 'number') {
      nonNumber = true
    }
  })

  // Pan/zoom to the newly selected item
  if (panToItem && coordinates?.length === 2 && selectedItemFromMap !== uniq && !nonNumber) {
    map.panTo({ lat: coordinates[1], lng: coordinates[0] })
    if ((map.getZoom() || 0) < 11) {
      map.setZoom(11)
    }
  }
}

/*
This is the beginning of the process for plotting the overlays on the map.
It is a hook that is called throughout the application and generally on the useEffect when the routing changes
and a component that depends upon mapping is initialized
Along with calling a rerender of the markers and datalayers it also sets the filtered list of markers to be displayed.
These are curated and created outside of this files and generally created where you find the hook state called.
*/
export const useMarkerCollection = (
  callback?: (arg: MapDataFeature) => void,
): [RelatedItem[], (features: RelatedItem[]) => void] => {
  const [storedFeatures, setFeatures] = useState<RelatedItem[]>([])
  const map = getMap()
  const [activeItem, setActiveItem] = useActiveMapItem(callback)
  const updateMarkerCollection = (features?: RelatedItem[]) => {
    renderMarkerCollection(map, features ?? [], activeItem, setActiveItem)

    if (features && features.length !== storedFeatures.length) {
      setFeatures(features)
    }
  }

  return [storedFeatures, updateMarkerCollection]
}

/*
This function actually plots the markers and datalayers
It incorporates alot of the functions in this file.

*/
export const renderMarkerCollection = (
  map: google.maps.Map,
  features: RelatedItem[],
  selectedItem: google.maps.Data.Feature | null | google.maps.Marker | any,
  updateUrl: (feature: any) => void,
) => {
  if (!map) return
  clearMarkerCollection()

  if (!features.length) {
    return
  }

  const styleFn = (feature: google.maps.Data.Feature) => styleMultiLine(selectedItem, feature)

  if (!dataLayer) {
    dataLayer = createDataLayer(map, styleFn, updateUrl)
  }

  updateFeaturesOnDataLayer(dataLayer, features)

  dataLayer.setStyle(styleFn)

  const createClusterMarker = (markerGroup: any, type: IconType) => {
    let cluster = new MarkerClusterer({
      markers: markerGroup,
      map,
      renderer: {
        render: ({ count, position }) =>
          new google.maps.Marker({
            label: { text: String(count), color: 'white', fontSize: '14px', fontWeight: '600' },
            position,
            icon: getSvgMarker(type),
            // adjust zIndex to be above other markers
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          }),
      },
    })

    cluster.set('type', type)
    return cluster
  }

  markers = updateMarkersOnMap(map, features, selectedItem, updateUrl)

  const getSvgMarker = (iconType: string) => {
    return {
      path: 'M 30 30 m -30 -15 a 15 15 0 1 0 30 0 a 15 15 0 1 0 -30 0',
      fillColor: getMapFilterColours(iconType),
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: 'white',
      rotation: 0,
      scale: 1,
      anchor: new google.maps.Point(0, 15),
      labelOrigin: new google.maps.Point(15, 15),
    }
  }

  const markerArray = Object.values(markers)
  // For each array of marker types we need to create a cluster and store it in a reuseable array

  markerArray.forEach((markerGroup, index) => {
    let cluster = createClusterMarker(markerGroup, Object.keys(markers)[index] as IconType)
    markerClusters.push(cluster)
  })

  // Re-trigger styling so the selected item is larger, and any previous one is small again
  const uniq = selectedItem?.get('properties')?.uniq
  let coordinates: number[] = []
  const ll = selectedItem?.getPosition()
  coordinates = [ll?.lng(), ll?.lat()]

  // Pan to item, but only if the item is newly selected
  panToItem(map, uniq, coordinates)

  selectedItemFromMap = selectedItem?.get('properties')?.uniq || null
}

// Everytime the map renders this function is called to replot the datalayers that exist in the newly filtered features
const updateFeaturesOnDataLayer = (dataLayer: google.maps.Data, features: RelatedItem[]) => {
  const featureIndex: any = {}

  // Filter for only Multistring
  const multiLineFeatures = features.filter((feature) => {
    return feature?.geometry?.type === 'MultiLineString'
  })
  // Loop through data layer, getting uniq id for each feature

  dataLayer.forEach((feature) => {
    featureIndex[feature.getProperty('uniq')] = feature
  })

  // For each feature being passed, add if doesn't exist
  // If already exists, update, but only if lastUpdated has changed
  multiLineFeatures.forEach((feature: any) => {
    if (!featureIndex[feature.properties.uniq]) {
      // new feature not previously rendered
      dataLayer?.addGeoJson(feature)
    } else {
      // Regenerate this feature if it changed
      if (featureIndex[feature.properties.uniq].getProperty('lastUpdated') !== feature.properties.lastUpdated) {
        dataLayer?.remove(featureIndex[feature.properties.uniq])
        dataLayer?.addGeoJson(feature)
      }
      // Remove the items we've seen / are keeping, so any that are left in that index should be removed
      delete featureIndex[feature.properties.uniq]
    }
  })

  //Remove features which are no longer in the collection passed down
  Object.keys(featureIndex).forEach((uniq) => {
    if (featureIndex[uniq]) {
      dataLayer?.remove(featureIndex[uniq])
    }
  })
}

// Everytime the map renders this function is called to replot the markers that exist in the newly filtered features
const updateMarkersOnMap = (
  map: google.maps.Map,
  features: RelatedItem[],
  selectedItem: any | null,
  updateUrl: (feature: any) => void,
) => {
  const markerObj = features.reduce((acc: Record<string, any>, feature: RelatedItem) => {
    let coords

    const iconName = MapFilterIconsMap[feature?.properties?.type]
    if (feature.geometry?.coordinates) {
      if (!Array.isArray(feature?.geometry?.coordinates?.[0])) {
        // this only has one point
        coords = feature.geometry.coordinates
      } else {
        // this has multiple points and the center of the points is required.
        coords = getClosestPointToCenter(feature)
      }
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng({
          lng: coords[0],
          lat: coords[1],
        }),
        icon: getIcon(feature.properties?.type, selectedItem?.uniq === feature.properties.uniq),
        map: map,
      })
      marker.set('properties', feature.properties)
      marker.addListener('click', () => {
        updateUrl(feature)
      })

      if (acc[iconName]) {
        return {
          ...acc,
          [iconName]: [...acc[iconName], marker],
        }
      } else {
        return {
          ...acc,
          [iconName]: [marker],
        }
      }
    } else {
      return acc
    }
  }, {})

  clearNoLongerPresentMarkers(markers, markerObj)
  return markerObj
}

// If the markers array doesn't contain the markers in the new render it needs to be removed by setting the map to null
const clearNoLongerPresentMarkers = (
  oldObject: Record<string, google.maps.Marker[]>,
  newObject: Record<string, google.maps.Marker[]>,
) => {
  const oldArray = oldObject ? Object.values(oldObject) : []
  const newArray = newObject ? Object.values(newObject) : []

  if (oldArray.length < 1 || newArray.length < 1) {
    return
  }
  const existingMarkers = Object.values(oldObject).flatMap((x) => x)
  const newMarkers = Object.values(newObject).flatMap((y) => y)

  existingMarkers.forEach((marker) => {
    // If the newMarkers object/array doesn't contain the marker it needs to be removed by setting the map to null [CM 6/6/22]
    if (!newMarkers.includes(marker)) {
      marker.setMap(null)
    }
  })
  clearNoLongerPresesentCluster(newObject)
}

// If the markerClusters array doesn't contain the cluster type any more it needs to be removed by setting the map to null
const clearNoLongerPresesentCluster = (newObject: Record<string, google.maps.Marker[]>) => {
  const newTypes = Object.keys(newObject)
  markerClusters.forEach((cluster) => {
    if (!newTypes.includes(cluster.get('type'))) {
      cluster.setMap(null)
    }
  })
}
