import React from 'react'
import classNames from 'classnames'
import sprite from '../../../../assets/icons/icons.svg'
import { IconType } from '../../../types/icon-name-types'

export interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  // Must provide to correctly obtain an SVG icon
  icon: IconType
  // Default 'regular' size used if size prop omitted
  size?: 'small' | 'large'
  // Will inherit text colour of containing element if omitted
  color?: 'primary' | 'body-text' | 'white' | 'grey' | 'success' | 'error' | 'info'
  isIconRotated180?: boolean
}

export interface AriaLabelSVGIconProps extends IconProps {
  // Default to false
  ariaHidden?: boolean
  // Must provide as a standard accessibility requirement
  ariaLabel: string
}

export interface AriaHiddenSVGIconProps extends IconProps {
  // Default to true
  ariaHidden: true
  // Must provide as a standard accessibility requirement
  ariaLabel?: undefined
}

export default function Icon({
  className = '',
  icon,
  size,
  color,
  isIconRotated180,
  ariaLabel,
  ariaHidden,
  ...props
}: AriaLabelSVGIconProps | AriaHiddenSVGIconProps) {
  const iconClasses = classNames('c-icon', {
    [`c-icon--${size}`]: size,
    [`c-icon--${color}`]: color,
    'c-icon--spinner': icon === 'spinner',
    [`${className}`]: className != null && className !== '',
    'c-icon--rotated': isIconRotated180,
  })

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={iconClasses}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      {...props}
    >
      <use xlinkHref={`${sprite}#${icon}`}></use>
    </svg>
  )
}
