import { ReactElement } from 'react'
import { Delay } from '../../../types/JourneyPlannerTypes'
import { InfoList } from '../../common/info-list'
import PanelSection from '../../layout/panel-section'
import PanelFooter from '../panel-footer/index'

interface Props {
  item: Delay
  itemLinks?: {
    prevUrl: string
    nextUrl: string
  }
}

export default function GeneralWarningsPanel({ item, itemLinks }: Props): ReactElement {
  const panelInfoList = [{ label: 'Last updated', data: item.LastUpdatedNice }]

  return (
    <PanelSection>
      {item.EventIsland && <p className='c-road-works-panel__comments'>{item.EventIsland}</p>}
      {item.EventDescription && <p className='c-road-works-panel__comments'>{item.EventDescription}</p>}

      <InfoList items={panelInfoList} />

      <PanelFooter footerLinks={itemLinks} />
    </PanelSection>
  )
}
