import classNames from 'classnames'
import { ComponentType } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import '../../styles/app.scss'
import PopOutPanel from '../components/common/pop-out-panel'
import KeyJourneysPanel from '../components/detail-panels/key-journeys/key-journeys-panel'
import TrafficDashboardMobileFooter from '../components/detail-panels/key-journeys/traffic-dashboard-mobile-footer'
import TrafficUpdateDetailPanel from '../components/detail-panels/traffic-update/detail'
import { RouteResults } from '../components/journey-planner/route-results'
import MapArea from '../components/MapArea'
import { Portal, PortalAnchor } from '../components/portal'
import TrafficTable from '../components/traffic-and-travel-list-view/traffic-table'
import TrafficDashboard from '../components/traffic-dashboard/index'
import TrafficDashboardPanel from '../components/traffic-dashboard/panel'
import useRegions from '../hooks/use-regions'
import { useOnHighwayConditions } from '../pages/HighwayConditions'
import TrafficAndTravelListView from '../pages/TrafficAndTravelListView'
import EVChargersPanel from '../routes/ev-chargers/panel'
import HighwayConditionsPanel from '../routes/highway-conditions/panel'
import HolidayTrafficJourneys, { useOnHolidayJourney } from '../routes/holiday-journeys'
import RegionHolidayTrafficJourneys from '../routes/holiday-journeys/[region-id]'
import JourneyPlannerPanel from '../routes/journey-planner/panel'
import RegionsPanel from '../routes/regions/panel'
import TrafficCameras from '../routes/traffic-cameras'
import TrafficCamerasByRegion from '../routes/traffic-cameras/[region-id]'
import RegionPanel from '../routes/[region-id]'
import { useKeyJourneyState } from '../services/map/KeyJourneyMap'
import { useActiveSearchState, useInvalidSearchState } from '../stores/journey-planner/JourneySearchState'
import { useRegionsState } from '../stores/national-data/regions'
import { useActiveTabState } from '../stores/TabNavigationState'
import { TabTypes } from '../types/tab-name-types'
import EVChargersByRegion from './ev-chargers/[region-id]'
import TrafficUpdatePanel, { useOnTrafficUpdate } from './traffic-update'
import TrafficUpdateDetail from './traffic-update/traffic-update-detail'
import RegionAlertBanner from './[region-id]/regionAlertBanner'

type WithMapArea = { withMap: true; mainPanelComponent?: undefined }
type WithMainPanel = { mainPanelComponent: ComponentType; withMap: false }

type RouteProps = {
  path: string | string[]
  exact?: boolean
  panelComponent: ComponentType
} & (WithMainPanel | WithMapArea)

const buildRoutes = (regionSlug: string): RouteProps[] => {
  return [
    {
      path: '/holiday-journeys/:holiday([\\w\\-]+)',
      withMap: true,
      exact: true,
      panelComponent: HolidayTrafficJourneys,
    },
    {
      path: [
        `/holiday-journeys/:holiday([\\w\\-]+)/${regionSlug}`,
        `/holiday-journeys/:holiday([\\w\\-]+)/${regionSlug}/:hotspotId(\\d+)`,
      ],
      withMap: true,
      exact: true,
      panelComponent: RegionHolidayTrafficJourneys,
    },
    {
      path: '/highway-conditions/traffic-and-travel-list-view',
      withMap: false,
      mainPanelComponent: TrafficTable,
      panelComponent: TrafficAndTravelListView,
    },
    {
      path: [`/ev-chargers/${regionSlug}`, `/ev-chargers/${regionSlug}/:chargerId(\\d+)`],
      withMap: true,
      exact: true,
      panelComponent: EVChargersByRegion,
    },
    {
      path: '/ev-chargers',
      withMap: true,
      exact: true,
      panelComponent: EVChargersPanel,
    },
    {
      path: [`/highway-conditions/${regionSlug}`, `/highway-conditions/${regionSlug}/:type([\\w\\-]+)?/:index(\\d+)?`],
      withMap: true,
      exact: true,
      panelComponent: HighwayConditionsPanel,
    },
    {
      path: '/highway-conditions',
      withMap: true,
      exact: false,
      panelComponent: HighwayConditionsPanel,
    },
    {
      path: '/journey-planner',
      withMap: true,
      exact: false,
      panelComponent: JourneyPlannerPanel,
    },
    {
      path: '/regions',
      withMap: true,
      exact: true,
      panelComponent: RegionsPanel,
    },
    {
      path: `/regions/${regionSlug}`,
      withMap: true,
      exact: true,
      panelComponent: RegionPanel,
    },
    {
      path: `/regions/${regionSlug}/traffic-dashboard`,
      withMap: false,
      exact: true,
      panelComponent: TrafficDashboardPanel,
      mainPanelComponent: TrafficDashboard,
    },
    {
      path: `/regions/${regionSlug}/traffic-dashboard/:routeUrl`,
      withMap: true,
      exact: true,
      panelComponent: KeyJourneysPanel,
    },
    {
      path: [`/traffic-cameras/${regionSlug}`, `/traffic-cameras/${regionSlug}/:cameraId(\\d+)`],
      withMap: true,
      exact: true,
      panelComponent: TrafficCamerasByRegion,
    },
    {
      path: `/traffic-cameras`,
      withMap: true,
      exact: true,
      panelComponent: TrafficCameras,
    },
    {
      path: [`/regions/${regionSlug}/traffic-updates`, `/regions/${regionSlug}/traffic-updates/:updateId(\\d+)`],
      withMap: true,
      exact: true,
      panelComponent: TrafficUpdatePanel,
    },
    {
      path: `/regions/${regionSlug}/traffic-updates/:updateId/details`,
      withMap: false,
      exact: true,
      panelComponent: TrafficUpdateDetailPanel,
      mainPanelComponent: TrafficUpdateDetail,
    },
  ]
}

/**
 * Build out routes for the application
 * @returns Routes for application
 */
export default function Router() {
  const { slug } = useRegions()
  const routes = buildRoutes(slug)

  // Initialise region state
  useRegionsState()
  const invalidSearchState = useInvalidSearchState().get()
  const activeSearchState = useActiveSearchState().get()
  const keyJourneyState = useKeyJourneyState().get()
  const activeTabState = useActiveTabState().get()
  const holidayJourneys = useOnHolidayJourney().get()
  const onTrafficUpdate = useOnTrafficUpdate().get()
  const onHighwayConditions = useOnHighwayConditions().get()

  const mapAreaClasses = classNames('l-map-tool__map-area', {
    'l-map-tool__map-area--activeSearch': !invalidSearchState && activeSearchState,
    'l-map-tool__map-area--show-on-mobile':
      !!keyJourneyState ||
      (activeTabState === TabTypes.MAP && (!!holidayJourneys || !!onTrafficUpdate || !!onHighwayConditions)),
  })

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={`/regions/${slug}`} component={RegionAlertBanner} />
      </Switch>

      <div className='l-map-tool__container'>
        <PortalAnchor />

        <div className='l-map-tool__sidebar'>
          <Switch>
            {/* Routes won't be changing without a reload, so safe to use idx as key */}
            {routes.map(({ path, panelComponent, exact }, idx) => (
              <Route key={idx} path={path} exact={exact} component={panelComponent} />
            ))}
            {/* Redirect to traffic-cameras */}
            <Redirect from='/traffic-and-travel-information/traffic-cameras' to='/traffic-cameras' />
          </Switch>
        </div>

        <Switch>
          {routes
            .filter((r) => !r.withMap)
            .map(({ path, mainPanelComponent, exact }, idx) => (
              <Route key={idx} path={path} exact={exact} component={mainPanelComponent} />
            ))}
          <Route path={routes.filter((r) => !!r.withMap).flatMap((r) => r.path)}>
            <div className={classNames(mapAreaClasses)}>
              <MapArea />
              <RouteResults showSelectedOnly={true} />
              <TrafficDashboardMobileFooter />
            </div>
          </Route>
        </Switch>
        <Portal>
          <PopOutPanel />
        </Portal>
      </div>
    </BrowserRouter>
  )
}
