/* eslint-disable */
// TODO this can go once we update my journeys to designs
const tabs = require('./tabs')
const { initialiseBanners } = require('./banners')

window.$ = window.jQuery = require('jquery')

function updateJavascriptEnabledStyling() {
  $('html').removeClass('no-js').addClass('js')
}

// function refreshKeyJourneys () {
//   $.ajax(window.location.href + (window.location.search ? '&' : '?') + 'list=1').done(function (content) {
//     $('#js-refresh-journey-listing').html(content)
//     $('#js-refresh-journey-listing .error').remove()
//     const fields = ['length', 'speed', 'time']
//     fields.forEach(field => {
//       $('.js-journey-' + field + '-copy').html($('.js-journey-' + field + '-src').html())
//     })
//   }).error(function () {
//     let $errDisplay = $('#js-refresh-journey-listing .error')
//     if ($errDisplay.length === 0) {
//       $errDisplay = $('<div class="error">')
//       $('#js-refresh-journey-listing').prepend($errDisplay)
//     }
//     $errDisplay.html('<h2>Error updating journeys</h2><p>Trying again soon...</p>')
//   })
// }

// function setupKeyJourneysRefresh () {
//   if ($('#js-refresh-journey-listing').length) {
//     refreshKeyJourneys()
//     window.setInterval(refreshKeyJourneys, 30000)
//   }
// }

function toggleRegistrationForm() {
  $('#register-form').toggleClass('hide')
  $('#login-form').toggleClass('hide')
  $('#show-register').toggleClass('hide')
  $('#show-login').toggleClass('hide')
  $('#left-col').toggleClass('left-border-col')
  $('#right-col').toggleClass('right-border-col')
}

function addClickHandlerForRegisterForm() {
  $('#show-register-button, #show-login-button').on('click', toggleRegistrationForm)
  if (window.location.hash === '#Form_RegisterForm') {
    toggleRegistrationForm()
  }
}

/**
 * Generic browser popup generation
 */
function confirmPopup() {
  $('.js-confirm-link').on('click', function (e) {
    if (!window.confirm($(this).data('confirm-msg'))) {
      e.preventDefault()
      return false
    }
  })
}

jQuery(function () {
  updateJavascriptEnabledStyling()
  // setupKeyJourneysRefresh()
  addClickHandlerForRegisterForm()
  tabs.init()
  confirmPopup()
  initialiseBanners()
})
