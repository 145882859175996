import { useLocation } from 'react-router-dom'
import { useKeyJourneyState } from '../../../services/map/KeyJourneyMap'
import Button from '../../common/button'
import { prepareTimeForDisplay } from '../../traffic-dashboard'
import { useIsPanelOpen } from './key-journeys-panel'

export default function TrafficDashboardMobileFooter() {
  const keyJourneyState = useKeyJourneyState()
  const location = useLocation()

  const selectedJourney = keyJourneyState.get()
  const isPanelOpenState = useIsPanelOpen()

  const regex = new RegExp(/\/traffic-dashboard\/\w+/g)

  if (!location.pathname.match(regex)) {
    return null
  }

  return (
    <div className='c-key-journey-detail-footer'>
      <div className='c-key-journey-detail-footer__heading-container'>
        <h3 className='c-key-journey-detail-footer__name'>{selectedJourney?.Title}</h3>
        <p className='c-key-journey-detail-footer__time'>{prepareTimeForDisplay(selectedJourney?.CurrentTime)}</p>
      </div>
      <Button
        label='Details'
        buttonStyle='text-action'
        size='small'
        className='c-key-journey-detail-footer__btn'
        onClick={() => isPanelOpenState.set(true)}
      />
    </div>
  )
}
