import { useMemo } from 'react'
import { useRegionsState, getRegions } from '../stores/national-data/regions'

export default function useRegions() {
  // Initialise region state
  const loaded = useRegionsState()

  // Get regions
  const regions = getRegions()
  const getLoaded = loaded.get()
  // Construct region slug using regions available
  // Memoise based on length of regions won't change without a refresh anyway
  const validRegionSlug = useMemo(
    () => `:regionSlug(${regions.map((r) => r.properties.slug).join('|')})`,
    // eslint-disable-next-line
    [getLoaded, regions],
  )

  return {
    regions,
    slug: validRegionSlug,
  }
}
