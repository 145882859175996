import { FormattedAddress } from '../types/JourneyPlannerTypes'

const uniqueCompact = (obj: any) => {
  const uniCom: any = {}
  Object.keys(obj).forEach((key) => {
    const val = obj[key]

    const shouldAdd = Object.keys(uniCom).every((uKey) => {
      return val && val !== uniCom[uKey]
    })

    if (shouldAdd) uniCom[key] = val
  })

  return uniCom
}

export const parseAddressParts = (googleResult: google.maps.places.PlaceResult) => {
  const addressComponents = googleResult.address_components || []
  const components: any = {}
  addressComponents.forEach((comp) => {
    comp.types.forEach((type) => {
      components[type] = comp.long_name || comp.short_name
    })
  })

  let name = googleResult.name
  if (!name) {
    if (components.street_number || components.route) {
      name = [components.street_number, components.route].filter((val) => !!val).join(' ')
    } else if (components.establishment) {
      name = components.establishment
    } else {
      name = components.sublocality
    }
  }

  const parts: FormattedAddress = uniqueCompact({
    name: name,
    suburb: components.sublocality,
    city: components.locality || components.administrative_area_level_1,
    country: components.country,
  })

  const postCode = components.postal_code
  if (parts.city && postCode) parts.city += ' ' + postCode
  parts.formatted = [parts.name, parts.suburb, parts.city, parts.country]
    .filter((p) => typeof p === 'string' && p.length)
    .join(', ')

  return parts
}

export const formatted = (googleResult: google.maps.places.PlaceResult) => {
  if (!googleResult) return ''
  const parts = parseAddressParts(googleResult)
  return parts.formatted
}

const AddressFormatter = {
  formatted,
  parseAddressParts,
}
export default AddressFormatter
