import React from 'react'
import classNames from 'classnames'

export default function NavigationPanel({
  children,
  className,
}: {
  children: React.PropsWithChildren<React.ReactNode>
  className?: string
}) {
  const navPanelClasses = classNames('c-navigation-panel', {
    [`${className}`]: !!className,
  })
  return <section aria-label="Page (navigation)" className={navPanelClasses}>{children}</section>
}
