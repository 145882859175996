import { useState } from '@hookstate/core'
import { ReactElement, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import useRegions from '../../../hooks/use-regions'
import { usePanToItem } from '../../../services/map/MarkerCollection'
import { TrafficCamera } from '../../../types/JourneyPlannerTypes'
import Heading from '../../common/heading'
import PanelSection from '../../layout/panel-section'
import PanelFooter from '../panel-footer'

interface Props {
  item: TrafficCamera
  itemLinks?: {
    prevUrl: string
    nextUrl: string
  }
}

export default function TrafficCamerasPanel({ item, itemLinks }: Props): ReactElement {
  const { regions } = useRegions()
  const region = regions.find((r) => r.properties.id === item.RegionID)
  const { regionSlug } = useParams<{ regionSlug: string; cameraId?: string }>()

  // Allows selecting camera from map to set URL correctly, without impacting popout on routes:
  const history = useHistory()
  const location = useLocation()
  const url = regionSlug ? `/traffic-cameras/${regionSlug}/${item.id}` : `/traffic-cameras/${region?.properties?.slug}/${item.id}`
  useEffect(() => {
    const currentURL = location.pathname
    const segments = currentURL.split('/')
    // Check URL so popout funcions normally on JourneyRoute; otherwise update URL:
    if(!segments.includes('commute') && !segments.includes('highway-conditions')){
      history.push(url)
    }
    // eslint-disable-next-line
  }, [url])

  const cacheTime = useState(new Date().getTime())
  const imageUrl = item.ImageUrl && item.ImageUrl + '?t=' + cacheTime.get()
  usePanToItem({ type: item?.type, id: item?.id, focus: true})

  return (
    <PanelSection>
      {item.Description && (
        <Heading fontWeight='regular' level={3}>
          {item.Description}
        </Heading>
      )}
      <img alt="" src={imageUrl || ''} />
      <PanelFooter footerLinks={itemLinks} />
    </PanelSection>
  )
}
