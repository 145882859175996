import { ReactNode, useRef } from 'react'
import { createPortal } from 'react-dom'

export const PortalAnchor = () => {
  return <div id='c-portal-anchor'></div>
}

export const Portal = ({ children }: { children: ReactNode }) => {
  const anchor = useRef<HTMLDivElement>()

  if (!anchor.current) {
    anchor.current = document.getElementById('c-portal-anchor') as HTMLDivElement
  }

  if (!anchor.current) return null

  return createPortal(<>{children}</>, anchor.current)
}
