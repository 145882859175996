import classNames from 'classnames'
import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { IconType } from '../../../types/icon-name-types'
import Icon from '../icon'

interface LinkListProps {
  links?: LinkItemProps[]
  url?: string
  urlPrefix?: string
  className?: string
  linkStyle?: string
  // Nav will persist across routes, so provide nav styling for this
  type?: 'small' | 'default' | 'nav'
  onClick?: () => void
}

export interface LinkItemProps {
  title: string
  url?: string
  query?: string
  date?: string
  key?: string
  icon?: IconType
  children?: ReactNode
}

export default function LinkList({ linkStyle, className, onClick, urlPrefix, links, url, type = 'default' }: LinkListProps) {
  const linkListClasses = classNames('c-link-list', {
    [`${className}`]: !!className,
    [`c-link-list--type-${type}`]: type,
    [`c-link-list--${linkStyle}`]: linkStyle,
  })

  return (
    <ul className={linkListClasses}>
      {links?.map((link: LinkItemProps, index) => (
        <li key={link.key ? link.key : index} className='c-link-list__item'>
          {link?.icon && <Icon icon={link.icon} ariaHidden={true} className='c-link-list__icon' />}
          <div className='c-link-list__container'>
            <NavLink
              onClick={onClick}
              className={
                linkStyle
                ?
                `c-link-list__link c-link-list__link--${linkStyle}`
                :
                classNames('c-link-list__link', {'c-link-list__link--icon': !!link.icon,})
              }
              activeClassName={type === 'nav' ? 'c-link-list__link--active' : undefined}
              to={`${urlPrefix || ''}${url || link.url}${link.query || ''}`}
            >
              {link.title}
            </NavLink>
            {link.children}
          </div>
        </li>
      ))}
    </ul>
  )
}
