import { Fragment } from 'react'
import PanelSection from './layout/panel-section'
import { useJourneyResultState } from '../stores/journey-planner/JourneySearchState'
import { useActiveTabState } from '../stores/TabNavigationState'
import { Link, useLocation, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { IndexParam } from '../types/JourneyPlannerTypes'
import { TabTypes } from '../types/tab-name-types'
import { useEffect } from 'react'
import Icon from './common/icon'
import Heading from './common/heading'
import { useGroupedPanelItems } from '../services/PanelItemsSorter'
import usePopOutPanel from '../hooks/use-pop-out-panel'
import { useJourneyPlannerUrlCreator } from '../services/JourneyPlannerUrlHelper'
import { formatDistanceToNow, fromUnixTime } from 'date-fns'

export default function JourneyRouteDetails({ selectedRouteId }: { selectedRouteId: number }) {
  const search = useLocation().search
  const journeyResultState = useJourneyResultState()
  const { type, index } = useParams<IndexParam>()
  const [state] = usePopOutPanel()
  const selectedItemId = isNaN(parseInt(index, 10)) ? null : parseInt(index, 10)
  const activeTabState = useActiveTabState()
  const delayGroups = useGroupedPanelItems()
  const createUrl = useJourneyPlannerUrlCreator()
  const [, open] = usePopOutPanel()

  useEffect(() => {
    // Navigation to details view means you want to see the list info not map
    // But if we're here because an item is selected then don't switch (it shows in a panel instead)
    if (activeTabState.get() !== TabTypes.LIST && !selectedItemId) {
      activeTabState.set(TabTypes.LIST)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const route = journeyResultState.get()?.routes?.[selectedRouteId]

    if (selectedItemId !== null && route?.properties?.related?.length) {
      const item = route.properties.related.find(
        (i: any) => i.properties?.type === type && i.properties?.id === selectedItemId,
      )
      if (item) {
        open({ type: 'item-details', subType: item.properties.type, detailId: item.properties.id })
      }
    }
  }, [journeyResultState, selectedRouteId, type, selectedItemId, open])

  const detailsClasses = classNames('c-route-details', {
    'c-route-details--tab-active': activeTabState.get() === TabTypes.LIST,
  })

  const route = journeyResultState.get()?.routes?.[selectedRouteId]
  if (!route?.properties?.hasLoadedRelated) return <PanelSection>Loading</PanelSection>

  return (
    <div className={detailsClasses}>
      <div className='c-route-details__headings'>
        <Link className='c-back-link' to={`/journey-planner/commute/${selectedRouteId}${search.toString()}`}>
          <Icon className='c-back-link__icon' icon='chevron-left' ariaHidden />
          <span className='c-back-link__label'>Results</span>
        </Link>
        <Heading level={3}>Traffic updates for {route.properties.name}</Heading>
      </div>
      <nav role='navigation' aria-label='Secondary Navigation'>
        {delayGroups.map((group) => (
          <Fragment key={group.itemType}>
            <Heading className='c-route-details__list-heading' level={4}>
              {group.label}
            </Heading>
            <ul className='c-route-details__list'>
              {group.items.map((item: any, i: number) => {
                const itemClasses = classNames('c-route-detail', {
                  'c-route-detail--selected': item.properties.id === state.detailId && state.subType === group.itemType,
                })

                const itemLink = createUrl({
                  selectedPanelItemId: item.properties.id,
                  selectedPanelItemType: group.itemType,
                })

                return (
                  <li key={`${group.itemType}-${i}`} className={itemClasses}>
                    <Icon
                      className='c-route-detail__icon c-route-detail__icon--roadworks'
                      icon={group.icon}
                      ariaHidden
                    />
                    <div className='c-route-detail__content'>
                      <Link className='c-route-detail__name' to={itemLink}>
                        {item.properties.Name}
                      </Link>
                      <div className='c-route-detail__updated'>
                        Last updated:
                        {group.itemType !== 'cameras' && group.itemType !== 'ev'
                          ? ' ' + item.properties.LastUpdatedAgo
                          : ' ' + formatDistanceToNow(fromUnixTime(item.properties.lastUpdated)) + ' ago'}
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </Fragment>
        ))}
      </nav>
    </div>
  )
}
