import { Route, Switch, useParams } from 'react-router-dom'
import useTitle from '../../hooks/use-title'
import { getRegions, useRegionsState } from '../../stores/national-data/regions'
import NavigationPanel from '../navigation/navigation-panel/index'
import PanelSubNavigation from '../navigation/navigation-panel/panel-sub-navigation'

type RegionListParams = {
  regionSlug: string
  type?: string
  index?: string
}

export default function TrafficDashboardPanel() {
  const { regionSlug } = useParams<RegionListParams>()
  useRegionsState()
  const regions = getRegions()
  const region = regions.find((r) => r.properties.slug === regionSlug)
  const regionName = region?.properties.name
  const pageTitle = region ? `Traffic dashboard for ${regionName}` : 'Traffic dashboard'
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  return (
    <NavigationPanel>
      <Switch>
        <Route path={`/regions/${regionSlug}/traffic-dashboard`}>
          <PanelSubNavigation
            title={``}
            navLayout='titleBelow'
            prevLabel={`${regionName} region`}
            prevUrl={`/regions/${regionSlug}`}
            hasBorderBottom={false}
          />
        </Route>
      </Switch>
    </NavigationPanel>
  )
}
