import { useEffect, useRef } from 'react'

export default function CloneNode({ selector }: { selector: string }) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const element: HTMLDivElement | null = document.querySelector(selector)
      ref.current.innerHTML = element?.innerHTML ?? ''
    }
  }, [ref, selector])

  return <div ref={ref}></div>
}
