import { createState, useState as hookstateUseState } from '@hookstate/core'
import { AnimatePresence } from 'framer-motion'
import { History } from 'history'
import { useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import LinkList from '../../components/common/link-list'
import PopOutPanelContent from '../../components/common/pop-out-panel/content'
import { useIsPanelOpen } from '../../components/detail-panels/key-journeys/key-journeys-panel'
import RoadWorksPanel from '../../components/detail-panels/road-works-panel'
import PanelSection from '../../components/layout/panel-section'
import NavigationPanel from '../../components/navigation/navigation-panel'
import PanelSubNavigation from '../../components/navigation/navigation-panel/panel-sub-navigation'
import Tabs from '../../components/navigation/tabs'
import { Portal } from '../../components/portal'
import { textFromSelector } from '../../components/textFromSelector'
import useBoundedList from '../../hooks/use-bounded-list'
import useRegion from '../../hooks/use-region'
import { useMarkerCollection, usePanToItem } from '../../services/map/MarkerCollection'
import { MapFilterIconsMap } from '../../stores/MapFiltersState'
import { getDelays } from '../../stores/national-data/delays'
import { getRegions } from '../../stores/national-data/regions'
import { useActiveTabState, useTabPropsState } from '../../stores/TabNavigationState'
import { defaultTabProps } from '../../types/JourneyPlannerTypes'
import { TabTypes } from '../../types/tab-name-types'
import { filteredAndSortedTrafficUpdates } from './traffic-update-filters'
import useTitle from '../../hooks/use-title'

export const redirectToTrafficUpdates = (history: History, feature?: any) => {
  const regionsIds = feature?.properties.regions
  const regions = getRegions()

  const region = regions.find((r) => r.properties.id === regionsIds[0])
  const featureRegion = region?.properties.slug
  const featureId = feature?.properties.id

  if (!featureId) {
    history.push(`/regions/${featureRegion}/traffic-updates`)
  } else {
    history.push(`/regions/${featureRegion}/traffic-updates/${featureId}`)
  }
}

const onTrafficUpdate = createState<boolean>(false)

export const useOnTrafficUpdate = () => {
  return hookstateUseState(onTrafficUpdate)
}

const previousTabState = createState<string>(TabTypes.MAP)

export const usePreviousTabState = () => {
  return hookstateUseState(previousTabState)
}

export default function TrafficUpdatePanel() {
  const history = useHistory()
  const [, updateMarkerCollection] = useMarkerCollection((feature: any) => {
    redirectToTrafficUpdates(history, feature)
    isPanelOpenState.set(true)
  })

  const onTrafficUpdateState = useOnTrafficUpdate()

  const region = useRegion({ panOnLoad: true })
  const { regionSlug, updateId } = useParams<{ regionSlug: string; updateId?: string }>()
  const isPanelOpenState = useIsPanelOpen()
  const pageTitle = region?.properties.name ? `Traffic updates for ${region?.properties.name}` : `Traffic updates`
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  const activeTabState = useActiveTabState()
  const tabPropsState = useTabPropsState()
  const isTabletPortraitUp = window.innerWidth > 600

  let previousTab = usePreviousTabState()

  useEffect(() => {
    tabPropsState.set(defaultTabProps)
    activeTabState.set(previousTab.value ?? tabPropsState.get().tabs[0].tabType)
    onTrafficUpdateState.set(true)
    updateId && isTabletPortraitUp && isPanelOpenState.set(true)
    return () => {
      onTrafficUpdateState.set(false)
      previousTab.set(activeTabState.value)
      activeTabState.set('')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    previousTab.set(activeTabState.value)
  }, [activeTabState.value, previousTab])

  const trafficUpdatesApi = getDelays()

  let trafficUpdates = filteredAndSortedTrafficUpdates(trafficUpdatesApi, region?.properties.id)

  const [selectedUpdate, nextUpdateId, prevUpdateId] = useBoundedList(
    trafficUpdates.map((c) => c.properties),
    'id',
    updateId,
  )

  usePanToItem({ type: selectedUpdate?.type, id: selectedUpdate?.id, focus: true })

  updateMarkerCollection(trafficUpdates)

  const baseUrl = `/regions/${regionSlug}/traffic-updates`

  const trafficLinks =
    trafficUpdates.map((update) => {
      return {
        title: update.properties.Name ?? update.properties.EventType ?? 'Traffic update',
        url: `${baseUrl}/${update.properties.id}`,
        icon: MapFilterIconsMap[update.properties.type],
        children: (
          <div className='link-footer'>
            <p className='link-footer__subtext'>Update {update.properties.LastUpdatedAgo}</p>
          </div>
        ),
      }
    }) ?? []
  const refTitle = useRef(null)
  useEffect(() => {
    if (selectedUpdate && refTitle.current) refTitle.current.focus()
  }, [selectedUpdate])
  return (
    <NavigationPanel>
      <Tabs
        activeTab={activeTabState.get()}
        menuName={tabPropsState.get().menuName}
        tabs={tabPropsState.get().tabs}
        hideOnDesktop
      />
      <PanelSubNavigation
        title={textFromSelector('#page-title__traffic-updates', 'Latest traffic updates and delays')}
        prevLabel={`${region?.properties.name} region`}
        prevUrl={`/regions/${region?.properties.slug}/`}
        navLayout='titleBelow'
      />

      {isTabletPortraitUp || activeTabState.get() === TabTypes.LIST ? (
        <PanelSection hasVerticalPadding={false} hasHorizontalPadding={false} navigation={true}>
          {trafficLinks.length > 0 ? (
            <LinkList links={trafficLinks} onClick={() => isPanelOpenState.set(true)} type='nav' />
          ) : (
            <p className='update__text'>No updates at this time</p>
          )}
        </PanelSection>
      ) : null}

      <Portal>
        <AnimatePresence exitBeforeEnter={true}>
          {isPanelOpenState.get() && selectedUpdate && (
            <PopOutPanelContent
              key='selected-traffic-update'
              title={selectedUpdate.Name ?? 'Traffic Update'}
              ref={refTitle}
              closeAction={() => {
                history.push(baseUrl)
                const activeLink = document.getElementsByClassName('c-link-list__link--active')[0]
                activeLink.focus()
              }}
            >
              <RoadWorksPanel
                regionSlug={regionSlug}
                item={selectedUpdate}
                itemLinks={
                  nextUpdateId && prevUpdateId
                    ? { prevUrl: `${baseUrl}/${prevUpdateId}`, nextUrl: `${baseUrl}/${nextUpdateId}` }
                    : undefined
                }
              />
            </PopOutPanelContent>
          )}
        </AnimatePresence>
      </Portal>
    </NavigationPanel>
  )
}
