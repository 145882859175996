import classNames from 'classnames'

interface KeyProps {
  colour: 'red' | 'orange' | 'green' | 'light-green'
  label: string
  shape?: 'circle' | 'square' | 'triangle'
}

export const Key = ({ colour, label, shape = 'circle' }: KeyProps) => {
  const delayKeyClasses = classNames('c-key__key', {
    [`c-key__key--${colour}`]: !!colour,
    [`c-key__key--${shape}`]: !!shape,
  })

  return (
    <div className='c-key'>
      <span className={delayKeyClasses}></span>
      <span className={`c-key__label c-key__label--${shape}`}>{label}</span>
    </div>
  )
}
