import { useNextPrevItemUrls } from '../../../services/PanelItemsSorter'
import Button from '../../common/button'
import classNames from 'classnames'

interface Props {
  footerLinks?: {
    prevUrl: string
    nextUrl: string
  }
  className?: string
}

export default function PanelFooter({ footerLinks, className }: Props) {
  const urls = useNextPrevItemUrls()
  if (urls == null && footerLinks == null) {
    return null
  }

  const footerClasses = classNames('c-pop-out-panel__footer', { [`${className}`]: !!className })

  const { prevUrl, nextUrl } = urls ?? footerLinks ?? {}
  return (
    <div className={footerClasses}>
      <Button
        noPadding
        icon='chevron-left'
        iconAlignment='left'
        label='Previous'
        buttonStyle='text-action'
        path={prevUrl}
      />
      <Button noPadding icon='chevron-right' label='Next' buttonStyle='text-action' path={nextUrl} />
    </div>
  )
}
