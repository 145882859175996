export enum TabTypes {
  MAP = 'mapView',
  LIST = 'listView',
  TABLE = 'tableView',
}
export enum TabLabel {
  MAP = 'Map view',
  LIST = 'List view',
  TABLE = 'Table view',
}

export enum TabMenuName {
  JOURNEYPLANNER = 'Journey planner results tabs',
  HIGHWAYCONDITIONS = 'Highway conditions tabs',
}
