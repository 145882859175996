import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import useRegion from '../../hooks/use-region'
import { RegionBannerConfig } from '../../types/JourneyPlannerTypes'
import getDelayById from '../../util/get-delay-by-id'

type RegionBannerParams = {
  regionSlug?: string
}

export default function RegionAlertBanner() {
  const { regionSlug } = useParams<RegionBannerParams>()
  const region = useRegion()

  const [isDismissed, setIsDismissed] = useState(false)
  const [banner, setBanner] = useState<RegionBannerConfig>({})
  useEffect(() => {
    const dismissedBanner = sessionStorage.getItem(`${regionSlug}-banner`)
    const bannerConfig = region?.properties.bannerConfig

    if (!!dismissedBanner === true) {
      setIsDismissed(true)
    }
    if ((bannerConfig?.bannerEventID || bannerConfig?.useManualBannerEvent) && !!dismissedBanner === false) {
      setBanner(bannerConfig)
    }
  }, [regionSlug, region])

  const { bannerEventID, useManualBannerEvent, bannerEventImpact, bannerEventTitle, bannerEventURL } = banner

  // Get Delay by ID for when not manual event
  const event = getDelayById(bannerEventID)

  function handleDismiss() {
    sessionStorage.setItem(`${regionSlug}-banner`, JSON.stringify(banner))
    setIsDismissed(true)
  }

  const refPageBanner = useRef(null)
  useEffect(() => {
    if (banner && refPageBanner && refPageBanner.current) {
      refPageBanner.current.focus()
    }
  }, [banner])

  return isDismissed ? null : (
    <>
      {(!!useManualBannerEvent && !!bannerEventImpact && !!bannerEventTitle) && (
        <div className='c-region-banner__container' role='alert' ref={refPageBanner} tabIndex={0}>
          <div className='c-region-banner__wrapper'>
            <div className='c-region-banner__manual-event'>
              <p className='c-region-banner__content'>
                <span className='c-region-banner__impact'>{`${bannerEventImpact}: `}</span>
                {`${bannerEventTitle} `}
                {bannerEventURL && (
                  <a className='c-region-banner__link' href={`${bannerEventURL}`}>
                    View more details
                  </a>
                )}
              </p>
            </div>
            <div className='c-region-banner__btn-wrapper'>
              <span className='c-region-banner__btn' onKeyDown={(e) => e.key === 'Enter' && handleDismiss()} onClick={() => handleDismiss()} tabIndex={0}>
                Dismiss
              </span>
            </div>
          </div>
        </div>
      )}
      {!!bannerEventID && !!event && !useManualBannerEvent && (
        <div className='c-region-banner__container' role='alert' ref={refPageBanner} tabIndex={0}>
          <div className='c-region-banner__wrapper'>
            <div className='c-region-banner_existing-event'>
              <p className='c-region-banner__content'>
                <span className='c-region-banner__impact'>{`${event?.properties.EventType}: `}</span>
                {`${event?.properties.EventDescription} `}
                <a
                  className='c-region-banner__link'
                  href={`/regions/${region?.properties.slug}/traffic-updates/${event?.properties.id}/details`}
                >
                  View more details
                </a>
              </p>
            </div>
            <div className='c-region-banner__btn-wrapper'>
              <span className='c-region-banner__btn' onKeyDown={(e) => e.key === 'Enter' && handleDismiss()} onClick={() => handleDismiss()} tabIndex={0}>
                Dismiss
              </span>
            </div>
          </div >
        </div >
      )}
    </>
  )
}
