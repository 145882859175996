import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { getRegions, useRegionsState } from '../../../../stores/national-data/regions'
import { useActiveTabState } from '../../../../stores/TabNavigationState'
import { Delay, GeojsonFeature } from '../../../../types/JourneyPlannerTypes'
import { TabTypes } from '../../../../types/tab-name-types'

interface TableRowProps {
  item: GeojsonFeature<Delay>
}

export default function TableRow({ item }: TableRowProps): ReactElement {
  const isTabletPortraitUp = window.innerWidth > 600
  const itemProps = item.properties
  const activeTabState = useActiveTabState()

  useRegionsState()
  const regions = getRegions()
  const region = regions.find(r => itemProps.regions.includes(Number(r.properties.id)))
  const regionProps = region?.properties

  return (
    <tr>
      <td data-header='Location'>
        {itemProps.LocationArea} (
          <Link to={`/highway-conditions/${regionProps?.slug}/${itemProps.type}/${itemProps.id}`} onClick={() => { if(!isTabletPortraitUp) activeTabState.set(TabTypes.MAP) }}>map view</Link>
        )
      </td>
      <td data-header='Event type'>{itemProps.EventType}</td>
      <td data-header='Impact'>{itemProps.Impact}</td>
      <td data-header='Comments'>
        <h5>{itemProps.EventDescription}</h5>

        <p>{itemProps.EventComments}</p>

        <p>
          <span className='font-bold'>Restrictions:</span>
          <br />
          {itemProps.Restrictions}
        </p>

        <p>
          <span className='font-bold'>Scheduled time:</span>
          <br />
          Start: {itemProps.StartDateNice}
          <br />
          End: {itemProps.EndDateNice}
        </p>
      </td>
      <td data-header='Detour route'>{itemProps.AlternativeRoute}</td>
      <td data-header='Update received'>{itemProps.LastUpdatedNice}</td>
      <td data-header='Next update'>{itemProps.UpdateDueNice}</td>
      <td data-header='Expected resolution'>{itemProps.ExpectedResolution}</td>
    </tr>
  )
}
