import { createState, useState } from '@hookstate/core'
import { TabLabel, TabMenuName, TabTypes } from '../types/tab-name-types'

/** Used to set which tab is active */
const activeTabState = createState('')
export const useActiveTabState = () => {
  return useState(activeTabState)
}

const availableTabsState = createState({ tabType: 'mapView', label: 'Map View' })
export const useAvailableTabsState = () => {
  return useState(availableTabsState)
}

/** Used to set which tab is active */
const tabPropsState = createState({
  menuName: TabMenuName.JOURNEYPLANNER,
  tabs: [
    {
      tabType: TabTypes.MAP,
      label: TabLabel.MAP,
    },
    {
      tabType: TabTypes.LIST,
      label: TabLabel.LIST,
    },
    {
      tabType: TabTypes.TABLE,
      label: TabLabel.TABLE,
    }
  ],
})

export const useTabPropsState = () => {
  return useState(tabPropsState)
}
