import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import RegionList from '../components/regions/region-list'
import { textFromSelector } from '../components/textFromSelector'
import useMapEvents from '../hooks/use-map-events'
import { useReturnMapToDefaultView } from '../hooks/use-return-map-to-default-view'
import useTitle from '../hooks/use-title'
import { resetFiltersForView, useFilterState } from '../stores/MapFiltersState'

export default function Regions() {
  const filters = useFilterState().get()
  const pageTitle = textFromSelector('#page-title__region', 'Regions')
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  useEffect(() => {
    resetFiltersForView('journey-planner')
  }, [])

  useMapEvents({ filters })

  useReturnMapToDefaultView()

  return (
    <div className='c-navigation-panel'>
      <Switch>
        <Route exact path='/regions' component={RegionList} />
      </Switch>
    </div>
  )
}
