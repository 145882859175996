import React from 'react'

interface InfoItemProps {
  label?: string | null | undefined
  data?: string | JSX.Element[] | null | undefined | JSX.Element
}

interface InfoListProps {
  items?: Array<InfoItemProps> | undefined | null
  styleClass?: string
}

const InfoItem = ({ label, data }: InfoItemProps) => {
  return (
    <li className='c-info-list__item'>
      <p className='c-info-list__label'>{label}</p>
      {typeof data === 'string' || data instanceof String ? (
        <p className='c-info-list__data'>{data}</p>
      ) : (
        <div className='c-info-list__data-wrapper'>{data}</div>
      )}
    </li>
  )
}

export const InfoList = ({ items, styleClass }: InfoListProps) => {
  return (
    <ul className={`c-info-list ${styleClass}`}>
      {items &&
        items.map((item, key) => {
          // If objects have no label (empty object), don't display it
          return item.label ? <InfoItem key={key} label={item.label} data={item.data} /> : null
        })}
    </ul>
  )
}
