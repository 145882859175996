import React, { ComponentPropsWithRef, ReactElement } from 'react'
import TableHeader from '../table-header'

interface TableProps extends ComponentPropsWithRef<'table'> {
  heading: string
}

export default function Table({ children, heading }: TableProps): ReactElement {
  return (
    <>
      <TableHeader heading={heading} />
      <table className='table table--responsive l-traffic-table__column-headers' data-table-responsive>
        <thead>
          <th>Location</th>
          <th>Event type</th>
          <th>Impact</th>
          <th>Comments</th>
          <th>Detour route</th>
          <th>Update received</th>
          <th>Next update</th>
          <th>Expected resolution</th>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </>
  )
}
