import React from 'react'
import classNames from 'classnames'
import { IconType } from '../../../types/icon-name-types'
import Icon from '../icon'

export interface HeadingProps {
  level: number
  className?: string
  icon?: IconType
  fontWeight?: 'bold' | 'regular'
  noMargin?: boolean
}

export default function Heading({
  level,
  fontWeight = 'bold',
  className,
  icon,
  noMargin,
  children,
}: React.PropsWithChildren<HeadingProps>) {
  const HeadingTag = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  const classes = classNames(className, {
    'c-heading--no-margin': noMargin,
    'c-icon-heading': !!icon,
    [`c-heading--${fontWeight}`]: fontWeight,
  })

  return (
    <HeadingTag className={classes}>
      {icon && <Icon icon={icon} ariaHidden />}
      {children}
    </HeadingTag>
  )
}
