import { ReactElement } from 'react'
import DelayKeys from '../../common/delay-keys'
import PanelSection from '../../layout/panel-section'
import TrafficSegment, { TrafficSegmentProps } from './traffic-segment'
import TrafficMetric from './traffic-metric'
import { prepareTimeForDisplay } from '../../traffic-dashboard'
import { Link } from 'react-router-dom'
import Icon from '../../common/icon'

type TrafficDashboardPanelProps = {
  totalLength: string
  currentTime: number
  speed: string
  trafficSegments: TrafficSegmentProps[]
  returnJourneyUrl?: string | null
}
export default function TrafficDashboardPanel({
  totalLength,
  currentTime,
  speed,
  returnJourneyUrl,
  trafficSegments = [],
}: TrafficDashboardPanelProps): ReactElement {
  return (
    <PanelSection>
      {returnJourneyUrl && (
        <Link className='c-btn c-btn--secondary c-btn--icon-left c-btn--medium' to={returnJourneyUrl}>
          <Icon icon='swap-horiz' className='c-btn__icon' ariaHidden />
          Switch directions
        </Link>
      )}
      <PanelSection className='l-traffic-dashboard__metrics-list' hasBorderBottom hasHorizontalPadding={false}>
        <TrafficMetric label='Distance' metric={totalLength} icon='settings-ethernet' />
        <TrafficMetric label='Current speed' metric={speed} icon='speed' />
        <TrafficMetric label='Current time' metric={prepareTimeForDisplay(currentTime)} icon='schedule' />
      </PanelSection>

      {trafficSegments.length > 0 && (
        <PanelSection hasBorderBottom hasHorizontalPadding={false}>
          <div className='l-traffic-dashboard__traffic-segments'>
            {trafficSegments.map((segment, index) => {
              return (
                <TrafficSegment
                  key={index}
                  journeyTitle={segment.journeyTitle}
                  segmentTime={segment.segmentTime}
                  segmentDistance={segment.segmentDistance}
                  journeyTimeRemaining={segment.journeyTimeRemaining}
                  trafficChanges={segment.trafficChanges}
                  delayStatus={segment.delayStatus}
                />
              )
            })}
          </div>
        </PanelSection>
      )}

      <PanelSection hasHorizontalPadding={false}>
        <DelayKeys />
      </PanelSection>
    </PanelSection>
  )
}
