import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

type ParamDictionary = {
  [param: string]: string
}

export default function useQuery(): [
  URLSearchParams,
  (params: ParamDictionary) => string,
  (params: ParamDictionary) => void,
] {
  const history = useHistory()
  const currLocation = useLocation()

  const search = currLocation?.search

  const params = useMemo(() => new URLSearchParams(search), [search])

  const createLocation = (newParams: ParamDictionary) => {
    const p = new URLSearchParams(params)
    Object.entries(newParams).forEach(([k, v]) => p.set(k, v))

    const location = history.createHref({ ...currLocation, search: p.toString() })

    return location
  }

  const push = (newParams: ParamDictionary) => {
    const l = createLocation(newParams)

    history.push(l)
  }

  return [params, createLocation, push]
}
