import { ReactElement, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import CloneNode from '../components/clone-inner'
import NavigationPanel from '../components/navigation/navigation-panel'
import PanelHeader from '../components/navigation/navigation-panel/panel-header'
import PanelSubNavigation from '../components/navigation/navigation-panel/panel-sub-navigation'
import Tabs from '../components/navigation/tabs'
import useTitle from '../hooks/use-title'
import { useActiveTabState, useTabPropsState } from '../stores/TabNavigationState'
import { defaultTabProps } from '../types/highway-conditions-types'
import { TabTypes } from '../types/tab-name-types'

export default function TrafficAndTravelListView(): ReactElement {
  const isTabletPortraitUp = window.innerWidth > 600
  const activeTabState = useActiveTabState()
  const tabPropsState = useTabPropsState()
  const history = useHistory()
  useTitle(`Traffic and travel table | NZTA Journey Planner`)

  const tab = useMemo(() => {
    return activeTabState.value
  }, [activeTabState.value])

  useEffect(() => {
    if (!isTabletPortraitUp && tab === '') {
      tabPropsState.set(defaultTabProps)
      activeTabState.set(tabPropsState.get().tabs[2].tabType)
    }
    if (!isTabletPortraitUp && (tab === TabTypes.LIST || tab === TabTypes.MAP)) {
      history.push('/highway-conditions')
    }
  }, [tab, activeTabState, history, isTabletPortraitUp, tabPropsState])

  return (
    <>
      {isTabletPortraitUp ? (
        <NavigationPanel>
          <PanelSubNavigation
            title='Traffic and travel table'
            navLayout='titleBelow'
            prevUrl='/highway-conditions'
            prevLabel='Highway conditions'
          />
        </NavigationPanel>
      ) : (
        <div className='c-navigation-panel'>
          <PanelHeader
            filter
            title='Highway Conditions'
            closeAriaLabel='Close highway conditions info modal'
            modalContent={<CloneNode selector='#modal-help__highway-conditions' />}
          />
          <Tabs
            activeTab={activeTabState.get()}
            menuName={tabPropsState.get().menuName}
            tabs={tabPropsState.get().tabs}
            hideOnDesktop
          />
        </div>
      )}
    </>
  )
}
