import { useMemo } from 'react'

export default function useBoundedList<T>(
  list: T[],
  accessor: keyof T,
  elementId?: string,
): [T | null, string | null, string | null] {
  return useMemo(() => {
    if (!elementId) {
      return [null, null, null]
    }
    let nextId: string | null = null
    let prevId: string | null = null

    const itemIndex = list.findIndex((i) => `${i[accessor]}` === elementId)
    const item = itemIndex === -1 ? null : list[itemIndex]

    if (item?.[accessor] != null && list.length > 1) {
      const lastIndex = list.length - 1
      const nextIndex = itemIndex === lastIndex ? 0 : itemIndex + 1
      const prevIndex = itemIndex === 0 ? lastIndex : itemIndex - 1

      nextId = `${list[nextIndex][accessor]}`
      prevId = `${list[prevIndex][accessor]}`
    }

    return [item, nextId, prevId]
  }, [list, elementId, accessor])
}
