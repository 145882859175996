import { ReactElement } from 'react'
import classNames from 'classnames'

export interface TableRowProps {
  route: string
  routeUrl: string
  freeFlowingTime: string
  currentTime: string
  currentFlowState: 'red' | 'orange' | 'green'
}

export default function TableRow({
  route,
  routeUrl,
  freeFlowingTime,
  currentTime,
  currentFlowState,
}: TableRowProps): ReactElement {
  const stateClasses = classNames('c-table__flow-state', {
    [`c-table__flow-state--${currentFlowState}`]: !!currentFlowState,
  })

  return (
    <tr className='c-table__row'>
      <td className='c-table__cell l-traffic-dashboard__route'>
        <a href={routeUrl}>{route}</a>
      </td>
      <td className='c-table__cell'>
        <span className={stateClasses}></span>
        {currentTime}
      </td>
      <td className='c-table__cell'>{freeFlowingTime}</td>
    </tr>
  )
}
