import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IconType } from '../../../types/icon-name-types'
import Icon from '../icon/index'
import LinkList, { LinkItemProps } from '../link-list'

interface ConditionCardProps {
  heading: string
  link: string
  conditionItems?: ConditionItemProps[] | undefined
  expandable?: boolean
  smallLinks?: boolean
}

export type ConditionURLProps = LinkItemProps & {}

export type ConditionItemProps = {
  icon: IconType
  iconLabel?: string
  label?: string
  urls?: ConditionURLProps[]
  expandable?: boolean
  smallLinks?: boolean
  control?: string
}

type ConditionCardParams = {
  regionSlug?: string
  type?: string
  index?: string
}

export const ConditionItem = ({
  icon,
  expandable,
  iconLabel,
  label,
  urls,
  smallLinks = false,
  control,
}: ConditionItemProps) => {
  const { regionSlug, type, index } = useParams<ConditionCardParams>()
  const [isExpanded, setisExpanded] = useState(false)

  const accordionClasses = classNames('c-condition-item__accordion', {
    'c-condition-item__accordion--clickable': expandable,
  })

  // Expands the section when map marker is clicked:
  const currentUrl = regionSlug && type && index ? `/highway-conditions/${regionSlug}/${type}/${index}` : ''
  useEffect(() => {
    const item = urls?.find((u) => u.url === currentUrl)
    if (item) {
      setisExpanded(true)
    }
  }, [currentUrl, urls])

  return (
    <li className='c-condition-item'>
      {expandable ? (
        <button
          aria-controls={control}
          aria-expanded={isExpanded}
          id={`accordion-${control}`}
          className={accordionClasses}
          onClick={() => setisExpanded(!isExpanded)}
        >
          <Icon className='c-condition-item__icon' ariaHidden ariaLabel={iconLabel} icon={icon} />
          <span className='c-condition-item__label'>{label}</span>
          <hr className='c-condition-item__hr' />
          {expandable && (
            <motion.i
              animate={isExpanded ? { rotateX: 180 } : { rotateX: 0 }}
              className="className='c-condition-item__chevron'"
            >
              <Icon icon='chevron-down' ariaLabel={`Expand ${iconLabel}`} />
            </motion.i>
          )}
        </button>
      ) : (
        <div className={accordionClasses}>
          <Icon className='c-condition-item__icon' ariaHidden ariaLabel={iconLabel} icon={icon} />
          <span className='c-condition-item__label'>{label}</span>
          <hr className='c-condition-item__hr' />
        </div>
      )}
      {expandable && (
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              id={control}
              initial={{ height: 0, opacity: 0 }}
              exit={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              style={{ overflow: 'hidden' }}
            >
              <LinkList
                type='nav'
                className='c-condition-item__list'
                url=''
                links={urls}
                linkStyle={smallLinks ? 'small' : ''}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </li>
  )
}

export const ConditionCard = ({ expandable, heading, link, conditionItems, smallLinks }: ConditionCardProps) => {
  const onClick = () => {
    window.scrollTo(0, 0)
  }
  return (
    <li className='c-condition-card'>
      {!!link ? (
        <Link className='c-condition-card__label' to={link} onClick={onClick}>
          {heading}
        </Link>
      ) : (
        <h2 className='c-condition-card__label'>{heading}</h2>
      )}

      <ul className='c-condition-card__list'>
        {conditionItems ? (
          conditionItems.map((item) => {
            return (
              <ConditionItem
                key={item.label}
                control={`${heading} ${item.label}`.replace(/\s+/g, '-').toLowerCase()}
                expandable={expandable}
                icon={item.icon}
                iconLabel={item.iconLabel}
                label={item.label}
                urls={item.urls}
                smallLinks={smallLinks}
              />
            )
          })
        ) : (
          <p>No road closures, road works, road hazards, traffic time and message signs.</p>
        )}
      </ul>
    </li>
  )
}
