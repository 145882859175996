import React from 'react'
import classNames from 'classnames'

/*
  Applies consistent padding around items within map navigation padding.

  For items that need to go all the way to the edge of the panel, omit PanelSection and handle padding internally for that component
*/

interface PanelSectionInterface {
  hasShadowBottom?: boolean
  hasBorderBottom?: boolean
  hasVerticalPadding?: boolean
  hasHorizontalPadding?: boolean
  className?: string
  navigation?: boolean
}

export default function PanelSection({
  children,
  hasVerticalPadding = true,
  hasHorizontalPadding = true,
  hasShadowBottom,
  hasBorderBottom,
  className,
  navigation = false
}: React.PropsWithChildren<PanelSectionInterface>) {
  const classes = classNames('c-navigation-panel__section', {
    'c-navigation-panel__section--shadow-bottom': hasShadowBottom,
    'c-navigation-panel__section--border-bottom': hasBorderBottom,
    'c-navigation-panel__section--no-vert-padding': !hasVerticalPadding,
    'c-navigation-panel__section--no-horizontal-padding': !hasHorizontalPadding,
    [`${className}`]: !!className,
  })



  return  navigation ? <nav aria-label='Secondary Menu' className={classes}>{children}</nav> : <div className={classes}>{children}</div>
}
