import { createState, useState as useHookState } from '@hookstate/core'
import { useEffect, useMemo, useState } from 'react'
import { getMap, useGoogleLoadState } from '../../stores/GoogleMapState'
import { KeyJourney } from '../../types/features'
import { MAP_Z_INDEX } from '../../types/JourneyPlannerTypes'
import { geocodeAddressString, getPathFromCoords } from '../../util/geometry-utils'

const keyJourneyState = createState<KeyJourney | null>(null)

export const useKeyJourneyState = () => {
  return useHookState(keyJourneyState)
}

let bounds: google.maps.LatLngBounds
let startDirectionsMarker: google.maps.Marker
let endDirectionsMarker: google.maps.Marker
let polylines: google.maps.Polyline[] = []
let borderPolyline: google.maps.Polyline
let paths: { path: google.maps.LatLng; color: string; journeyId: string }[] = []
let startDirectionsMarkerPos: google.maps.LatLng | null | undefined
let endDirectionsMarkerPos: google.maps.LatLng | null | undefined

const COLORS = {
  green: '#49a74e',
  orange: '#f0a43a',
  red: '#bc3832',
  markerFill: '#236fa6',
}

export const useKeyJourneyMap = () => {
  const googleLoadState = useGoogleLoadState()
  const keyJourneyItem = keyJourneyState.get()
  const map = getMap()

  // Get the coords from location string IF set on the keyJourneyItem
  // (GoogleStart/EndLocations are only set on a keyJourneyItem when there are no links)
  const [googleStartLoc, setGoogleStartLoc] = useState<number[]>([])
  const [googleEndLoc, setGoogleEndLoc] = useState<number[]>([])
  useMemo(async () => {
    if (keyJourneyItem?.GoogleStartLocation && keyJourneyItem?.GoogleEndLocation && map) {
      const start = await geocodeAddressString(keyJourneyItem?.GoogleStartLocation)
      const end = await geocodeAddressString(keyJourneyItem?.GoogleEndLocation)
      setGoogleStartLoc(start)
      setGoogleEndLoc(end)
    }
  }, [keyJourneyItem?.GoogleStartLocation, keyJourneyItem?.GoogleEndLocation, map])

  // Get the path between to the two points GoogleStart/EndLocation
  const [kjPath, setKjPath] = useState()
  useMemo(async () => {
    if (keyJourneyItem && googleStartLoc && googleEndLoc && map) {
      const polyline = await getPathFromCoords(googleStartLoc, googleEndLoc)
      setKjPath(polyline)
    }
  }, [keyJourneyItem, googleStartLoc, googleEndLoc, map])

  useEffect(() => {
    const googleLoaded = googleLoadState.get()

    if (googleLoaded && map && keyJourneyItem) {
      const journeyIds = keyJourneyItem?.LinkIds?.split(',') ?? [keyJourneyItem.ID]
      const isSingleJourney = !!(journeyIds.length === 1) ?? false

      let links = keyJourneyItem.JourneyLinks
      if (!Array.isArray(keyJourneyItem.JourneyLinks)) {
        links = Object.values(keyJourneyItem.JourneyLinks ?? [])
      }

      const startPoint = links.find((link) => link.properties.LinkId === parseInt(journeyIds[0]))
      const endPoint = links.find((link) => link.properties.LinkId === parseInt(journeyIds[journeyIds.length - 1]))

      const svgMarker = {
        path: 'M 12 12 m -12 -6 a 6 6 0 1 0 12 0 a 6 6 0 1 0 -12 0',
        fillColor: COLORS.markerFill,
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: 'white',
        rotation: 0,
        scale: 1,
        anchor: new google.maps.Point(6, 0),
      }
      journeyIds.forEach((journeyId) => {
        // IF links, then push to paths
        if (links.length) {
          const link = links.find((l) => l.properties.LinkId === parseInt(journeyId))
          link?.geometry.coordinates.forEach((segment) => {
            segment.forEach((startEnd) => {
              paths.push({
                path: new google.maps.LatLng({ lng: startEnd[0], lat: startEnd[1] }),

                color: COLORS[link.properties.Condition],
                journeyId: journeyId,
              })
            })
          })
        }
        // IF NO links, then push googleStart/End location to paths
        if (googleStartLoc && googleEndLoc && isSingleJourney) {
          const googleStartEnd = [googleStartLoc, googleEndLoc]
          googleStartEnd.forEach((startEnd) => {
            if (startEnd.length) {
              paths.push({
                path: new google.maps.LatLng({ lng: startEnd[0], lat: startEnd[1] }),

                color: COLORS[keyJourneyItem.FreeFlowState],
                journeyId: journeyId,
              })
            }
          })
        }
      })

      const renderedJourneyIds: string[] = []

      for (let i = 0; i < paths.length - 1; i++) {
        if (links.length && paths.length) {
          if (
            renderedJourneyIds.includes(paths[i].journeyId) ||
            startPoint?.properties.LinkId === parseInt(paths[i].journeyId)
          ) {
            polylines.push(
              new google.maps.Polyline({
                path: [paths[i].path, paths[i + 1].path],
                strokeColor: paths[i].color,
                strokeOpacity: 1.0,
                strokeWeight: 6,
                map: map,
                zIndex: MAP_Z_INDEX.directionsRenderer,
              }),
            )
          } else {
            polylines.push(
              new google.maps.Polyline({
                path: [paths[i].path, paths[i + 1].path],
                strokeColor: paths[i].color,
                strokeOpacity: 1.0,
                strokeWeight: 6,
                map: map,
                zIndex: MAP_Z_INDEX.directionsRenderer,
                icons: [
                  {
                    icon: svgMarker,
                    offset: '0%',
                  },
                ],
              }),
            )
            renderedJourneyIds.push(paths[i].journeyId)
          }
        }
        if (kjPath && isSingleJourney && paths.length) {
          if (keyJourneyItem && renderedJourneyIds.includes(paths[i].journeyId)) {
            polylines.push(
              new google.maps.Polyline({
                path: kjPath,
                strokeColor: COLORS[keyJourneyItem.FreeFlowState],
                strokeOpacity: 1.0,
                strokeWeight: 6,
                map: map,
                zIndex: MAP_Z_INDEX.directionsRenderer,
              }),
            )
          } else {
            polylines.push(
              new google.maps.Polyline({
                path: kjPath,
                strokeColor: COLORS[keyJourneyItem.FreeFlowState],
                strokeOpacity: 1.0,
                strokeWeight: 6,
                map: map,
                zIndex: MAP_Z_INDEX.directionsRenderer,
                icons: [
                  {
                    icon: svgMarker,
                    offset: '0%',
                  },
                ],
              }),
            )
            renderedJourneyIds.push(paths[i].journeyId)
          }
        }
      }

      if (paths.length) {
        borderPolyline = new google.maps.Polyline({
          path: paths.map((x) => x.path),
          geodesic: true,
          strokeColor: '#FFFFFF',
          strokeOpacity: 0.8,
          strokeWeight: 10,
          zIndex: MAP_Z_INDEX.directionsRendererBorder,
        })
        borderPolyline.setMap(map)
      }

      const startPosition =
        (startPoint &&
          new google.maps.LatLng({
            lng: parseFloat(startPoint.properties.StartLongitude),
            lat: parseFloat(startPoint.properties.StartLatitude),
          })) ??
        (googleStartLoc &&
          new google.maps.LatLng({
            lng: googleStartLoc[1],
            lat: googleStartLoc[0],
          }))

      const endPosition =
        (endPoint &&
          new google.maps.LatLng({
            lng: parseFloat(endPoint.properties.EndLongitude),
            lat: parseFloat(endPoint.properties.EndLatitude),
          })) ??
        (googleEndLoc &&
          new google.maps.LatLng({
            lng: googleEndLoc[1],
            lat: googleEndLoc[0],
          }))

      startDirectionsMarker = new google.maps.Marker({
        position: startPosition,
        icon: '/images/marker-a.png',
        map: map,
      })

      endDirectionsMarker = new google.maps.Marker({
        position: endPosition,
        icon: '/images/marker-b.png',
        map: map,
      })
    }

    startDirectionsMarkerPos = startDirectionsMarker?.getPosition()
    endDirectionsMarkerPos = endDirectionsMarker?.getPosition()

    // Check to confirm coords are present before stepping into setting bounds
    if (map && startDirectionsMarkerPos?.lat() && endDirectionsMarkerPos?.lat()) {
      bounds = new google.maps.LatLngBounds()

      bounds.extend(startDirectionsMarkerPos)
      bounds.extend(endDirectionsMarkerPos)

      // Zoom map to fit markers with padding of 50:
      map.fitBounds(bounds, 50)
      // Set an offset on desktop to make routes visible from behind popoout panel:
      if (window.innerWidth > 1000) {
        map.panBy(-200, 0)
      }
    }

    return () => {
      if (map) {
        if (startDirectionsMarker) {
          startDirectionsMarker.setMap(null)
        }
        if (endDirectionsMarker) {
          endDirectionsMarker.setMap(null)
        }
        if (polylines.length) {
          for (let i = 0; i < polylines.length; i++) {
            polylines[i].setMap(null)
          }
          paths = []
          polylines = []
        }
        if (borderPolyline) {
          borderPolyline.setMap(null)
        }
      }
    }
    // eslint-disable-next-line
  }, [
    googleLoadState.value,
    keyJourneyItem?.ID,
    window.innerWidth,
    startDirectionsMarker,
    endDirectionsMarker,
    googleStartLoc,
    googleEndLoc,
    kjPath,
  ])
}
