import LinkList, { LinkItemProps } from '../../../components/common/link-list'

type LinksOrNoneProps = {
  links: LinkItemProps[]
  fallbackText: string
  type?: 'nav' | 'default'
}

export default function LinksOrNone({ links, fallbackText, type }: LinksOrNoneProps) {
  if (!links.length) {
    return <div>{fallbackText}</div>
  }

  return <LinkList type={type} links={links} />
}
