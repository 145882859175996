/* eslint-disable */
/**
 * Tabs - Copied from NZTA pattern library (/lib/js/components/Tabs.js)
 * Extended to allow using # for other things (don't break journey planner page)
 * And to apply class styles to tab buttons
 */
module.exports = (function () {
  var tabsObj = {
    init: function () {
      // initialise tab events
      this.showTabContent()
      var _self = this
      $(window).on('hashchange', function (e) {
        _self.showTabContent(e)
      })
    },
    showTabContent: function (e) {
      if (
        window.location.hash === '#' ||
        window.location.hash.match(/^#+$/) ||
        window.location.hash.match(/^#[^=]*=[^=]*/) ||
        !window.location.hash
      ) {
        return
      }
      var tabId = window.location.hash.replace('#', '')
      if (tabId) {
        if (e) {
          e.preventDefault()
        }
        try {
          var $trigger = $('#' + tabId + '-trigger')
          var $content = $('#' + tabId + '-content')

          // show tab content
          $content.parent().children().hide()
          $content.show()

          // set active state of tab-link
          $trigger
            .parent()
            .children()
            .removeClass($trigger.data('active-class') || 'is-active')
          $trigger.addClass($trigger.data('active-class') || 'is-active')

          // update document location
          window.location.hash = tabId
        } catch (ex) {
          console.error('Failed to change tab', ex)
        }
      }
    },
  }
  return tabsObj
})()
