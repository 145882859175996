import { useEffect } from 'react'
import { createState, useState } from '@hookstate/core'
import { loadDataFromAssetFile } from '../../services/map-data-loader'
import { GeojsonFeature, TrafficCamera } from '../../types/JourneyPlannerTypes'

const camerasState = createState(0)
let allCameras: GeojsonFeature<TrafficCamera>[] = []
let loadStarted = false
export const useCamerasState = () => {
  useEffect(() => {
    // TODO initiate a refresh every 5 minutes?
    if (!camerasState.get() && !loadStarted) {
      loadData()
    }
  }, [])
  return useState(camerasState)
}
export const getCameras = () => {
  return allCameras
}

async function loadData() {
  loadStarted = true
  const camerasData = await loadDataFromAssetFile('cameras.json')
  loadStarted = false
  if (camerasData) {
    allCameras = camerasData.features ?? []
    camerasState.set(camerasData.lastUpdated)
  }
  return []
}

