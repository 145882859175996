import { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useIntegratedNav } from '../services/IntegratedNav'
import { loadAndCreateMap, useGoogleLoadState } from '../stores/GoogleMapState'
import { useMapFullscreenState } from '../stores/journey-planner/JourneySearchState'
import WeatherWidget from './highway-conditions/weather-widget'

export default function MapArea() {
  const isTabletPortraitUp = window.innerWidth > 900
  const history = useHistory()

  // Here instead of App because it needs to be underneath something rendered with BrowserRouter
  // otherwise useHistory never gets set
  useIntegratedNav(history)

  const mapFullscreenState = useMapFullscreenState()
  const googleLoaded = useGoogleLoadState()

  // On mount set up map
  useEffect(() => {
    loadAndCreateMap(mapFullscreenState)

    // On unmount reset googleLoadState in order to reinitialise map setup next mount
    return () => {
      googleLoaded.set(false)
    }
    // eslint-disable-next-line
  }, [])

  const weatherRoutes = [
    '/highway-conditions/:regionSlug([\\w\\-]+)/:detail?/:id?',
    '/traffic-cameras/:regionSlug([\\w\\-]+)/:id?',
    '/regions/:regionSlug([\\w\\-]+)',
  ]

  // This just creates a Google map, other services (under separate routes)
  // update the map using custom js / via Google maps lib (not react output)
  return (
    <>
      <div id='js-map-container' className='l-map-tool__map-container' title='Map' aria-hidden='true' />
      <Switch>
        {isTabletPortraitUp && weatherRoutes.map((path) => <Route exact path={path} component={WeatherWidget} />)}
      </Switch>
    </>
  )
}
