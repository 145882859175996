import React from 'react'
import { Link } from 'react-router-dom'
import Icon, { IconProps } from '../../../components/common/icon'

interface OverviewItemProps extends IconProps {
  label: string
  url: string
  ariaLabel?: string
  isSpaRoute: boolean
  isExtRoute: boolean
}

const Anchor = ({ isSpaRoute, isExtRoute, children, url }: React.PropsWithChildren<{ isSpaRoute: boolean; isExtRoute:boolean; url: string }>) => {
  if (isSpaRoute) {
    return (
      <Link className='l-region-overview__item' to={url}>
        {children}
      </Link>
    )
  }
  else if (isExtRoute) {
    return (
      <a className='l-region-overview__item' href={url} target='_blank' rel='noopener noreferrer'>
        {children}
      </a>
    )
  }

  return (
    <a className='l-region-overview__item' href={url} rel='noopener noreferrer'>
      {children}
    </a>
  )
}

export default function OverviewItem({ icon, label, ariaLabel, url, isSpaRoute, isExtRoute }: OverviewItemProps) {
  return (
    <Anchor isSpaRoute={isSpaRoute} isExtRoute={isExtRoute} url={url}>
      {ariaLabel == null && <Icon className='l-region-overview__icon' ariaHidden icon={icon} />}
      {ariaLabel != null && <Icon className='l-region-overview__icon' ariaLabel={ariaLabel} icon={icon} />}

      <span className='l-region-overview__label'>{label}</span>
    </Anchor>
  )
}
