import { useEffect } from 'react'
import { getMap, useGoogleLoadState } from '../stores/GoogleMapState'

export const useReturnMapToDefaultView = () => {

  const mapLoaded = useGoogleLoadState()
  const map = getMap()
  useEffect(() => {
    if (mapLoaded.value && map) {
      map.setCenter({ lat: -41.2889, lng: 174.7772 })
      map.setZoom(5)
      map.fitBounds({
        east: 178.644431,
        north: -34.094485,
        south: -47.317516,
        west: 166.129201,
      })
    }
  }, [mapLoaded.value, map])
}
