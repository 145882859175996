import { useEffect } from 'react'
import {
  loadJourneyResults,
  usePlaceASearchFailedState,
  usePlaceBSearchFailedState,
  usePlaceStateA,
  usePlaceStateB,
  useJourneyResultState,
  useSelectedRouteIndexState,
  setSelectedRouteIndex,
  clearJourneyResults,
  useActiveSearchState,
  useInvalidSearchState,
} from '../stores/journey-planner/JourneySearchState'
import { Switch, Route, useHistory, useLocation, useParams } from 'react-router-dom'
import JourneyRouteListing from './JourneyRouteListing'
import JourneyRouteDetails from './JourneyRouteDetails'
import PanelSection from './layout/panel-section'
import { IndexParam } from '../types/JourneyPlannerTypes'
import { useJourneyPlannerMap } from '../services/map/JourneyPlannerMap'
import useActiveMapItem from '../hooks/use-active-map-item'

export default function JourneyRouteListArea() {
  // TODO: Combine state using reducer / context pattern
  const { search } = useLocation()
 
  const history = useHistory()
  const placeASearchFailed = usePlaceASearchFailedState().get()
  const placeBSearchFailed = usePlaceBSearchFailedState().get()
  const placeAState = usePlaceStateA()
  const placeBState = usePlaceStateB()
  const { index } = useParams<IndexParam>()
  const selectedRouteId = parseInt(index, 10) || 0
  const selectedRouteIndex = useSelectedRouteIndexState().get()
  const journeyResultState = useJourneyResultState()
  const activeSearchState = useActiveSearchState()
  const invalidSearchState = useInvalidSearchState()
  const [,setActiveItem] = useActiveMapItem()
  useJourneyPlannerMap()

  useEffect(() => {
    return () => {
      clearJourneyResults()
      setActiveItem(null)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(search)
    if (!query.get('a') || !query.get('b')) {
      history.replace(`/journey-planner/${search}`)
    }
  }, [history, search])

  const isValidSearch = placeAState.get() && placeBState.get() && !placeASearchFailed && !placeBSearchFailed

  // Load the routes if we have valid search and are on this view
  useEffect(() => {
    const placeA = placeAState.get()
    const placeB = placeBState.get()
    if (placeA && placeB && placeA.geometry && placeB.geometry && !placeASearchFailed && !placeBSearchFailed) {
      loadJourneyResults(placeA, placeB)
      if (invalidSearchState.get()) {
        invalidSearchState.set(false)
      }
      if (!activeSearchState.get()) {
        activeSearchState.set(true)
      }
    }
  }, [placeAState, 
      placeBState, 
      activeSearchState, 
      invalidSearchState, 
      placeASearchFailed, 
      placeBSearchFailed])

  // Set the selected route from the index in the URL path
  useEffect(() => {
    const journeyResult = journeyResultState.get()
    if (journeyResult?.routes?.length && selectedRouteIndex !== selectedRouteId) {
      setSelectedRouteIndex(journeyResult?.routes?.[selectedRouteId] ? selectedRouteId : 0)
    }
  }, [selectedRouteIndex, selectedRouteId, journeyResultState])

  let display
  if (placeASearchFailed || placeBSearchFailed) {
    display = <p>One of the locations searched about could not be resolved, please update it and try again.</p>
  } else if (!isValidSearch) {
    display = <p>No routes found, please update your journey search and try again.</p>
  } else {
    const title =
      placeAState.value?.title && placeBState.value?.title
        ? `${placeAState.value.title} to ${placeBState.value.title}`
        : ''
    return (
      <Switch>
        <Route path={`/journey-planner/commute/${selectedRouteId}/details`}>
          <JourneyRouteDetails selectedRouteId={selectedRouteId} />
        </Route>
        <Route path='*'>
          <JourneyRouteListing selectedRouteId={selectedRouteId} title={title} />
        </Route>
      </Switch>
    )
  }
  return (
    <PanelSection>
      <h2>Results</h2>
      {display}
    </PanelSection>
  )
}
