import { ReactElement, useEffect, useRef } from 'react'
import Content from '../layout/content/index'
import { TableRowProps } from './table-row'
import JourneyGroup from './journey-group'
import DelayKeys from '../common/delay-keys'
import { useKeyJourneysInRegion } from '../../stores/national-data/key-journeys'
import { useParams } from 'react-router-dom'
import { getRegions } from '../../stores/national-data/regions'
import { KeyJourneyFeature } from '../../types/features'

type RegionListParams = {
  regionSlug: string
  type?: string
  index?: string
}

export const prepareTimeForDisplay = (time?: number): string => {
  if (time === undefined) {
    return '-'
  } else if (time < 60) {
    return `${time} mins`
  } else {
    let hours = Math.floor(time / 60)
    let minutes = time % 60
    return `${hours} h ${minutes} mins`
  }
}

export default function TrafficDashboard(): ReactElement {
  const { regionSlug } = useParams<RegionListParams>()
  const regions = getRegions()
  const region = regions.find((r) => r.properties.slug === regionSlug)
  const regionName = region?.properties.name
  const [, keyJourneys] = useKeyJourneysInRegion(regionSlug)

  const journeyGroups: { SortOrder: number; Title: string; GroupID: number }[] = []

  keyJourneys?.forEach((journey) => {
    if (!journeyGroups.some((group) => group.GroupID === journey.properties.GroupID)) {
      journeyGroups.push({ GroupID: journey.properties.GroupID, ...journey.properties.JourneyGroup })
    }
  })

  const preparetableRow = (featureData: KeyJourneyFeature): TableRowProps => {
    const data = featureData.properties
    return {
      route: data.Title,
      routeUrl: `traffic-dashboard/${data.URLSlug}`,
      freeFlowingTime: prepareTimeForDisplay(data.FreeFlowTime),
      currentTime: prepareTimeForDisplay(data.CurrentTime),
      currentFlowState: data.FreeFlowState,
    }
  }

  const journeysByGroup = (GroupID: number) => {
    const groupedJourneys = keyJourneys.filter((journey) => journey.properties.GroupID === GroupID)
    const sortedJourneys = groupedJourneys.sort((a, b) => (a.properties.SortOrder > b.properties.SortOrder ? 1 : -1))

    return sortedJourneys.map((j) => preparetableRow(j))
  }

  const titleRef = useRef(null)
  useEffect(() => {
    if (titleRef.current) titleRef.current.focus()
  }, [titleRef])
  return (
    <Content>
      <div className='l-traffic-dashboard' >
        <div className="remove-outline" aria-live='polite' ref={titleRef} tabIndex={0}>
          <h1>Traffic dashboard for {regionName}</h1>
        </div>
        <DelayKeys />
        {journeyGroups
          .sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1))
          .map((group) => {
            return <JourneyGroup items={journeysByGroup(group.GroupID)} groupTitle={group.Title} />
          })}
      </div>
    </Content>
  )
}
