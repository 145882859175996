import {
  usePlaceStateA,
  usePlaceStateB,
  useSelectedRouteIndexState,
  useJourneyResultState,
  getJourneyDirections,
} from '../../stores/journey-planner/JourneySearchState'
import { useGoogleLoadState, getMap } from '../../stores/GoogleMapState'
import { useEffect } from 'react'
import { JourneyResult, JourneySearchPoint, MAP_Z_INDEX, RelatedItem } from '../../types/JourneyPlannerTypes'
import { useFilterState } from '../../stores/MapFiltersState'
import { clearMarkerCollection, useMarkerCollection } from './MarkerCollection'
import { useLocation, useHistory } from 'react-router-dom'
import { getClosures } from '../../stores/national-data/delays'

let directionsRenderer: google.maps.DirectionsRenderer
let directionsRendererBorder: google.maps.DirectionsRenderer
let startDirectionsMarker: google.maps.Marker
let endDirectionsMarker: google.maps.Marker

let lastDirectionsHash: string

let pointA: google.maps.Data.Feature | null
let pointB: google.maps.Data.Feature | null
let alternateRoutesLayer: google.maps.Data | null
let routeBordersLayer: google.maps.Data | null

const updatePoint = (
  map: google.maps.Map,
  feature: google.maps.Data.Feature | null,
  geometry: google.maps.LatLng | null,
) => {
  if (!map) return null
  if (feature) {
    // TODO, potentially update instead of removing/re-adding?
    map.data.remove(feature)
  }
  if (geometry) {
    return map.data.addGeoJson({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: [geometry.lng(), geometry.lat()],
      },
    })[0]
  }
  return null
}

const renderDirections = (
  map: google.maps.Map,
  journeyResult: JourneyResult | null,
  placeA: JourneySearchPoint | null,
  placeB: JourneySearchPoint | null,
  selectedRouteIndex: number,
  history: any,
  search: any,
) => {
  if (!map || !directionsRenderer || !directionsRendererBorder) return
  const directions = getJourneyDirections()
  // We render the non-selected routes as separate grey clickable lines for switching route
  // because DirectionsRenderer doesn't allow styling the different lines differently.
  // We also render a separate border layer underneath to give a dark outline around each line.

  if (alternateRoutesLayer) {
    alternateRoutesLayer.setMap(null)
    alternateRoutesLayer = null
  }
  if (routeBordersLayer) {
    routeBordersLayer.setMap(null)
    routeBordersLayer = null
  }
  if (journeyResult && directions && typeof journeyResult.locationHash !== 'undefined') {
    directionsRenderer.setOptions({
      preserveViewport: lastDirectionsHash === journeyResult.locationHash,
      directions,
      routeIndex: selectedRouteIndex,
      map,
      suppressMarkers: true,
    })
    directionsRendererBorder.setOptions({
      preserveViewport: true,
      directions,
      routeIndex: selectedRouteIndex,
      map,
      suppressMarkers: true,
    })

    if (placeA && placeB) {
      // Set location to be the start and end of the directions
      startDirectionsMarker.setOptions({ position: placeA.geometry, map, opacity: 1 })
      endDirectionsMarker.setOptions({ position: placeB.geometry, map, opacity: 1 })
    }

    lastDirectionsHash = journeyResult.locationHash
    // Render the other (non-selected) routes and also a background route to give it an outline
    if (journeyResult.routes.length > 1) {
      alternateRoutesLayer = new google.maps.Data({
        map: map,
        style: (feature) => {
          return {
            strokeColor: '#b6b6b6',
            strokeOpacity: 0.8,
            strokeWeight: 4,
            zIndex: MAP_Z_INDEX.alternateRoutesBase + (feature.getProperty('id') + 1) * 2,
            visible: feature.getProperty('id') !== selectedRouteIndex,
          }
        },
      })
      alternateRoutesLayer.addGeoJson({
        type: 'FeatureCollection',
        features: journeyResult.routes,
      })
      alternateRoutesLayer.addListener('click', (event: google.maps.Data.MouseEvent) => {
        if (event.feature.getProperty('id') !== selectedRouteIndex) {
          history.push(`/journey-planner/commute/${event.feature.getProperty('id')}${search.toString()}`)
        }
      })
      routeBordersLayer = new google.maps.Data({
        map: map,
        style: (feature) => {
          return {
            strokeColor: '#6b6b6b',
            strokeWeight: 6,
            clickable: false,
            zIndex: MAP_Z_INDEX.alternateRoutesBorderBase + (feature.getProperty('id') + 1) * 2,
            visible: feature.getProperty('id') !== selectedRouteIndex,
          }
        },
      })
      routeBordersLayer.addGeoJson({
        type: 'FeatureCollection',
        features: journeyResult.routes,
      })
    }
    pointA = updatePoint(map, pointA, null)
    pointB = updatePoint(map, pointB, null)
  } else {
    // Remove all things from the map if not being used
    pointA = updatePoint(map, pointA, placeA?.geometry || null)
    pointB = updatePoint(map, pointB, placeB?.geometry || null)
    directionsRenderer.setMap(null)
    directionsRendererBorder.setMap(null)
  }
}

let googleLoadStateCache: any
let placeACache: any
let placeBCache: any
let journeyResultCache: any
let relatedDataCache: any
let selectedRouteIndexCache: any

export const useJourneyPlannerMap = () => {
  const history = useHistory()
  const search = useLocation().search
  const googleLoadState = useGoogleLoadState()
  const placeAState = usePlaceStateA()
  const placeBState = usePlaceStateB()
  const journeyResultState = useJourneyResultState()
  const selectedRouteIndexState = useSelectedRouteIndexState()
  const [, updateCollection] = useMarkerCollection()
  const filterState = useFilterState()

  useEffect(() => {
    const googleLoaded = googleLoadState.get()
    const placeA = placeAState.get()
    const placeB = placeBState.get()
    const journeyResult = journeyResultState.get()
    const selectedRouteIndex = selectedRouteIndexState.get()
    const mapFilters = filterState.get()
    const map = getMap()
    if (googleLoaded && map) {
      if (!directionsRenderer) {
        directionsRenderer = new google.maps.DirectionsRenderer({
          // draggable: true
          polylineOptions: {
            zIndex: MAP_Z_INDEX.directionsRenderer,
            strokeColor: '#709def',
            strokeWeight: 6,
          },
        })
      }
      if (!directionsRendererBorder) {
        directionsRendererBorder = new google.maps.DirectionsRenderer({
          polylineOptions: {
            zIndex: MAP_Z_INDEX.directionsRendererBorder,
            strokeColor: '#6b6b6b',
            strokeWeight: 8,
            clickable: false,
          },
          suppressMarkers: true,
        })
      }
      if (!startDirectionsMarker || !endDirectionsMarker) {
        // Assign to persistent objects (like directionsREnderer),
        // and then hide when there are no directions being shown
        startDirectionsMarker = new google.maps.Marker({
          icon: '/images/marker-a.png',
        })
        endDirectionsMarker = new google.maps.Marker({
          icon: '/images/marker-b.png',
        })
      }
      const loadedRelated = !!journeyResult?.routes?.some((r) => r?.properties.hasLoadedRelated)
      if (
        googleLoaded !== googleLoadStateCache ||
        placeA?.search !== placeACache ||
        placeB?.search !== placeBCache ||
        journeyResult?.locationHash !== journeyResultCache ||
        selectedRouteIndex !== selectedRouteIndexCache ||
        loadedRelated !== relatedDataCache
      ) {
        renderDirections(map, journeyResult, placeA, placeB, selectedRouteIndex, history, search)
      }

      // Get all related feature excluding general warnings (these shouldn't show on map)
      let features: RelatedItem[] =
        journeyResult?.routes?.[selectedRouteIndex]?.properties?.related?.filter((feature: RelatedItem) => {
          return feature.properties.type !== 'general-warnings' && mapFilters?.[feature.properties.type].enabled
        }) || []
      // Need to get features from Proxy objects
      features = JSON.parse(JSON.stringify(features))

      // Need to always inlcude closures when routes are plotted but ensure no doubled up on events
      let closures = getClosures()

      features.forEach((f) => {
        closures.forEach((c) => {
          if (f.properties.id === c.properties.id) {
            const index = features.indexOf(f)

            features.splice(index, 1)
          }
        })
      })
      const uniqueEvents = features.concat(closures)

      // Update rendered marker collection with new features
      updateCollection(uniqueEvents)

      relatedDataCache = loadedRelated
      googleLoadStateCache = googleLoaded
      placeACache = placeA?.search
      placeBCache = placeB?.search
      journeyResultCache = journeyResult?.locationHash
      selectedRouteIndexCache = selectedRouteIndex
    }
    // eslint-disable-next-line
  }, [googleLoadState, placeAState, placeBState, journeyResultState, selectedRouteIndexState])

  useEffect(() => {
    return () => {
      clearMarkerCollection()
      const map = getMap()
      if (map) {
        if (startDirectionsMarker) {
          startDirectionsMarker.setMap(null)
        }
        if (endDirectionsMarker) {
          endDirectionsMarker.setMap(null)
        }
        if (directionsRenderer) {
          directionsRenderer.setMap(null)
        }
        if (directionsRendererBorder) {
          directionsRendererBorder.setMap(null)
        }
        if (pointA) {
          map.data.remove(pointA)
        }
        if (pointB) {
          map.data.remove(pointB)
        }
        if (alternateRoutesLayer) {
          alternateRoutesLayer.setMap(null)
        }
        if (routeBordersLayer) {
          routeBordersLayer.setMap(null)
        }
      }
    }
  }, [])
}
