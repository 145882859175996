import { useEffect } from 'react'
import { createState, useState } from '@hookstate/core'
import { GeojsonFeature, TimSign } from '../../types/JourneyPlannerTypes'
import { loadDataFromAssetFile } from '../../services/map-data-loader'

const ttsState = createState(0)
let allTrafficTimeSigns: GeojsonFeature<TimSign>[] = []
let loadStarted = false

const useLoadIfNeeded = () => {
  useEffect(() => {
    // TODO initiate a refresh every 5 minutes?
    if (!ttsState.get() && !loadStarted) {
      loadData()
    }
  }, [])
}

export const useTrafficTimeState = () => {
  useLoadIfNeeded()
  return useState(ttsState)
}

export const getTrafficTimeSigns = () => {
  return allTrafficTimeSigns
}

async function loadData() {
  loadStarted = true
  const ttsData = await loadDataFromAssetFile('time.json')
  loadStarted = false
  if (ttsData) {
    allTrafficTimeSigns = ttsData.features ?? []
    ttsState.set(1)
  }
  return []
}

