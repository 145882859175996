import { MapDataFeatureCollection } from '../types/JourneyPlannerTypes'

export async function loadDataFromAssetFile(file: string): Promise<MapDataFeatureCollection<any>> {
  const host = document.getElementById('root')?.dataset?.host || ''
  return fetch(host + '/assets/map-data-cache/' + file, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .catch((err) => {
      console.error('Failed to load national map data', file, err)
    })
}
