import React from 'react'
import { Key } from '../key'

export default function DelayKeys() {
  return (
    <div>
      <div>
        <strong>Delays compared to free flowing</strong>
      </div>
      <div className='c-delay-keys'>
        <Key colour='green' label='<25%' shape='circle' />
        <Key colour='orange' label='25%-50%' shape='square' />
        <Key colour='red' label='50%+' shape='triangle' />
      </div>
    </div>
  )
}
