import { useEffect } from 'react'
import { createState, useState } from '@hookstate/core'
import { loadDataFromAssetFile } from '../../services/map-data-loader'
import { GeojsonFeature, Highway } from '../../types/JourneyPlannerTypes'

// NOTE these are called "journeys" in TAS (and in our backend)
// But we're only using them for their highway labels here
const highwaysState = createState(0)
let allHighways: GeojsonFeature<Highway>[] = []
let loadStarted = false
export const useHighwaysState = () => {
  useEffect(() => {
    // TODO initiate a refresh every 5 minutes?
    if (!highwaysState.get() && !loadStarted) {
      loadData()
    }
  }, [])
  return useState(highwaysState)
}
export const getHighways = () => {
  return allHighways
}

async function loadData() {
  loadStarted = true
  const highwaysData = await loadDataFromAssetFile('journeys.json')
  loadStarted = false
  if (highwaysData) {
    highwaysData.features.forEach((h) => {
      h.properties.id = parseInt(h.properties.id, 10)
      h.properties.regions?.forEach((r: any) => (r.id = parseInt(r.id, 10)))
    })
    allHighways = highwaysData.features ?? []
    highwaysState.set(highwaysData.lastUpdated)
  }
  return []
}

