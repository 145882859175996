import { motion } from 'framer-motion'
import { ComponentPropsWithRef, forwardRef } from 'react'
import Button from '../button'

interface PopOutPanelContentProps extends ComponentPropsWithRef<'div'> {
  title?: string
  closeAction: () => void
}

const PopOutPanelContent = forwardRef(({ children, title, closeAction }: PopOutPanelContentProps, ref) => {
  return (
    <motion.div
      transition={{ ease: 'easeInOut' }}
      initial={{ x: '-100%' }}
      animate={{ x: '0%' }}
      exit={{ x: '-100%' }}
      className='c-pop-out-panel'
    >
      {title && (
        <div className='c-pop-out-panel__header'>
          <h2 className='c-pop-out-panel__heading' ref={ref} tabindex='0'>
            {title}
          </h2>
          <Button
            buttonStyle='icon'
            icon='cross-circle-solid'
            iconAriaLabel='Close filter panel'
            onClick={closeAction}
          />
        </div>
      )}

      {children}
    </motion.div>
  )
})

export default PopOutPanelContent
