import { useEffect } from "react"
import { getMap, useGoogleLoadState } from "../../stores/GoogleMapState"
import { useFilterState } from "../../stores/MapFiltersState"

export const useTrafficLayer = () => {
  const mapFilters = useFilterState().get()
  const trafficLayerFilter = mapFilters['traffic-layer']
  const map = getMap()
  const googleLoaded = useGoogleLoadState()
  let trafficLayer: google.maps.TrafficLayer

  useEffect(() => {
    if(map && googleLoaded.value && trafficLayerFilter.enabled) {
       // eslint-disable-next-line
      trafficLayer = new google.maps.TrafficLayer()
      trafficLayer.setMap(map)
    }

    return () => {
      if(map && !trafficLayerFilter.enabled) {
        trafficLayer.setMap(null)
      }
    }
  }, [trafficLayerFilter.enabled])
}
