import GeojsonUtils from './geojson-utils'
import simplify from 'simplify-geojson'

const locationsEqual = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

const sum = (a: Array<number>) => {
  return a.reduce((n, t) => t + n, 0)
}

const RouteLoader = {
  loadRoutes(a: google.maps.LatLng, b: google.maps.LatLng, onDone: Function) {
    if (!a || !b || locationsEqual(a, b)) {
      return onDone(null, [])
    }
    // this.relatedGeojson = GeojsonUtils.createFeatureCollection([])
    this.fetchGoogleRoutes(a, b, null, new Date(Date.now()), onDone)
    // TODO when loading detours, don't clear them if we've only swapped directions?
  },

  /**
   * Fetches route(s) from a to b
   * @param {Array} a - google.maps.LatLng
   * @param {Array} b - google.maps.LatLng
   * @param {Object} detour - or null
   * @param {Date} departureTime - or null, only applicable if mode is DRIVING
   * @param {function} cb - called with (google.maps.DirectionsResult|null, array of routes) - routes may be empty array
   */
  fetchGoogleRoutes(
    a: google.maps.LatLng,
    b: google.maps.LatLng,
    detour: any | null,
    departureTime: Date | null,
    cb: Function,
  ) {
    let directionService
    try {
      directionService = new window.google.maps.DirectionsService()
    } catch (ex) {
      console.error('Failed to load Google Directions Service')
      cb(null, [])
      return
    }
    // TODO do we want to use a directions renderer? Means we might get things like drag drop
    // need to know if it means we can still style as needed
    // we might need it to only render the "selected" result, not sure if that's easy enough
    // or surely the DR supplies some kind of "selected" switching etc?
    const req: google.maps.DirectionsRequest = {
      origin: { lat: a.lat(), lng: a.lng() },
      destination: { lat: b.lat(), lng: b.lng() },
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: window.google.maps.UnitSystem.METRIC,
      provideRouteAlternatives: true,
      region: 'NZ',
    }
    if (departureTime) {
      req.drivingOptions = {
        departureTime,
      }

      if (detour) {
        req.waypoints = detour.waypoints
        req.optimizeWaypoints = true
        req.provideRouteAlternatives = false
      }
    }
    const processDirectionResults = (
      res: google.maps.DirectionsResult | null,
      status: google.maps.DirectionsStatus,
    ) => {
      if (status === google.maps.DirectionsStatus.OK && res) {
        // TODO handle Google route warnings
        const routes = res.routes.map((route: google.maps.DirectionsRoute, i) => {
          // Create an initial feature to pass to "simplify"
          const routeGeomFeature = GeojsonUtils.createFeature(
            {},
            'LineString',
            route.overview_path.map((p) => [p.lng(), p.lat()]),
          )
          return GeojsonUtils.createFeature(
            {
              id: i,
              name: this.getRouteName(route),
              comparativeTime: this.formatComparativeTime(route),
              currentTime: this.formatTime(this.getRouteTime(route)),
              unformattedTime: this.getRouteTime(route),
              normalTime: this.showNormalTime(route) ? this.formatTime(this.getNormalTime(route)) : '',
              totalLength: sum(route.legs.map((leg) => leg?.distance?.value || 0)) / 1000,
              startLatitude: route.legs[0].start_location.lat(),
              startLongitude: route.legs[0].start_location.lng(),
              endLatitude: route.legs[route.legs.length - 1].end_location.lat(),
              endLongitude: route.legs[route.legs.length - 1].end_location.lng(),
              hasLoadedRelated: false,
              related: [],
              isGoogleRoute: true,
              nextPTDepartsAt: route.legs[0].departure_time && route.legs[0].departure_time.text,
              overviewPolyline: route.overview_polyline,
              hasTolls: this.routeHasTolls(route),
              isTollRoad: this.routeHasTolls(route) ? 'Yes' : 'No',
              simpleGeometry: simplify(routeGeomFeature, 0.0005).geometry,
            },
            'LineString',
            routeGeomFeature.geometry.coordinates,
          )
        })
        cb(res, routes)
      } else {
        cb(null, [])
      }
    }
    try {
      directionService.route(req, processDirectionResults)
    } catch (ex) {
      cb(null, [])
    }
  },

  getBestDuration(leg: google.maps.DirectionsLeg) {
    const value = (leg.duration_in_traffic && leg.duration_in_traffic.value) || (leg.duration && leg.duration.value)
    return value || 0
  },

  getRouteTime(route: google.maps.DirectionsRoute) {
    return Math.round(sum(route.legs.map((leg) => this.getBestDuration(leg) / 60)))
  },

  formatTime(totalMins: number) {
    let time = ''
    const days = Math.floor(totalMins / 1440)
    if (days) {
      time += days + 'd '
      totalMins = totalMins % 1440
    }
    const hours = Math.floor(totalMins / 60)
    if (hours) {
      time += hours + 'h '
      totalMins = totalMins % 60
    }
    time += totalMins + (time ? 'm' : ' mins')
    return time
  },

  // Show normal time if we also have the accurate current time
  showNormalTime(route: google.maps.DirectionsRoute) {
    return route.legs.every((leg) => {
      return leg.duration_in_traffic && leg.duration_in_traffic.value && leg.duration && leg.duration.value
    })
  },

  getNormalTime(route: google.maps.DirectionsRoute) {
    return Math.round(sum(route.legs.map((leg) => (leg?.duration?.value || 0) / 60)))
  },

  getRouteName(route: any) {
    // Not sure why the TS interface doesn't have this property
    if (route.summary) {
      return route.summary
    }
    return 'Unnamed route'
  },

  formatComparativeTime(route: any) {
    const diff = this.getRouteTime(route) - this.getNormalTime(route)
    if (diff > 0) {
      return Math.abs(diff) + ' min slower than normal'
    } else if (diff < 0) {
      return Math.abs(diff) + ' min faster than normal'
    } else {
      return ''
    }
  },

  routeHasTolls(route: google.maps.DirectionsRoute) {
    const allInstructions = route.legs.map((leg) => leg.steps.map((step) => step.instructions))
    return JSON.stringify(allInstructions).toLowerCase().indexOf('toll road') >= 0
  },
}

export default RouteLoader;