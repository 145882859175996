import React, { ReactElement } from 'react'
import TableRow, { TableRowProps } from '../table-row'

export interface JourneyGroupProps {
  groupTitle: string
  items: TableRowProps[]
}

export default function JourneyGroup({ items, groupTitle }: JourneyGroupProps): ReactElement {
  return (
    <div className='l-traffic-dashboard__journey-group'>
      <h3 className='l-traffic-dashboard__group-heading'>{groupTitle}</h3>

      <table className='c-table'>
        <thead className='c-table__header'>
          <th className='c-table__column-heading'>Route</th>
          <th className='c-table__column-heading l-traffic-dashboard__current'>Current</th>
          <th className='c-table__column-heading l-traffic-dashboard__free-flowing'>Free flowing</th>
        </thead>

        <tbody>
          {/* Table Row */}
          {items.map((item) => {
            return <TableRow {...item} />
          })}
        </tbody>
      </table>
    </div>
  )
}
