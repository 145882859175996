import { ReactElement } from 'react'
import { usePanToItem } from '../../../services/map/MarkerCollection'
import { EVChargingSite } from '../../../types/JourneyPlannerTypes'
import { InfoList } from '../../common/info-list'
import PanelSection from '../../layout/panel-section'
import PanelFooter from '../panel-footer/index'

interface Props {
  item: EVChargingSite
  itemLinks?: {
    nextUrl: string,
    prevUrl: string
  }
}

export default function EVChargingSitePanel({ item, itemLinks }: Props): ReactElement {
  const connectors = item.connectors.map((c) => {
    const info = [c.Current, c.KwRated, c.ConnectorType].filter((i) => !!i).join(', ')
    return (
      <p>
        {info} - Status: {c.OperationStatus}
        {c.NextPlannedOutage && <div>{c.NextPlannedOutage}</div>}
      </p>
    )
  })

  const evInfoList = [
    { label: 'Address', data: item.Address },
    { label: 'Owner', data: item.OwnerName },
    { label: 'Connectors', data: connectors },
    { label: 'Cost', data: item.ChargingCost },
  ]

  usePanToItem({ type: item?.type, id: item?.id, focus: true })

  return (
    <PanelSection>
      <InfoList items={evInfoList} styleClass='c-pop-out-panel__ev' />
      <PanelFooter footerLinks={itemLinks} />
    </PanelSection>
  )
}
