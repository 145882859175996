import { useEffect } from 'react'
import { createState, useState } from '@hookstate/core'
import { GeojsonFeature, EVChargingSite } from '../../types/JourneyPlannerTypes'
import { loadDataFromAssetFile } from '../../services/map-data-loader'

const evChargersState = createState(0)
let allChargingSites: GeojsonFeature<EVChargingSite>[] = []
let loadStarted = false

const useLoadIfNeeded = () => {
  useEffect(() => {
    // TODO initiate a refresh every 5 minutes?
    if (!evChargersState.get() && !loadStarted) {
      loadData()
    }
  }, [])
}

export const useEvChargersState = () => {
  useLoadIfNeeded()
  return useState(evChargersState)
}

export const getChargingSites = () => {
  return allChargingSites
}

async function loadData() {
  loadStarted = true
  const evData = await loadDataFromAssetFile('chargers.json')
  loadStarted = false
  if (evData) {
    allChargingSites = evData.features ?? []
    evChargersState.set(1)
  }
  return []
}

