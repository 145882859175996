import classNames from 'classnames'
import { ReactElement, useEffect, useRef } from 'react'
import Button from '../../../common/button'
import Heading from '../../../common/heading'
import PanelSection from '../../../layout/panel-section'

interface PanelSubNavigationInterface {
  title?: string
  navLayout?: 'inline' | 'titleAbove' | 'titleBelow'
  nextUrl?: string
  nextLabel?: string
  prevUrl?: string
  prevLabel?: string
  hasBorderBottom?: boolean
  headingLevel?: number
}

export default function PanelSubNavigation({
  title,
  navLayout = 'inline',
  prevUrl,
  prevLabel = '',
  nextUrl,
  nextLabel = '',
  hasBorderBottom = true,
  headingLevel = 2,
}: PanelSubNavigationInterface): ReactElement {
  const panelSubNavigationClasses = classNames('c-panel-sub-navigation', {
    'c-panel-sub-navigation--inline': navLayout === 'inline',
    'c-panel-sub-navigation--title-above': navLayout === 'titleAbove',
    'c-panel-sub-navigation--title-below': navLayout === 'titleBelow',
  })

  const titleRef = useRef(null)
  useEffect(() => {
    if (title === 'Traffic and travel table') return
    if (titleRef.current) titleRef.current.focus()
  }, [titleRef, title])

  return (
    <PanelSection hasBorderBottom={hasBorderBottom}>
      <div className={panelSubNavigationClasses}>
        {title && (
          <div
            className='c-panel-sub-navigation__heading'
            aria-live='polite'
            aria-hidden={title === 'Traffic and travel table'}
            ref={titleRef}
          >
            <Heading noMargin level={headingLevel}>
              {title}
            </Heading>
          </div>
        )}
        {(prevUrl || nextUrl) && (
          <div className='c-panel-sub-navigation__buttons'>
            {prevUrl && (
              <Button
                noPadding
                icon='chevron-left'
                iconAlignment='left'
                size='small'
                label={prevLabel}
                buttonStyle='text-action'
                path={prevUrl}
              />
            )}
            {nextUrl && (
              <Button
                noPadding
                icon='chevron-right'
                iconAlignment='right'
                label={nextLabel}
                size='small'
                buttonStyle='text-action'
                path={nextUrl}
              />
            )}
          </div>
        )}
      </div>
    </PanelSection>
  )
}
