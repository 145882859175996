import React from 'react'

export interface CheckableFieldTypeProps extends React.ComponentPropsWithoutRef<'input'> {
  type: 'radio' | 'checkbox'
}

export interface CheckableFieldProps extends CheckableFieldTypeProps {
  id: string
  name: string
  label: string
  describedBy?: string | undefined
  isRequired?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  value?: string
}

export default function CheckableField({
  id,
  name,
  label,
  type,
  describedBy = `${id}--help-text ${id}--validation-msg`,
  isRequired,
  isDisabled,
  value,
  ...props
}: CheckableFieldProps) {
  return (
    <div className='c-checkable-field'>
      <input className='c-checkable-field__input' id={id} type={type} name={name} {...props} />
      <label className='c-checkable-field__label' htmlFor={id}>
        {label}
      </label>
    </div>
  )
}
