import { createState, useState as hookstateUseState } from '@hookstate/core'
import { History } from 'history'
import { useEffect, useMemo } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import CloneNode from '../components/clone-inner'
import HighwayList from '../components/highway-conditions/highway-list'
import RegionList from '../components/highway-conditions/region-list'
import PanelHeader from '../components/navigation/navigation-panel/panel-header/index'
import Tabs from '../components/navigation/tabs'
import { textFromSelector } from '../components/textFromSelector'
import useMapEvents from '../hooks/use-map-events'
import useRegion from '../hooks/use-region'
import useTitle from '../hooks/use-title'
import { getMap, useGoogleLoadState } from '../stores/GoogleMapState'
import { resetFiltersForView, useFilterState } from '../stores/MapFiltersState'
import { getRegions } from '../stores/national-data/regions'
import { useActiveTabState, useTabPropsState } from '../stores/TabNavigationState'
import { defaultTabProps } from '../types/highway-conditions-types'
import { Region } from '../types/JourneyPlannerTypes'
import { TabTypes } from '../types/tab-name-types'

const onHighwayConditions = createState<boolean>(false)

export const useOnHighwayConditions = () => {
  return hookstateUseState(onHighwayConditions)
}

export const redirectToHighwayConditions = (history: History, feature?: any) => {
  const itemId = feature?.properties.id
  const itemType = feature?.properties.type
  const regions = getRegions()
  const region = regions.find((r) => {
    if (itemId && itemType) {
      const type = r.properties[itemType as keyof Region]
      const item = type ? Object.values(type)?.find((i) => i.properties.id === itemId) : null
      if (type && item) {
        return r
      } else {
        return null
      }
    } else {
      return null
    }
  })

  if (!region || !itemId) {
    history.push('/highway-conditions')
  } else {
    history.push(`/highway-conditions/${region?.properties.slug}/${itemType}/${itemId}`)
  }
}

type HighwayConditionsParams = {
  regionSlug?: string
  type?: string
  index?: string
}

export default function HighwayConditions() {
  const { regionSlug, type, index } = useParams<HighwayConditionsParams>()
  const isTabletPortraitUp = window.innerWidth > 600
  const activeTabState = useActiveTabState()
  const tabPropsState = useTabPropsState()
  const generalWarnings = 'general-warnings'
  const { [generalWarnings]: remove, ...filters } = useFilterState().get() // Removing general warnings from the filter set because we don't want to render it on the map
  const history = useHistory()
  const region = useRegion()
  const map = getMap()
  const mapLoaded = useGoogleLoadState()
  let bounds: google.maps.LatLngBounds

  const pageTitle = region
    ? `Highway conditions for ${region.properties.name}`
    : textFromSelector('#page-title__highway-conditions', 'Highway conditions')
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  useMapEvents({ filters }, (feature: any) => {
    redirectToHighwayConditions(history, feature)
  })

  const tab = useMemo(() => {
    return activeTabState.value
  }, [activeTabState.value])

  useEffect(() => {
    if (tab === TabTypes.TABLE) {
      history.push('/highway-conditions/traffic-and-travel-list-view')
    }
    if (tab === TabTypes.MAP && mapLoaded.value && map && !region) {
      map.setCenter({ lat: -41.2889, lng: 174.7772 })
      map.setZoom(5)
      map.fitBounds({
        east: 178.644431,
        north: -34.094485,
        south: -47.317516,
        west: 166.129201,
      })
    }

    // eslint-disable-next-line
  }, [tab, mapLoaded, region, map])

  useEffect(() => {
    tabPropsState.set(defaultTabProps)
    if (tab === '') {
      activeTabState.set(tabPropsState.get().tabs[0].tabType)
    }
    onHighwayConditions.set(true)

    resetFiltersForView('highway-conditions')

    return () => {
      onHighwayConditions.set(false)
    }
    // eslint-disable-next-line
  }, [])

  // Set highway bounds and fit map to bounds when a highway is selected:
  useEffect(() => {
    const regionHighways = region?.properties.highways
    const highway = index ? regionHighways?.find((h) => h.properties.id === parseInt(index)) : undefined

    if (map && highway && index) {
      const startPosition = new google.maps.LatLng({
        lng: highway.properties?.startLongitude,
        lat: highway.properties?.startLatitude,
      })
      const endPosition = new google.maps.LatLng({
        lng: highway.properties?.endLongitude,
        lat: highway.properties?.endLatitude,
      })
      // eslint-disable-next-line
      bounds = new google.maps.LatLngBounds()
      bounds.extend(startPosition)
      bounds.extend(endPosition)

      map.fitBounds(bounds, 50)

      // Set a maximum zoom level when selecting a highway (some are very small sections of road):
      const zoomLvl = map.getZoom()
      if (zoomLvl !== undefined && zoomLvl > 16) {
        map.setZoom(16)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <section role='navigation' className='c-navigation-panel'>
        <PanelHeader
          filter
          title={textFromSelector('#page-title__highway-conditions', 'Highway Conditions')}
          closeAriaLabel='Close highway conditions info modal'
          modalContent={<CloneNode selector='#modal-help__highway-conditions' />}
        />
        <Tabs
          activeTab={activeTabState.get()}
          menuName={tabPropsState.get().menuName}
          tabs={tabPropsState.get().tabs}
          hideOnDesktop
        />
        <Switch>
          {(isTabletPortraitUp || tab === TabTypes.LIST || (regionSlug && type && index)) && (
            <>
              <Route exact path='/highway-conditions' component={RegionList} />
              <Route
                path='/highway-conditions/:regionSlug([\w\-]+)/:type([\w\-]+)?/:index(\d+)?'
                component={HighwayList}
              />
            </>
          )}
        </Switch>
      </section>
    </>
  )
}
