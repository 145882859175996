import React from 'react'
import classNames from 'classnames'
import { IconType } from '../../../types/icon-name-types'
import Icon from '../icon'
import Link from '../../navigation/link'

export interface ButtonWithIcon extends ButtonProps {
  label: string
  iconAlignment?: 'right' | 'left'
  iconAriaLabel?: string
}

export interface ButtonWithoutIcon extends ButtonProps {
  label: string
  iconAriaLabel?: undefined
  iconAlignment?: 'right' | 'left'
}

export interface ButtonOnlyIcon extends ButtonProps {
  label?: undefined
  iconAriaLabel: string
  iconAlignment?: 'right' | 'left'
}

export interface ButtonProps extends React.ComponentProps<'button'> {
  isLarge?: boolean
  icon?: IconType
  isIconRotated180?: boolean
  buttonStyle?: 'primary' | 'secondary' | 'text-action' | 'icon'
  size?: 'large' | 'medium' | 'small'
  isDisabled?: boolean
  noPadding?: boolean
  isLoading?: boolean
  isStretch?: boolean
  isTab?: boolean
  path?: string
  exact?: boolean
}

export interface ButtonContentsProps {
  label?: string
  isLoading?: boolean
  icon?: IconType
  iconAlignment?: 'right' | 'left'
  isIconRotated180?: boolean
  iconAriaLabel?: string
}

function ButtonContents({
  label,
  isLoading,
  icon,
  iconAlignment = 'right',
  isIconRotated180,
  iconAriaLabel,
}: ButtonContentsProps) {
  const iconClasses = classNames('c-btn__icon', {
    'c-btn__icon--align-left': iconAlignment === 'left',
    'c-btn__icon--only-child': !label,
  })

  const labelClasses = classNames('c-btn__label', {
    'c-btn__label--loading': isLoading,
  })

  const spinnerClasses = classNames('c-btn__icon', 'c-btn__icon--spinner')

  const ariaHidden: boolean = !!label
  const ariaLabel: string = iconAriaLabel && !ariaHidden ? iconAriaLabel : ''

  return (
    <>
      {isLoading && <Icon className={spinnerClasses} icon='spinner' ariaHidden={true} />}
      <span className={labelClasses}>{label}</span>
      {icon && (
        <Icon
          icon={icon}
          ariaHidden={ariaHidden}
          ariaLabel={ariaLabel}
          className={iconClasses}
          isIconRotated180={isIconRotated180}
        />
      )}
    </>
  )
}

export default function Button({
  className = '',
  label,
  buttonStyle = 'primary',
  size = 'large',
  isLarge,
  isDisabled,
  isLoading,
  isStretch,
  noPadding,
  isTab,
  icon,
  iconAlignment = 'right',
  isIconRotated180,
  iconAriaLabel,
  path,
  exact,
  ...props
}: ButtonWithIcon | ButtonWithoutIcon | ButtonOnlyIcon) {
  const btnClasses = classNames('c-btn', {
    [`c-btn--${buttonStyle}`]: buttonStyle !== 'primary',
    [`c-btn--icon-${iconAlignment}`]: iconAlignment !== 'right',
    [`c-btn--${size}`]: !!size,
    'c-btn--stretch': isStretch,
    'c-btn--large': isLarge,
    'c-btn--no-padding': noPadding,
    'c-btn--disabled': isDisabled,
    'c-btn--loading': isLoading,
    'c-btn--isTab': isTab,
    'c-btn--icon-only': icon && !label,
    [`${className}`]: !!className,
  })

  // Returns either Link component (for routing actions) or button element (for normal actions - delete, save etc)
  return (
    <>
      {path && (
        <Link className={btnClasses} path={path} exact={exact}>
          <ButtonContents
            label={label}
            isLoading={isLoading}
            icon={icon}
            iconAlignment={iconAlignment}
            isIconRotated180={isIconRotated180}
            iconAriaLabel={iconAriaLabel}
          />
        </Link>
      )}
      {!path && (
        <button className={btnClasses} disabled={isDisabled} {...props}>
          <ButtonContents
            label={label}
            isLoading={isLoading}
            icon={icon}
            iconAlignment={iconAlignment}
            isIconRotated180={isIconRotated180}
            iconAriaLabel={iconAriaLabel}
          />
        </button>
      )}
    </>
  )
}
