import { Loader } from '@googlemaps/js-api-loader'
import { createState, StateMethods, useState } from '@hookstate/core'
import { closeFiltersIfOpen } from './MapFiltersState'

let map: google.maps.Map
const googleLoadState = createState(false)

export const useGoogleLoadState = () => {
  return useState(googleLoadState)
}

// Not wrapping in hookstate because the proxy object does weird things to the Google map object
// Instead use useGoogleLoadState to know that the map is set
export const getMap = () => {
  return map
}

export const loadAndCreateMap = (mapFullscreenState: StateMethods<boolean>) => {
  new Loader({
    // Pulled from CMS (or in index.html for react dev)
    apiKey: document.getElementById('root')?.dataset?.google || '',
    version: 'weekly',
    libraries: ['places'],
  })
    .load()
    .then(() => {
      const mapEl = document.getElementById('js-map-container')
      if (!mapEl) {
        console.error('Failed to find map element')
        return
      }
      map = new google.maps.Map(mapEl, {
        center: { lat: -41.2889, lng: 174.7772 },
        zoom: 5,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
      })
      map.fitBounds({
        east: 178.644431,
        north: -34.094485,
        south: -47.317516,
        west: 166.129201,
      })

      // Lets the user click the map to change to/from fullscreen
      // But double click still needs to zoom in
      // But click is fired at the start of a double click
      // So we wait a little after a click to see if they click again
      // If they don't then it was single click and can switch full/non
      let wasDoubleClick = false
      const checkClickAndUpdateFullscreen = () => {
        if (wasDoubleClick) return
        mapFullscreenState.set(!mapFullscreenState.get())
      }
      map.addListener('click', () => {
        wasDoubleClick = false
        window.setTimeout(checkClickAndUpdateFullscreen, 350)
      })
      map.addListener('dblclick', () => {
        wasDoubleClick = true
      })
      map.addListener('click', closeFiltersIfOpen)
      map.addListener('dragstart', closeFiltersIfOpen)

      googleLoadState.set(true)
    })
}
