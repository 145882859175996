import { useEffect } from 'react'
import { createState, useState } from '@hookstate/core'
import { loadDataFromAssetFile } from '../../services/map-data-loader'
import { MapDataFeatureCollection, DelayType, GeojsonFeature, Delay } from '../../types/JourneyPlannerTypes'

let loadStarted = false
const useLoadIfNeeded = () => {
  useEffect(() => {
    if (!delaysState.get() && !loadStarted) {
      loadData()
    }
  }, [])
}

const delaysState = createState(0)
let allDelays: GeojsonFeature<Delay>[] = []
export const useDelaysState = () => {
  useLoadIfNeeded()
  return useState(delaysState)
}
export const getDelays = () => {
  return allDelays
}

let allRoadworks: GeojsonFeature<Delay>[] = []
export const getRoadworks = () => {
  return allRoadworks
}
let allClosures: GeojsonFeature<Delay>[] = []
export const getClosures = () => {
  return allClosures
}
let allHazards: GeojsonFeature<Delay>[] = []
export const getHazards = () => {
  return allHazards
}
let allWarnings: GeojsonFeature<Delay>[] = []
export const getWarnings = () => {
  return allWarnings
}
let allGeneralWarnings: GeojsonFeature<Delay>[] = []
export const getGeneralWarnings = () => {
  return allGeneralWarnings
}

const filterCollection = (delaysData: MapDataFeatureCollection<any>, type: DelayType): GeojsonFeature<Delay>[] => {
  return delaysData.features.filter((feature) => feature.properties.type === type)
}

export async function loadData() {
  loadStarted = true
  const delaysData = await loadDataFromAssetFile('delays.json')
  loadStarted = false
  // let uniqueCoords:[]  = []
  // let parsedFeatures

  // // We have discovered that several features have exactly the same lng, lat which means the map plotting overlays on 2 points.
  // // This is an existing bug however using the cluster functionality it shows grouping off 2 when only one is visible.
  // // To mitigate this we only push through 1 of the points to be plotted onto the map.

  // // First we need to ensure the dates are ordered so as the point with the latest Enddate is first of the block as the second will be removed.
  // // If we dont have the latest Date it will remove potentially a marker that still may have works gonig on.
  // parsedFeatures  = delaysData.features.sort((a, b) => (new Date(a.properties.EndDate).getTime() > new Date(b.properties.EndDate).getTime()) ? 1 : -1)

  // // Second we filter out all duplicates.
  // parsedFeatures  = parsedFeatures.filter((delay) => {
  //   if(delay?.geometry && !uniqueCoords.includes(JSON.stringify(delay.geometry.coordinates))){
  //     uniqueCoords.push(JSON.stringify(delay.geometry.coordinates))
  //     return true
  //   }
  // return false
  // })
  // delaysData.features = parsedFeatures

  // TODO what is returned here if load fails? And how should we handle that?
  if (delaysData) {
    allRoadworks = filterCollection(delaysData, 'roadworks')
    allClosures = filterCollection(delaysData, 'closures')
    allHazards = filterCollection(delaysData, 'hazards')
    allWarnings = filterCollection(delaysData, 'warnings')
    allGeneralWarnings = filterCollection(delaysData, 'general-warnings')
    allDelays = delaysData.features
    delaysState.set(delaysData.lastUpdated)
  }
}

// we can add a useRegionalDelaysState which depends on regionsState and organises delays per region
