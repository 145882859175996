import { useHistory, useParams } from 'react-router-dom'
import { History } from 'history'
import CloneNode from '../../components/clone-inner'
import { LinkItemProps } from '../../components/common/link-list'
import PanelSection from '../../components/layout/panel-section'
import NavigationPanel from '../../components/navigation/navigation-panel/index'
import PanelHeader from '../../components/navigation/navigation-panel/panel-header'
import useMapEvents from '../../hooks/use-map-events'
import useTitle from '../../hooks/use-title'
import { getFilterItemsList } from '../../stores/MapFiltersState'
import { useJourneysByRegion, useSpecificHolidayJourneys } from '../../stores/national-data/hotspots'
import LinksOrNone from './components/links-or-none'
import Tabs from '../../components/navigation/tabs'
import { useEffect } from 'react'
import { useActiveTabState, useTabPropsState } from '../../stores/TabNavigationState'
import { defaultTabProps } from '../../types/JourneyPlannerTypes'
import { createState, useState as hookstateUseState } from '@hookstate/core'
import PanelSubNavigation from '../../components/navigation/navigation-panel/panel-sub-navigation'
import { useReturnMapToDefaultView } from '../../hooks/use-return-map-to-default-view'
import { textFromSelector } from '../../components/textFromSelector'

export const redirectToJourney = (history: History, feature?: any) => {
  const regionSlug = feature?.properties.regionSlug
  const holidaySlug = feature?.properties.holidaySlug
  const id = feature?.properties.id

  if (!regionSlug || !id) {
    history.push(`/holiday-journeys/${holidaySlug}`)
  } else {
    history.push(`/holiday-journeys/${holidaySlug}/${regionSlug}/${id}`)
  }
}

const onHolidayJourney = createState<boolean>(false)

export const useOnHolidayJourney = () => {
  return hookstateUseState(onHolidayJourney)
}

export default function HolidayTrafficJourneys() {
  const history = useHistory()

  const { holiday } = useParams<{ holiday: string }>()
  const activeTabState = useActiveTabState()
  const tabPropsState = useTabPropsState()
  const isTabletPortraitUp = window.innerWidth > 600

  const onHolidayJourneyState = useOnHolidayJourney()
  useReturnMapToDefaultView()

  const holidayData: any[] | undefined = useSpecificHolidayJourneys()

  useEffect(() => {
    tabPropsState.set(defaultTabProps)
    activeTabState.set(tabPropsState.get().tabs[0].tabType)
    onHolidayJourneyState.set(true)
    return () => {
      onHolidayJourneyState.set(false)
      activeTabState.set('')
    }
    // eslint-disable-next-line
  }, [])

  const [loaded, journeysByRegion] = useJourneysByRegion()

  let holidayTitle
  if (loaded && holidayData?.length) {
    holidayTitle = holidayData[0]
  }
  else if (loaded && !holidayData?.length) {
    holidayTitle = 'Page not found' 
  }
  else {
    holidayTitle = 'Loading'
  }

  useTitle(`${holidayTitle} | NZTA Journey Planner`)

  useMapEvents({ filters: getFilterItemsList('holiday-journeys') }, (feature: any) => {
    redirectToJourney(history, feature)
  })

  const regionList: LinkItemProps[] = Object.values(journeysByRegion).map((j) => ({
    url: `/holiday-journeys/${holiday}/${j.region.properties.slug}`,
    title: j.region.properties.name,
  }))

  return (
    <NavigationPanel>
      <PanelHeader
        modalContent={<CloneNode selector='#modal-help__holiday-traffic-journeys' />}
        title={textFromSelector('#page-title__holiday-traffic-journeys', 'Holiday journeys')}
        subTitle={holidayTitle && `${holidayTitle}`}
      />
      <Tabs
        activeTab={activeTabState.get()}
        menuName={tabPropsState.get().menuName}
        tabs={tabPropsState.get().tabs}
        hideOnDesktop
      />
      <PanelSubNavigation title={'Regions'} navLayout='titleBelow' headingLevel={3} />

      {isTabletPortraitUp || activeTabState.get() === 'listView' ? (
        <PanelSection hasVerticalPadding={false} navigation={true}>
          <LinksOrNone
            links={regionList}
            fallbackText={loaded ? 'There currently are not any holiday journeys available on this page' : 'Loading predictions'}
          />
        </PanelSection>
      ) : null}
    </NavigationPanel>
  )
}
