import { ReactElement } from 'react'
import classNames from 'classnames'

export interface TrafficSegmentProps {
  journeyTitle: string
  segmentTime: string
  segmentDistance: string
  journeyTimeRemaining: string
  trafficChanges: string
  delayStatus: 'red' | 'orange' | 'green'
}

export default function TrafficSegment({
  journeyTitle,
  segmentTime,
  segmentDistance,
  journeyTimeRemaining,
  trafficChanges,
  delayStatus,
}: TrafficSegmentProps): ReactElement {
  const trafficSegmentClasses = classNames('c-traffic-segment', {
    [`c-traffic-segment--${delayStatus}`]: !!delayStatus,
  })

  return (
    <div className={trafficSegmentClasses}>
      <div className='c-traffic-segment__title'>
        <strong>{journeyTitle}</strong>
      </div>
      <div>
        <div>
          <strong>Segment time: </strong> {segmentTime}
        </div>
        <div>
          <strong>Segment distance: </strong> {segmentDistance}
        </div>
        {journeyTimeRemaining && (
          <div>
            <strong>Journey time remaining: </strong> {journeyTimeRemaining}
          </div>
        )}
        <div>{trafficChanges ? trafficChanges : `No change to traffic over the last 2 mins`}</div>
      </div>
    </div>
  )
}
