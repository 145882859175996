import { LinkItemProps } from '../components/common/link-list'
import { HolidayJourneyFeature } from './features'
import { IconType } from './icon-name-types'
import { TabTypes, TabLabel, TabMenuName } from './tab-name-types'

export const defaultTabProps = {
  menuName: TabMenuName.JOURNEYPLANNER,
  tabs: [
    {
      tabType: TabTypes.MAP,
      label: TabLabel.MAP,
    },
    {
      tabType: TabTypes.LIST,
      label: TabLabel.LIST,
    },
  ],
}

export type JourneySearchPoint = {
  title: string
  search: string
  address: string
  parts: any
  geometry: google.maps.LatLng
}

export type JourneyResult = {
  routes: Array<any>
  locationHash: string
}

export type IndexParam = {
  index: string
  type?: string
}

export type FormattedAddress = {
  name: string
  suburb: string
  city: string
  country: string
  formatted: string
}

export type SelectedPanelItem = RelatedItem | null
export type RelatedItem =
  | GeojsonFeature<TrafficCamera>
  | GeojsonFeature<Delay>
  | GeojsonFeature<EVChargingSite>
  | GeojsonFeature<TimSign>
  | GeojsonFeature<VmsSign>
  | HolidayJourneyFeature

export type GeojsonFeature<t> = {
  type: 'Feature'
  properties: t
  geometry: any
}

export type MapDataType = TrafficCamera | Delay | EVChargingSite

export type MapFilterType = DetailPanelType | 'tts' | 'vms' | 'ev' | 'holiday-journeys' | 'traffic-layer'

export const mapFilterItemTypes = [
  'closures',
  'roadworks',
  'hazards',
  'warnings',
  'cameras',
  'tts',
  'vms',
  'ev',
  'holiday-journeys',
  'traffic-layer',
  // 'general-warnings', // This is deliberately commented out (disabled filter option), but has been put in place in case it's a desired feature in future
] as const
// TODO this is another way of defining both type AND array of strings ^
// export type MapFilterType = typeof mapFilterItemTypes[number]

export type MapFilterIcons = {
  [K in MapFilterType]: IconType
}

export type DetailPanelType = DelayType | 'cameras' | 'ev' | 'tts' | 'vms' | 'holiday-journeys'

export type DelayType = 'general-warnings' | 'roadworks' | 'closures' | 'hazards' | 'warnings'

// Order to show within the details panel
export const DetailPanelItemTypes: Array<DetailPanelType> = [
  'general-warnings',
  'closures',
  'roadworks',
  'hazards',
  'warnings',
  'cameras',
  'ev',
  'tts',
  'vms',
  'holiday-journeys',
]

export type Delay = {
  uniq: string
  id: number
  InternalId: number
  ExternalId: number
  lastUpdated: number
  ClassName?: string | null
  Content?: string | null
  EndDateNice?: null
  EventType?: string | null
  ExpectedResolution?: null
  IconClass?: string | null
  LastEdited?: string | null
  LastUpdatedAgo?: string | null
  LastUpdatedNice?: string | null
  LocationArea?: string | null
  StartDateNice?: null
  TravelTypeImage?: string | null
  type: DelayType
  UpdateDueNice?: null
  EventDescription: string | null
  EventComments: string | null
  Restrictions: string | null
  AlternativeRoute: string | null
  CompanyName: string | null
  TMPNumber: string | null
  LayoutOrDeploymentId: string | null
  Name?: string | null
  IsPlanned?: boolean
  IsPinned: number
  PinnedSortOrder: number
  EventIsland?: 'North Island' | 'South Island'
  Impact?: 'Caution' | 'Road Closed' | 'Delays' | ''
  TasJourneyId?: number // matches a Highway.id, can be 0 though (no matching highway)
  Status?: 'Active' | 'Scheduled' | 'Resolved'
  regions: number[]
  StartDate: string
  EndDate: string | null
}
export type TrafficCamera = {
  uniq: string
  id: number
  lastUpdated: number
  ClassName?: string | null
  Description?: string | null
  Direction?: string | null
  ImageUrl?: string | null
  Latitude?: string | null
  Longitude?: string | null
  Name?: string | null
  Offline?: boolean
  SortGroup?: string | null
  ThumbUrl?: string | null
  type: 'cameras'
  UnderMaintenance?: boolean
  Way?: string | null
  RegionID: number
  TasJourneyId?: number
  TasRegionId?: number
}
export type EVAccessLocation = {
  lat: number
  lon: number
}
export type EVConnector = {
  Current: string
  KwRated: number
  ConnectorType: string
  OperationStatus: string
  NextPlannedOutage: string
}
export type EVChargingSite = {
  id: number
  ExternalId: number
  LastEdited: string
  Name: string
  Operator: string
  Address: string
  Is24Hours: number
  CarParkCount: number
  HasCarparkCost: number
  MaxTimeLimit: string
  HasTouristAttraction: number
  AccessLocations: Array<EVAccessLocation>
  RegionID: number
  Region: string
  connectors: Array<EVConnector>
  OwnerName: string
  ChargingCost: string
  uniq: string
  type: 'ev'
  lastUpdated: number
}

export type FilterItem = {
  label: string
  icon: IconType
  applicable: boolean
  enabled: boolean
  retrieveFn: () => RelatedItem[]
}

export type FiltersSet = {
  closures: FilterItem
  roadworks: FilterItem
  hazards: FilterItem
  warnings: FilterItem
  cameras: FilterItem
  tts: FilterItem
  vms: FilterItem
  ev: FilterItem
  'holiday-journeys': FilterItem
  'traffic-layer': FilterItem
  'general-warnings': FilterItem
}

export type PanelItemNavInfo = {
  type: DetailPanelType
  id: number
}

export type PanelItemGroup = {
  itemType: DetailPanelType
  label: string
  icon: IconType
  items: Array<RelatedItem>
}

export type RecentJourney = LinkItemProps & {
  title: string
  query: string
  date: number
}

export type MapDataFeatureCollection<t> = {
  features: Array<GeojsonFeature<t>>
  type: 'FeatureCollection'
  lastUpdated: number
}

// NOTE these will be TAS region IDs and will need to map to Region.tasId
export type RegionReference = {
  id: number
  name: string
}

// TODO should all mappable markers have a standard base props?
// e.g. type uniq Name etc
export type TimSign = {
  updated: string
  uniq: string
  type: 'tts'
  id: number
  enabled: string
  featureType: string
  journey: any
  journeyLeg: any
  mode: string
  name: string
  page: TimSignPage
  region: RegionReference
  virtual: boolean
}

export type VmsSign = {
  uniq: string
  ClassName: string
  type: 'vms'
  id: number
  description: string
  lastUpdated: string
  currentMessage: string
}

export type ChchVmsSign = {
  uniq: string
  ClassName: string
  type: 'vms'
  id: number
  currentMessage: string
  JourneyTimes: Array<any>
  Name: string
  LastEditedNice: string
  IncidentMessage: string
}
export type TimSignPage = {
  line: Array<TimeSignLine>
  pageTime: number
}
export type TimeSignLine = {
  center: string | null
  left: string | null
  right: string | number | null
}

export type Highway = {
  id: number
  name: string
  regions: Array<RegionReference>
  delays?: GeojsonFeature<Delay>[]
  closures?: GeojsonFeature<Delay>[]
  roadworks?: GeojsonFeature<Delay>[]
  hazards?: GeojsonFeature<Delay>[]
  warnings?: GeojsonFeature<Delay>[]
  cameras?: GeojsonFeature<TrafficCamera>[]
  tts?: GeojsonFeature<TimSign>[]
  vms?: GeojsonFeature<VmsSign>[]
  startLatitude: number
  startLongitude: number
  endLatitude: number
  endLongitude: number
}

export type LinkItem = {
  label: string
  url: string
  isSpaRoute: boolean
  isExtRoute?: boolean
  icon: IconType
}

type RegionConfig = {
  enableTrafficDashboard: boolean
  enableHolidayJourneys: boolean
  hideRoadworksInMap: boolean
  hideRoadClosuresInMap: boolean
  hideWarningsInMap: boolean
  hideHazardsInMap: boolean
  hideEventsInMap: boolean
  hideNewsInMap: boolean
  hideHolidaysInMap: boolean
}

export type RegionBannerConfig = {
  bannerEventID: number
  useManualBannerEvent: boolean
  bannerEventImpact: string | null
  bannerEventTitle: string | null
  bannerEventURL: string | null
}

export type Region = {
  id: number
  name: string
  slug: string
  uniq: string
  type: string
  tasId: string
  links: LinkItem[]
  highways: GeojsonFeature<Highway>[]
  delays?: GeojsonFeature<Delay>[]
  closures?: GeojsonFeature<Delay>[]
  roadworks?: GeojsonFeature<Delay>[]
  hazards?: GeojsonFeature<Delay>[]
  warnings?: GeojsonFeature<Delay>[]
  cameras?: GeojsonFeature<TrafficCamera>[]
  tts?: GeojsonFeature<TimSign>[]
  vms?: GeojsonFeature<VmsSign>[]
  ev?: GeojsonFeature<EVChargingSite>[]
  'holiday-journeys'?: GeojsonFeature<any>[]
  'general-warnings'?: GeojsonFeature<Delay>[]
  config: RegionConfig
  bannerConfig: RegionBannerConfig
  parentRegion?: string
  parentRegionId?: number
  weatherLocation?: string
  publishedPage: boolean
}

// All zIndex values in 1 place to see relative values
// (though some are only a base and are adapted based on content)
export const MAP_Z_INDEX = {
  alternateRoutesBorderBase: 0,
  alternateRoutesBase: 1,
  directionsRendererBorder: 90,
  directionsRenderer: 100,
  markerBase: 140, // Note these 2 values get scaled up by 10000000
  markerSelectedBase: 160,
}
