import { LineString, Properties, Feature, Coord } from '@turf/helpers'
import length from '@turf/length'
import lineSlice from '@turf/line-slice'
import centriod from '@turf/centroid'
import pointOnLine from '@turf/nearest-point-on-line'
import { Point } from 'geojson'

export function getDistanceAlongLine(
  line: Feature<LineString, Properties> | LineString,
  point: Coord,
  startPoint?: Coord,
) {
  const snapped = pointOnLine(line, point)

  const coordinates = line.type === 'Feature' ? line.geometry.coordinates : line.coordinates

  const s = lineSlice(startPoint ?? coordinates[0], snapped, line)
  const distance = length(s)

  return distance
}

export function sortByDistanceAlongLine(line: Feature<LineString, Properties> | LineString, points: Coord[]) {
  const withDistances = points.map((point) => ({ point, distance: getDistanceAlongLine(line, point) }))

  withDistances.sort((a, b) => a.distance - b.distance)
  return withDistances.map((wd) => wd.point)
}

export function getClosestPointToCenter(feature: Feature<LineString, Properties>): Point['coordinates'] {
  // this finds the geographic center of any set of lines.
  let centroid = centriod(feature)

  // snaps to the closest point on the Polygon / Multiline String.
  var snapped = pointOnLine(feature, centroid)

  // only return coordinates as we want to use the original GeoJson features properties
  return snapped.geometry.coordinates
}

// Gets coordinates from string locations - example "Christchucrh, CBD", or string coordinates
export async function geocodeAddressString(address: string) {
  const geocoder = new google.maps.Geocoder()
  let res: number[] = []
  await geocoder.geocode({ address: address }, (results, status) => {
    try {
      if (results && status === 'OK') {
        res = [results[0].geometry.location.lat(), results[0].geometry.location.lng()]
      }
    } catch (error) {
      console.error('Geocoding failed:', error)
    }
  })
  return res
}

// Gets a "path" (additional coords) between two sets of coords:
// Basically this allows us to provide a start and end point,
// and then get an route to plot on the map between the two - the "path"
export async function getPathFromCoords(a: any[], b: any[]) {
  const directionsService = new google.maps.DirectionsService()
  let res

  if (a.length && b.length) {
    const req: google.maps.DirectionsRequest = {
      origin: { lat: a[0], lng: a[1] },
      destination: { lat: b[0], lng: b[1] },
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: window.google.maps.UnitSystem.METRIC,
      provideRouteAlternatives: false,
      region: 'NZ',
    }

    const processDirectionResults = (
      results: google.maps.DirectionsResult | null,
      status: google.maps.DirectionsStatus,
    ) => {
      try {
        if (results && status === 'OK') {
          res = results?.routes[0].overview_path
          return res
        }
      } catch (error) {
        console.error('Directions failed:', error)
      }
    }

    await directionsService.route(req, processDirectionResults)

    return res
  }
}
