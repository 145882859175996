import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import {
  useJourneyResultState,
  usePlaceStateA,
  usePlaceStateB,
  useSelectedRouteIndexState,
} from '../../../stores/journey-planner/JourneySearchState'
import { useActiveTabState } from '../../../stores/TabNavigationState'
import { TabTypes } from '../../../types/tab-name-types'
import useTitle from '../../../hooks/use-title'
import Icon from '../../common/icon'
interface RouteCardProps {
  heading: string
  routeLink: string
  currentTime: string
  comparativeTime: string
  detailsPanelLink: string
  saveRouteLink: string
  isSelected: boolean
}

export const RouteResult = ({
  heading,
  routeLink,
  currentTime,
  comparativeTime,
  detailsPanelLink,
  saveRouteLink,
  isSelected,
}: RouteCardProps) => {
  const itemClasses = classNames('c-route-result', {
    'c-route-result--selected': isSelected,
  })
  const activeTabState = useActiveTabState()

  const journeyResult = useJourneyResultState().get()
  const selectedRouteId = useSelectedRouteIndexState().get()
  const placeA = usePlaceStateA().get()
  const placeB = usePlaceStateB().get()
  const [loading, setLoading] = useState(false)
  const pageTitle = heading ? `${heading} - Journey planner` : 'Journey planner'
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setLoading(true)
    const route = journeyResult && journeyResult.routes[selectedRouteId]
    const CSRF: HTMLMetaElement | null = document.querySelector('meta[name="CSRF"]')
    const route_info: any = {
      CSRF: CSRF?.content,
      name: `${placeA?.title} to ${placeB?.title}`,
      geometry: JSON.stringify(route.properties.simpleGeometry),
      waypoints: JSON.stringify([
        {
          address: placeA?.address,
          geometry: placeA?.geometry,
          parts: placeA?.parts,
          placeId: placeA?.search,
        },
        {
          address: placeB?.address,
          geometry: placeB?.geometry,
          parts: placeB?.parts,
          placeId: placeB?.search,
        },
      ]),
      mode: 'DRIVE',
      overviewPolyline: route.properties.overviewPolyline,
    }
    var form_data = new FormData()

    for (var key in route_info) {
      form_data.append(key, route_info[key])
    }

    const host = document.getElementById('root')?.dataset?.host || ''
    fetch(host + '/my-journeys/saveroute', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: form_data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          window.location.assign(`${data.url}?new=1`)
        } else {
          alert(data.message)
          window.location.assign(`/Security/login?BackURL=${window.location.href}`)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.error('Failed to load related data', err)
      })
  }

  return (
    <li className={itemClasses}>
      <div className='c-route-result__heading'>
        {isSelected ? (
          <div className='c-route-result__name'>{heading}</div>
        ) : (
          <Link className='c-route-result__name' to={routeLink} replace={true}>
            {heading}
          </Link>
        )}
        <span className='c-route-result__cur-duration'>{currentTime}</span>
      </div>
      {comparativeTime && <div className='c-route-result__comparative-time'>{comparativeTime}</div>}
      {isSelected && (
        <div className='c-route-result__actions'>
          {/* Also set mobile tab state to list view (which is also done on page load via the JourneyRouteDetails component)  */}
          <Link
            className='c-route-result__action'
            to={detailsPanelLink}
            replace={true}
            onClick={() => {
              activeTabState.get() !== TabTypes.LIST && activeTabState.set(TabTypes.LIST)
            }}
          >
            Details
          </Link>
          <button className='c-route-result__action' onClick={handleSubmit}>
            Save route
            {loading && <Icon icon='spinner' className='c-btn__icon' ariaHidden />}
          </button>
        </div>
      )}
    </li>
  )
}
