import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getMap, useGoogleLoadState } from '../stores/GoogleMapState'
import { useRegionsState, getRegions } from '../stores/national-data/regions'

type UseRegionSettings = {
  panOnLoad?: boolean
}

export default function useRegion(props: UseRegionSettings | null = null) {
  const { panOnLoad } = props ?? {}
  const { regionSlug } = useParams<{ regionSlug: string }>()
  useRegionsState()
  const mapLoaded = useGoogleLoadState()
  const map = getMap()
  const regions = getRegions()
  const region = regions.find((r) => r.properties.slug === regionSlug)
  let bounds: google.maps.LatLngBounds

  useEffect(() => {
    if (region?.properties?.slug && region?.geometry?.type === 'Polygon' && panOnLoad && mapLoaded && map) {
      // eslint-disable-next-line
      bounds = new google.maps.LatLngBounds()

      const coords = region?.geometry.coordinates
      coords.forEach((c: any[]) => {
        c.forEach((latlng) => {
          const point = new google.maps.LatLng({
            lng: latlng[0],
            lat: latlng[1],
          })

          bounds.extend(point)
        })
      })

      map.fitBounds(bounds, 50)
      map.setZoom(8)
    }
  }, [region?.properties?.slug, mapLoaded.value])

  return region
}
