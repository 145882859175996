import React from 'react'

export interface FormFieldProps {
  helpText?: string
  children: React.ReactNode
  label: string
  isRequired?: boolean
}

// Default type - label references id of form input via for attribute
export interface LabelWithForProps extends FormFieldProps {
  htmlFor: string
  labelId?: string
}

// Alternative - attribute has id so that faux inputs etc can reference label via aria-labelledby attribute
export interface LabelWithIdProps extends FormFieldProps {
  labelId: string
  htmlFor?: string
}

export default function FormField({
  label,
  htmlFor,
  labelId,
  isRequired,
  children,
}: LabelWithForProps | LabelWithIdProps) {
  return (
    <div className='c-form-field'>
      <div className='c-form-field__labels'>
        <label className='c-form-field__label' htmlFor={htmlFor} id={labelId} aria-label={`${label} ${isRequired ? 'required fields' : ''}`}>
          {isRequired && <span className='c-form-field__required-text'>*</span>}
          {label}
        </label>
      </div>
      <div className='c-form-field__input'>{children}</div>
    </div>
  )
}
