import { ReactElement } from 'react'
import { IconType } from '../../../../types/icon-name-types'
import Icon from '../../../common/icon'

export interface TrafficMetricProps {
  label: string
  metric: string
  icon: IconType
}

export default function TrafficMetric({ label, metric, icon }: TrafficMetricProps): ReactElement {
  return (
    <div className='l-traffic-dashboard__metric'>
      <Icon ariaHidden={true} icon={icon} />
      <div className='l-traffic-dashboard__metric-label'>
        <strong>{label}</strong>
      </div>
      <div>{metric}</div>
    </div>
  )
}
