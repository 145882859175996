import { History } from 'history'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import CloneNode from '../components/clone-inner'
import LinkList from '../components/common/link-list'
import PanelSection from '../components/layout/panel-section'
import NavigationPanel from '../components/navigation/navigation-panel'
import PanelHeader from '../components/navigation/navigation-panel/panel-header'
import PanelSubNavigation from '../components/navigation/navigation-panel/panel-sub-navigation'
import { textFromSelector } from '../components/textFromSelector'
import useMapEvents from '../hooks/use-map-events'
import useRegions from '../hooks/use-regions'
import { useReturnMapToDefaultView } from '../hooks/use-return-map-to-default-view'
import useTitle from '../hooks/use-title'
import { getFilterItemsList } from '../stores/MapFiltersState'
import { getChargingSites, useEvChargersState } from '../stores/national-data/ev-chargers'
import { getRegions } from '../stores/national-data/regions'

export const redirectToEvCharger = (history: History, feature?: any) => {
  const regions = getRegions()
  const region = regions.find((r) => r.properties.id === feature?.properties.RegionID)
  const regionSlug = region?.properties.slug
  const chargerId = feature?.properties.ExternalId

  if (!regionSlug || !chargerId) {
    history.push('/ev-chargers')
  } else {
    history.push(`/ev-chargers/${regionSlug}/${chargerId}`)
  }
}

export default function EvChargers() {
  const pageTitle = textFromSelector('#page-title__ev-chargers', 'EV chargers')
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  const history = useHistory()
  const { regions } = useRegions()
  const chargersLoaded = useEvChargersState()

  const chargers = useMemo(() => {
    return getChargingSites()
    // eslint-disable-next-line
  }, [chargersLoaded])

  const regionsWithChargers = regions.filter((r) => {
    return chargers.find((c) => c.properties.Region === r.properties.name)
  })

  const regionList = regionsWithChargers.map((r) => ({
    title: r.properties.name,
    url: `/ev-chargers/${r.properties.slug}`,
  }))

  useMapEvents({ filters: getFilterItemsList('ev') }, (feature: any) => {
    redirectToEvCharger(history, feature)
  })

  useReturnMapToDefaultView()

  return (
    <NavigationPanel>
      <PanelHeader noHeadingMargin modalContent={<CloneNode selector='#modal-help__ev-chargers' />} title={pageTitle} />
      <PanelSubNavigation title='Regions' navLayout='titleBelow' />
      <PanelSection hasVerticalPadding={false} navigation={true}>
        <ul className='l-conditions-list'>
          <LinkList links={regionList} />
        </ul>
      </PanelSection>
    </NavigationPanel>
  )
}
