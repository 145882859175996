import { Delay, GeojsonFeature } from '../../types/JourneyPlannerTypes'
import add from 'date-fns/add'

const updateHasStartedOrWillStartInNextDay = (update: GeojsonFeature<Delay>) => {
  const now = new Date()
  const startDate = !!update.properties.StartDate ? new Date(update.properties.StartDate) : null
  const endDate = !!update.properties.EndDate ? new Date(update.properties.EndDate) : null

  if (endDate && now >= endDate) return false
  if (startDate && startDate >= now) return true
  if (startDate && add(startDate, { days: 1 })) return true
  return false
}


export const displayMoreInfo = (update: any) => {
  const now = new Date()
  const startDate = !!update.StartDate ? new Date(update.StartDate) : null
  const endDate = !!update.EndDate ? new Date(update.EndDate) : null

  if (endDate && now >= endDate) return false
  if (startDate && startDate >= now) return true
  if (startDate && add(startDate, { days: 1 })) return true
  return false
}

const trafficUpdatesInRegion = (trafficUpdates: GeojsonFeature<Delay>[], regionId?: number) => {
  if (!regionId) return trafficUpdates
  return trafficUpdates.filter((update) => {
   return  (regionId && update.properties.regions.includes(regionId))
  })
}

const trafficUpdatesStartedOrStartingWithIn24Hours = (trafficUpdates: GeojsonFeature<Delay>[]) => {
  return trafficUpdates.filter((update) => {
    return updateHasStartedOrWillStartInNextDay(update)
  })
}

const sortedTrafficUpdates = (trafficUpdates: GeojsonFeature<Delay>[]) => {
  let sortedUpdates: GeojsonFeature<Delay>[] = []
  const pinnedUpdates: GeojsonFeature<Delay>[] = []
  const remainingUpdates: GeojsonFeature<Delay>[] = []
  trafficUpdates.forEach((update) => {
    if (update.properties.IsPinned) {
      pinnedUpdates.push(update)
    } else {
      remainingUpdates.push(update)
    }
  })
  pinnedUpdates.sort((a, b) => (a.properties.PinnedSortOrder > b.properties.PinnedSortOrder ? 1 : -1))
  remainingUpdates.sort((a, b) => (a.properties.lastUpdated < b.properties.lastUpdated ? 1 : -1))
  sortedUpdates = [...pinnedUpdates, ...remainingUpdates]
  return sortedUpdates
}

export const filteredAndSortedTrafficUpdates = (trafficUpdates: GeojsonFeature<Delay>[], regionId?: number) => {
  const updatesInRegion = trafficUpdatesInRegion(trafficUpdates, regionId)
  const timeFrameUpdates = trafficUpdatesStartedOrStartingWithIn24Hours(updatesInRegion)

  return sortedTrafficUpdates(timeFrameUpdates)
}
