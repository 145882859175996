import { ReactElement } from 'react'
import { Delay } from '../../../types/JourneyPlannerTypes'
import { InfoList } from '../../common/info-list'
import PanelSection from '../../layout/panel-section'
import PanelFooter from '../panel-footer/index'
import useRegions from '../../../hooks/use-regions'
import { displayMoreInfo } from '../../../routes/traffic-update/traffic-update-filters'
interface Props {
  item: Delay
  regionSlug: string | null
  itemLinks?: {
    prevUrl: string
    nextUrl: string
  }
}

export default function RoadWorksPanel({ item, itemLinks, regionSlug }: Props): ReactElement {
  const { regions } = useRegions()
  let baseUrl
  if (!regionSlug) {
    const region = regions.find((r) => r.properties.id === item.regions[0])
    baseUrl = `/regions/${region?.properties?.slug}/traffic-updates`
  } else {
    baseUrl = `/regions/${regionSlug}/traffic-updates`
  }

  const TMPNumber = item.TMPNumber && (
    <>
      <span>TMP</span>
      <span>{item.TMPNumber}</span>
      {item.LayoutOrDeploymentId && (
        <>
          <span>Reference</span>
          <span>{item.LayoutOrDeploymentId}</span>
        </>
      )}
    </>
  )

  const roadWorksInfoList = [
    item.Restrictions ? { label: 'Impacts', data: item.Restrictions } : {},
    { label: 'Detour route', data: item.AlternativeRoute },
    { label: 'Start', data: item.StartDateNice },
    { label: 'End', data: item.EndDateNice },
    item.ExpectedResolution ? { label: 'Expected resolution', data: item.ExpectedResolution } : {},
    { label: 'Last updated', data: item.LastUpdatedNice },
    item.CompanyName ? { label: 'Company', data: item.CompanyName } : {},
    item.TMPNumber ? { label: '', data: TMPNumber } : {},
    item.UpdateDueNice ? { label: 'Next update', data: item.UpdateDueNice } : {},
  ]

  return (
    <PanelSection>
      {item.EventDescription && <h3>{item.EventDescription}</h3>}
      {item.EventComments && <p className='c-road-works-panel__comments'>{item.EventComments}</p>}

      <InfoList items={roadWorksInfoList} />
      {displayMoreInfo(item) && (
        <a className='link-footer__action' href={`${baseUrl}/${item.id}/details`}>
          More details
        </a>
      )}

      <PanelFooter footerLinks={itemLinks} />
    </PanelSection>
  )
}
