import { useHistory } from 'react-router-dom'
import { buildPanelQueryParams, PanelItemType } from '../hooks/use-pop-out-panel'
import useQuery from '../hooks/use-query'
import { useJourneyResultState, useSelectedRouteIndexState } from '../stores/journey-planner/JourneySearchState'

const buildBaseUrlSegments = ({ resultIndex }: { resultIndex: number | null }) => {
  let segments = ['journey-planner']

  // If there is a route result available, add that to the segments
  if (resultIndex != null) {
    segments.push('commute')
    segments.push(String(resultIndex))
    segments.push('details')
  }

  return segments
}

type LocationConstructionProps = Partial<{
  a: string
  b: string
  selectedPanelItemType: PanelItemType
  selectedPanelItemId: number
}>

function buildLocation(
  query: URLSearchParams,
  resultIndex: number | null,
  { selectedPanelItemId, selectedPanelItemType, a, b }: LocationConstructionProps,
) {
  let newQuery = new URLSearchParams(query)
  const segments = buildBaseUrlSegments({ resultIndex })

  if (selectedPanelItemId && selectedPanelItemType) {
    newQuery = buildPanelQueryParams(newQuery, {
      type: 'item-details',
      subType: selectedPanelItemType,
      detailId: selectedPanelItemId,
    })
  }

  // Update a & b in query params
  // Remove if not passed to this function
  Object.entries({ a, b }).forEach(([k, v]) => {
    if (v !== undefined) {
      if (v !== null) {
        newQuery.set(k, v)
      } else {
        newQuery.delete(k)
      }
    }
  })

  const location = { pathname: `/${segments.join('/')}`, search: newQuery.toString() }

  return location
}

export function useJourneyPlannerUrlSetter() {
  const history = useHistory()
  const [query] = useQuery()
  const journeyResultState = useJourneyResultState()

  // TODO: Use match for this instead of a state value
  const selectedRouteIndexState = useSelectedRouteIndexState()

  return (bits: any, replace = false) => {
    const resultIndex = !!journeyResultState.get() ? selectedRouteIndexState.get() : null
    const url = history.createHref(buildLocation(query, resultIndex, bits))
    const currentUrl = history.createHref({
      pathname: history.location.pathname,
      search: history.location.search,
      hash: history.location.hash,
    })
    if (url !== currentUrl) {
      replace ? history.replace(url) : history.push(url)
    }
  }
}

export function useJourneyPlannerUrlCreator() {
  const history = useHistory()
  const [query] = useQuery()
  const journeyResultState = useJourneyResultState()

  // TODO: Use match for this instead of a state value
  const selectedRouteIndexState = useSelectedRouteIndexState()
  return (bits: any) => {
    const resultIndex = !!journeyResultState.get() ? selectedRouteIndexState.get() : null
    const location = buildLocation(query, resultIndex, bits)
    return history.createHref(location)
  }
}
