// DO NOT EDIT. This file is generated from npm run generate-icons
import aboutPath from '../../assets/icons/svgs/about.svg'
import areawarningPath from '../../assets/icons/svgs/area-warning.svg'
import areawarningsPath from '../../assets/icons/svgs/area-warnings.svg'
import arrowPath from '../../assets/icons/svgs/arrow.svg'
import binPath from '../../assets/icons/svgs/bin.svg'
import calendarPath from '../../assets/icons/svgs/calendar.svg'
import camerasPath from '../../assets/icons/svgs/cameras.svg'
import carPath from '../../assets/icons/svgs/car.svg'
import caretdownPath from '../../assets/icons/svgs/caret-down.svg'
import caretleftPath from '../../assets/icons/svgs/caret-left.svg'
import caretrightPath from '../../assets/icons/svgs/caret-right.svg'
import caretupPath from '../../assets/icons/svgs/caret-up.svg'
import checkcirclesolidPath from '../../assets/icons/svgs/check-circle-solid.svg'
import checkcirclePath from '../../assets/icons/svgs/check-circle.svg'
import chevrondownPath from '../../assets/icons/svgs/chevron-down.svg'
import chevronleftPath from '../../assets/icons/svgs/chevron-left.svg'
import chevronrightPath from '../../assets/icons/svgs/chevron-right.svg'
import chevronupPath from '../../assets/icons/svgs/chevron-up.svg'
import closePath from '../../assets/icons/svgs/close.svg'
import contactPath from '../../assets/icons/svgs/contact.svg'
import crosscirclesolidPath from '../../assets/icons/svgs/cross-circle-solid.svg'
import crosscirclePath from '../../assets/icons/svgs/cross-circle.svg'
import dashboardPath from '../../assets/icons/svgs/dashboard.svg'
import disclaimerPath from '../../assets/icons/svgs/disclaimer.svg'
import editPath from '../../assets/icons/svgs/edit.svg'
import evchargingPath from '../../assets/icons/svgs/ev-charging.svg'
import exitPath from '../../assets/icons/svgs/exit.svg'
import externallinkPath from '../../assets/icons/svgs/external-link.svg'
import facebookfooterPath from '../../assets/icons/svgs/facebook-footer.svg'
import facebookoutlinePath from '../../assets/icons/svgs/facebook-outline.svg'
import facebookPath from '../../assets/icons/svgs/facebook.svg'
import feedPath from '../../assets/icons/svgs/feed.svg'
import filterPath from '../../assets/icons/svgs/filter.svg'
import holidayjourneysPath from '../../assets/icons/svgs/holiday-journeys.svg'
import infoPath from '../../assets/icons/svgs/info.svg'
import linkedinfooterPath from '../../assets/icons/svgs/linkedin-footer.svg'
import linkedinPath from '../../assets/icons/svgs/linkedin.svg'
import mapalertsPath from '../../assets/icons/svgs/map-alerts.svg'
import markerPath from '../../assets/icons/svgs/marker.svg'
import menuPath from '../../assets/icons/svgs/menu.svg'
import messagesignsPath from '../../assets/icons/svgs/message-signs.svg'
import morePath from '../../assets/icons/svgs/more.svg'
import notificationsPath from '../../assets/icons/svgs/notifications.svg'
import plusPath from '../../assets/icons/svgs/plus.svg'
import privacyPath from '../../assets/icons/svgs/privacy.svg'
import publictransportPath from '../../assets/icons/svgs/public-transport.svg'
import roadclosurePath from '../../assets/icons/svgs/road-closure.svg'
import roadclosuresPath from '../../assets/icons/svgs/road-closures.svg'
import roadhazardPath from '../../assets/icons/svgs/road-hazard.svg'
import roadhazardsPath from '../../assets/icons/svgs/road-hazards.svg'
import roadworksoutlinePath from '../../assets/icons/svgs/road-works-outline.svg'
import roadworksPath from '../../assets/icons/svgs/road-works.svg'
import schedulePath from '../../assets/icons/svgs/schedule.svg'
import searchPath from '../../assets/icons/svgs/search.svg'
import settingsethernetPath from '../../assets/icons/svgs/settings-ethernet.svg'
import speedPath from '../../assets/icons/svgs/speed.svg'
import spinnerPath from '../../assets/icons/svgs/spinner.svg'
import swaphorizPath from '../../assets/icons/svgs/swap-horiz.svg'
import switcherooPath from '../../assets/icons/svgs/switcheroo.svg'
import tollPath from '../../assets/icons/svgs/toll.svg'
import tollsPath from '../../assets/icons/svgs/tolls.svg'
import trafficlayerPath from '../../assets/icons/svgs/traffic-layer.svg'
import traffictimesignsPath from '../../assets/icons/svgs/traffic-time-signs.svg'
import twitterfooterPath from '../../assets/icons/svgs/twitter-footer.svg'
import twitteroutlinePath from '../../assets/icons/svgs/twitter-outline.svg'
import twitterPath from '../../assets/icons/svgs/twitter.svg'
import updatePath from '../../assets/icons/svgs/update.svg'
import userPath from '../../assets/icons/svgs/user.svg'
import warningsolidPath from '../../assets/icons/svgs/warning-solid.svg'
import warningPath from '../../assets/icons/svgs/warning.svg'
import youtubefooterPath from '../../assets/icons/svgs/youtube-footer.svg'
import youtubePath from '../../assets/icons/svgs/youtube.svg'

export type IconType = 'about' | 'area-warning' | 'area-warnings' | 'arrow' | 'bin' | 'calendar' | 'cameras' | 'car' | 'caret-down' | 'caret-left' | 'caret-right' | 'caret-up' | 'check-circle-solid' | 'check-circle' | 'chevron-down' | 'chevron-left' | 'chevron-right' | 'chevron-up' | 'close' | 'contact' | 'cross-circle-solid' | 'cross-circle' | 'dashboard' | 'disclaimer' | 'edit' | 'ev-charging' | 'exit' | 'external-link' | 'facebook-footer' | 'facebook-outline' | 'facebook' | 'feed' | 'filter' | 'holiday-journeys' | 'info' | 'linkedin-footer' | 'linkedin' | 'map-alerts' | 'marker' | 'menu' | 'message-signs' | 'more' | 'notifications' | 'plus' | 'privacy' | 'public-transport' | 'road-closure' | 'road-closures' | 'road-hazard' | 'road-hazards' | 'road-works-outline' | 'road-works' | 'schedule' | 'search' | 'settings-ethernet' | 'speed' | 'spinner' | 'swap-horiz' | 'switcheroo' | 'toll' | 'tolls' | 'traffic-layer' | 'traffic-time-signs' | 'twitter-footer' | 'twitter-outline' | 'twitter' | 'update' | 'user' | 'warning-solid' | 'warning' | 'youtube-footer' | 'youtube'

export const iconTypes: IconType[] = ['about', 'area-warning', 'area-warnings', 'arrow', 'bin', 'calendar', 'cameras', 'car', 'caret-down', 'caret-left', 'caret-right', 'caret-up', 'check-circle-solid', 'check-circle', 'chevron-down', 'chevron-left', 'chevron-right', 'chevron-up', 'close', 'contact', 'cross-circle-solid', 'cross-circle', 'dashboard', 'disclaimer', 'edit', 'ev-charging', 'exit', 'external-link', 'facebook-footer', 'facebook-outline', 'facebook', 'feed', 'filter', 'holiday-journeys', 'info', 'linkedin-footer', 'linkedin', 'map-alerts', 'marker', 'menu', 'message-signs', 'more', 'notifications', 'plus', 'privacy', 'public-transport', 'road-closure', 'road-closures', 'road-hazard', 'road-hazards', 'road-works-outline', 'road-works', 'schedule', 'search', 'settings-ethernet', 'speed', 'spinner', 'swap-horiz', 'switcheroo', 'toll', 'tolls', 'traffic-layer', 'traffic-time-signs', 'twitter-footer', 'twitter-outline', 'twitter', 'update', 'user', 'warning-solid', 'warning', 'youtube-footer', 'youtube']

export const iconPaths = {
  'about': aboutPath,
  'area-warning': areawarningPath,
  'area-warnings': areawarningsPath,
  'arrow': arrowPath,
  'bin': binPath,
  'calendar': calendarPath,
  'cameras': camerasPath,
  'car': carPath,
  'caret-down': caretdownPath,
  'caret-left': caretleftPath,
  'caret-right': caretrightPath,
  'caret-up': caretupPath,
  'check-circle-solid': checkcirclesolidPath,
  'check-circle': checkcirclePath,
  'chevron-down': chevrondownPath,
  'chevron-left': chevronleftPath,
  'chevron-right': chevronrightPath,
  'chevron-up': chevronupPath,
  'close': closePath,
  'contact': contactPath,
  'cross-circle-solid': crosscirclesolidPath,
  'cross-circle': crosscirclePath,
  'dashboard': dashboardPath,
  'disclaimer': disclaimerPath,
  'edit': editPath,
  'ev-charging': evchargingPath,
  'exit': exitPath,
  'external-link': externallinkPath,
  'facebook-footer': facebookfooterPath,
  'facebook-outline': facebookoutlinePath,
  'facebook': facebookPath,
  'feed': feedPath,
  'filter': filterPath,
  'holiday-journeys': holidayjourneysPath,
  'info': infoPath,
  'linkedin-footer': linkedinfooterPath,
  'linkedin': linkedinPath,
  'map-alerts': mapalertsPath,
  'marker': markerPath,
  'menu': menuPath,
  'message-signs': messagesignsPath,
  'more': morePath,
  'notifications': notificationsPath,
  'plus': plusPath,
  'privacy': privacyPath,
  'public-transport': publictransportPath,
  'road-closure': roadclosurePath,
  'road-closures': roadclosuresPath,
  'road-hazard': roadhazardPath,
  'road-hazards': roadhazardsPath,
  'road-works-outline': roadworksoutlinePath,
  'road-works': roadworksPath,
  'schedule': schedulePath,
  'search': searchPath,
  'settings-ethernet': settingsethernetPath,
  'speed': speedPath,
  'spinner': spinnerPath,
  'swap-horiz': swaphorizPath,
  'switcheroo': switcherooPath,
  'toll': tollPath,
  'tolls': tollsPath,
  'traffic-layer': trafficlayerPath,
  'traffic-time-signs': traffictimesignsPath,
  'twitter-footer': twitterfooterPath,
  'twitter-outline': twitteroutlinePath,
  'twitter': twitterPath,
  'update': updatePath,
  'user': userPath,
  'warning-solid': warningsolidPath,
  'warning': warningPath,
  'youtube-footer': youtubefooterPath,
  'youtube': youtubePath,
}
