import { AnimatePresence } from 'framer-motion'
import { useMemo, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import CloneNode from '../../../components/clone-inner'
import LinkList from '../../../components/common/link-list'
import PopOutPanelContent from '../../../components/common/pop-out-panel/content'
import TrafficCamerasPanel from '../../../components/detail-panels/traffic-cameras-panel'
import NavigationPanel from '../../../components/navigation/navigation-panel/index'
import PanelHeader from '../../../components/navigation/navigation-panel/panel-header'
import PanelSubNavigation from '../../../components/navigation/navigation-panel/panel-sub-navigation'
import PanelSection from '../../../components/layout/panel-section'
import { Portal } from '../../../components/portal'
import { textFromSelector } from '../../../components/textFromSelector'
import useBoundedList from '../../../hooks/use-bounded-list'
import useMapEvents from '../../../hooks/use-map-events'
import useRegion from '../../../hooks/use-region'
import useTitle from '../../../hooks/use-title'
import { usePanToItem } from '../../../services/map/MarkerCollection'
import { getFilterItemsList } from '../../../stores/MapFiltersState'
import { getCameras } from '../../../stores/national-data/cameras'

export default function TrafficCameras() {
  // Pull in contextual information
  const history = useHistory()
  const region = useRegion({ panOnLoad: true })
  const { cameraId } = useParams<{ regionSlug: string; cameraId?: string }>()

  // Set page title
  const pageTitle = region ? `Traffic cameras for ${region?.properties.name}` : 'Traffic cameras'
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  // Get all cameras
  const cameras = useMemo(() => {
    return getCameras()
  }, [])

  // Filter cameras to those available for this region and generate links
  const links = useMemo(() => {
    return (
      cameras
        .filter(
          (c) =>
            c.properties.TasRegionId ===
            ((region && parseInt(region.properties.tasId)) || parseInt(region?.properties?.parentRegionId)),
        )
        // sort by latitude
        .sort((c1, c2) => c2.geometry.coordinates[1] - c1.geometry.coordinates[1])
        .map((c) => ({ url: `${c.properties.id}`, title: c.properties.Name ?? 'Traffic camera' }))
    )
  }, [region, cameras])

  const [camera, nextCamId, prevCamId] = useBoundedList(
    cameras
      .filter(
        (c) =>
          c.properties.TasRegionId ===
          ((region && parseInt(region.properties.tasId)) || parseInt(region?.properties?.parentRegionId)),
      )
      .sort((c1, c2) => c2.geometry.coordinates[1] - c1.geometry.coordinates[1])
      .map((c) => c.properties),
    'id',
    cameraId,
  )

  // If there is a camera selected, pan to that
  usePanToItem({ type: camera?.type, id: camera?.id, focus: true })

  // Add cameras to the map
  useMapEvents({ filters: getFilterItemsList('cameras') })

  const baseUrl = `/traffic-cameras/${region?.properties?.slug}/`
  const refTitle = useRef(null)
  useEffect(() => {
    if (camera) refTitle.current.focus()
  }, [camera])
  return (
    <NavigationPanel>
      <PanelHeader
        noHeadingMargin
        modalContent={<CloneNode selector='#modal-help__traffic-cameras' />}
        title={textFromSelector('#page-title__traffic-cameras', 'Traffic cameras')}
      />
      <PanelSubNavigation title='Regions' navLayout='titleBelow' />
      <PanelSubNavigation
        title={`Traffic cameras for ${region?.properties.name}`}
        navLayout='titleBelow'
        prevLabel='Regions'
        prevUrl='/traffic-cameras/'
        headingLevel={3}
      />
      <PanelSection hasVerticalPadding={false} navigation={true}>
        <ul className='l-conditions-list'>
          <LinkList type='nav' links={links} urlPrefix={baseUrl} />
        </ul>
      </PanelSection>
      <Portal>
        <AnimatePresence exitBeforeEnter={true}>
          {camera && (
            <PopOutPanelContent
              key='selected-camera'
              ref={refTitle}
              title={camera.Name ?? ''}
              closeAction={() => {
                history.push(baseUrl)
                const activeLink = document.getElementsByClassName('c-link-list__link--active')[0]
                activeLink.focus()
              }}
            >
              <TrafficCamerasPanel
                item={camera}
                itemLinks={{ nextUrl: `${baseUrl}${nextCamId}`, prevUrl: `${baseUrl}${prevCamId}` }}
              />
            </PopOutPanelContent>
          )}
        </AnimatePresence>
      </Portal>
    </NavigationPanel>
  )
}
