export const initialiseBanners = () => {
  const banners = document.getElementsByClassName('c-banner')

  /**
  End if no banners
  */
  if (!banners.length) return

  /**
  Dismissed banners
  */
  let dismissedBanners: Array<string> = []
  try {
    const dismissedBannersString = window.localStorage.getItem('dismissed-banners')
    dismissedBanners = typeof dismissedBannersString === 'string' ? dismissedBannersString.split(',') : []
  } catch (e) {}
  const currentDismissedBanners: Array<string> = []
  const bannersToRemove: Array<HTMLElement> = []
  for (let i = 0; i < banners.length; i++) {
    const banner = banners[i]
    banner.classList.remove('hidden')
    if (!(banner instanceof HTMLElement)) continue
    const dismissKey: string =
      banner.dataset && typeof banner.dataset.dismissKey === 'string' ? banner.dataset.dismissKey : ''
    if (!dismissKey.length) continue
    if (dismissedBanners.indexOf(dismissKey) > -1) {
      bannersToRemove.push(banner)
      currentDismissedBanners.push(dismissKey)
    } else {
      const dismissButton = banner.getElementsByTagName('button')[0]
      if (dismissButton) {
        dismissButton.addEventListener('click', () => {
          currentDismissedBanners.push(dismissKey)
          try {
            window.localStorage.setItem('dismissed-banners', currentDismissedBanners.join(','))
          } catch (e) {}
          banner.remove()
        })
      }
    }
  }
  bannersToRemove.forEach((banner) => banner.remove())
  if (dismissedBanners.join(',') !== currentDismissedBanners.join(',')) {
    try {
      window.localStorage.setItem('dismissed-banners', currentDismissedBanners.join(','))
    } catch (e) {}
  }
}
