import { useEffect } from 'react'
import { createState, useState } from '@hookstate/core'
import { GeojsonFeature, VmsSign } from '../../types/JourneyPlannerTypes'
import { loadDataFromAssetFile } from '../../services/map-data-loader'

const vmsState = createState(0)
let allMessageSigns: GeojsonFeature<VmsSign>[] = []
let highwayMessageSigns: GeojsonFeature<VmsSign>[] = []
let loadStarted = false

const useLoadIfNeeded = () => {
  useEffect(() => {
    // TODO initiate a refresh every 5 minutes?
    if (!vmsState.get() && !loadStarted) {
      loadData()
    }
  }, [])
}

export const useMessageSignState = () => {
  useLoadIfNeeded()
  return useState(vmsState)
}

export const getAllMessageSigns = () => {
  return allMessageSigns
}

export const getHighwayMessageSigns = () => {
  return highwayMessageSigns
}
async function loadData() {
  loadStarted = true
  const vmsData = await loadDataFromAssetFile('vms.json')
  loadStarted = false
  if (vmsData) {
    allMessageSigns = vmsData.features ?? []
    highwayMessageSigns = vmsData.features.filter((feature) => feature.properties.ClassName !== 'ChchVms') ?? []
    vmsState.set(1)
  }
  return []
}
