import React from 'react'
import ReactModal from 'react-modal'
import { JsxElement } from 'typescript'
import Button from '../button'
import Heading from '../heading'

// See https://reactcommunity.org/react-modal/accessibility/
if(document.getElementById('root')){
  ReactModal.setAppElement('#root')
}


export interface ModalProps {
  isOpen: boolean
  onRequestClose: VoidFunction
  contentLabel: string // Indicates how the modal should be announced to screen readers
}

export interface BoilerplateModalProps extends ModalProps {
  heading?: string
  bodyText?: string
  buttons?: JsxElement
  children?: never
  closeModal?: () => void
  closeAriaLabel: string
}

export interface CustomModalProps extends ModalProps {
  children: React.ReactNode
  heading?: string
  bodyText?: never
  buttons?: never
  closeModal?: () => void
  closeAriaLabel: string
}

export default function Modal({
  isOpen,
  onRequestClose,
  closeModal,
  contentLabel,
  heading,
  children,
  closeAriaLabel,
}: BoilerplateModalProps | CustomModalProps) {
  return (
    <ReactModal
      closeTimeoutMS={400}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className='c-modal'
      overlayClassName='c-modal__overlay'
      portalClassName='c-modal__portal'
    >
      <Button
        className='c-modal__close-button'
        icon='cross-circle-solid'
        buttonStyle='icon'
        iconAriaLabel={closeAriaLabel}
        onClick={closeModal}
      />
      {heading && (
        <Heading level={3} className='c-modal__heading'>
          {heading}
        </Heading>
      )}
      {children}
    </ReactModal>
  )
}
