import { useEffect } from 'react'
import useActiveMapItem from '../hooks/use-active-map-item'
import useMapEvents from '../hooks/use-map-events'
import useTitle from '../hooks/use-title'
import { loadRecentJourneys, useRecentJourneysState } from '../stores/journey-planner/RecentJourneysState'
import { useFilterState } from '../stores/MapFiltersState'
import Heading from './common/heading'
import LinkList from './common/link-list'
import PanelSection from './layout/panel-section'

export default function RecentJourneysList() {
  const recentJourneys = useRecentJourneysState().get()
  const filters = useFilterState().get()
  const [activeItem] = useActiveMapItem()
  useMapEvents({ filters })

  // Load recent journeys on component mount
  // No need to clear selectedItem as this happens due to the route change anyway
  useEffect(() => {
    loadRecentJourneys()
  }, [])

  // When an item on the Journey Planner national map is clicked (not an item on
  // a route / when a journey has been searched)
  // This opens the panel with the selected item
  // We also update the page title to match'
  let name = false
  if (activeItem) {
    if (typeof activeItem.getProperty === 'function') {
      name = activeItem?.getProperty('Name')
    } else {
      name = activeItem?.properties.Name
    }
  }
  const pageTitle = name ? `${name} - Journey planner` : 'Journey planner'
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  if (!recentJourneys.length) {
    return null
  }
  return (
    <PanelSection>
      <Heading level={2}>Your recent journeys</Heading>
      <LinkList url={`/journey-planner/commute/0?`} links={recentJourneys} />
    </PanelSection>
  )
}
