import usePopOutPanel from '../hooks/use-pop-out-panel'
import { useJourneyResultState, useSelectedRouteIndexState } from '../stores/journey-planner/JourneySearchState'
import { useFilterState } from '../stores/MapFiltersState'
import { DetailPanelItemTypes, FiltersSet, PanelItemGroup, RelatedItem } from '../types/JourneyPlannerTypes'
import { useJourneyPlannerUrlCreator } from './JourneyPlannerUrlHelper'

export function useJourneyRelatedItems(): RelatedItem[] {
  const journeyResultState = useJourneyResultState()
  const selectedRouteId = useSelectedRouteIndexState().get()
  const route = journeyResultState.get()?.routes?.[selectedRouteId]
  if (!route?.properties?.hasLoadedRelated) {
    return []
  }

  return route.properties.related
}

// Splits related data into groups as (and if) they should show in the sidebar
export function useGroupedPanelItems() {
  const related = useJourneyRelatedItems()
  const layerFilters: FiltersSet = useFilterState().get()
  const delayGroupsMap: any = {}
  related.forEach((item: RelatedItem) => {
    if (item?.properties?.type && layerFilters[item.properties.type]?.enabled) {
      if (!delayGroupsMap[item.properties.type]) {
        delayGroupsMap[item.properties.type] = []
      }
      delayGroupsMap[item.properties.type].push(item)
    }
  })
  const sortedGroups: Array<PanelItemGroup> = []
  DetailPanelItemTypes.forEach((itemType) => {
    if (delayGroupsMap[itemType]?.length) {
      sortedGroups.push({
        itemType,
        label: layerFilters[itemType].label,
        icon: layerFilters[itemType].icon,
        items: delayGroupsMap[itemType],
      })
    }
  })
  return sortedGroups
}

// Puts all of the panel items into a single list
// Then works out what the next and previous items in the list are
// And generates the URL for browsing to those items
// Returns null if there's no current selection or it somehow isn't found
export function useNextPrevItemUrls() {
  const related = useJourneyRelatedItems()
  const createUrl = useJourneyPlannerUrlCreator()
  const layerFilters: FiltersSet = useFilterState().get()
  const [state] = usePopOutPanel()

  if (state.detailId == null) {
    return null
  }

  const availableItems = related.filter((r) => layerFilters[r.properties.type]?.enabled)

  let selectedItemIndex = availableItems.findIndex((r) => r.properties.id === state.detailId)

  if (selectedItemIndex === -1) {
    return null
  }

  const prevIndex = selectedItemIndex === 0 ? availableItems.length - 1 : selectedItemIndex - 1
  const nextIndex = selectedItemIndex === availableItems.length - 1 ? 0 : selectedItemIndex + 1
  const prevUrl = createUrl({
    selectedPanelItemId: availableItems[prevIndex].properties.id,
    selectedPanelItemType: availableItems[prevIndex].properties.type,
  })
  const nextUrl = createUrl({
    selectedPanelItemId: availableItems[nextIndex].properties.id,
    selectedPanelItemType: availableItems[nextIndex].properties.type,
  })
  return {
    prevUrl,
    nextUrl,
  }
}
