// This file references the google maps api included
/// <reference types="google.maps" />

import { useEffect } from 'react'
import { useMarkerCollection } from '../services/map/MarkerCollection'
import { useGoogleLoadState } from '../stores/GoogleMapState'
import { useCamerasState } from '../stores/national-data/cameras'
import { useDelaysState } from '../stores/national-data/delays'
import { useEvChargersState } from '../stores/national-data/ev-chargers'
import { useJourneysState } from '../stores/national-data/hotspots'
import { MapDataFeature } from '../types/features'
import { useMessageSignState } from '../stores/national-data/message-signs'
import { useTrafficTimeState } from '../stores/national-data/traffic-time-signs'
import { FiltersSet, RelatedItem } from '../types/JourneyPlannerTypes'

interface UseMapEventsProps {
  filters: FiltersSet
}

export default function useMapEvents({ filters }: UseMapEventsProps, callback?: (arg: MapDataFeature) => void) {
  const camerasUpdated = useCamerasState()
  const chargersUpdated = useEvChargersState()
  const delaysUpdated = useDelaysState()
  const googleLoadState = useGoogleLoadState()
  const holidayJourneysUpdated = useJourneysState()
  const [, updateMarkerCollection] = useMarkerCollection(callback)
  const trafficTimeSignsUpdated = useTrafficTimeState()
  const messageSignsUpdated = useMessageSignState()
  const filtersEncode = JSON.stringify(filters)
  useEffect(() => {
    const features = Object.values(filters).reduce((features: RelatedItem[], filter) => {
      // Get all features for all filters that are currently enabled
      let filterFeatures: RelatedItem[] = []
      if (filter.enabled) {
        filterFeatures = filter.retrieveFn()
      }
      return [...features, ...filterFeatures]
    }, [])

    updateMarkerCollection(features)
    // eslint-disable-next-line
  }, [
    filtersEncode,
    camerasUpdated.value,
    delaysUpdated.value,
    googleLoadState.value,
    holidayJourneysUpdated.value,
    chargersUpdated.value,
    trafficTimeSignsUpdated.value,
    messageSignsUpdated.value
  ])
}
