import { useParams } from 'react-router-dom'
import NavigationPanel from '../../../navigation/navigation-panel'
import PanelSubNavigation from '../../../navigation/navigation-panel/panel-sub-navigation'
import { textFromSelector } from '../../../textFromSelector'

export default function TrafficUpdateDetailPanel() {
  const { regionSlug, updateId } = useParams<{ regionSlug: string; updateId?: string }>()

  const baseUrl = `/regions/${regionSlug}/traffic-updates`

  return (
    <NavigationPanel>
      <PanelSubNavigation
        prevLabel={`${textFromSelector('#page-title__traffic-updates', 'Latest traffic updates')}`}
        prevUrl={`${baseUrl}/${updateId}`}
        hasBorderBottom={false}
      />
    </NavigationPanel>
  )
}
