import { AnimatePresence } from 'framer-motion'
import { useMemo, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import CloneNode from '../../../components/clone-inner'
import LinkList from '../../../components/common/link-list'
import PopOutPanelContent from '../../../components/common/pop-out-panel/content'
import EVChargingSitePanel from '../../../components/detail-panels/ev-charging-site-panel'
import NavigationPanel from '../../../components/navigation/navigation-panel'
import PanelHeader from '../../../components/navigation/navigation-panel/panel-header'
import PanelSubNavigation from '../../../components/navigation/navigation-panel/panel-sub-navigation'
import { Portal } from '../../../components/portal'
import { textFromSelector } from '../../../components/textFromSelector'
import useBoundedList from '../../../hooks/use-bounded-list'
import useMapEvents from '../../../hooks/use-map-events'
import useRegion from '../../../hooks/use-region'
import useTitle from '../../../hooks/use-title'
import { redirectToEvCharger } from '../../../pages/EvChargers'
import { usePanToItem } from '../../../services/map/MarkerCollection'
import { getFilterItemsList } from '../../../stores/MapFiltersState'
import { getChargingSites, useEvChargersState } from '../../../stores/national-data/ev-chargers'

export default function EVChargersByRegion() {
  const history = useHistory()
  const region = useRegion({ panOnLoad: true })
  const { chargerId } = useParams<{ regionSlug: string; chargerId?: string }>()
  const chargersLoaded = useEvChargersState()

  const pageTitle = textFromSelector('#page-title__ev-chargers', 'EV chargers')
  useTitle(`EV chargers for ${region?.properties.name} | NZTA Journey Planner`)

  const chargers = useMemo(() => {
    return getChargingSites()
    // eslint-disable-next-line
  }, [chargersLoaded])

  const links = useMemo(() => {
    return chargers
      .filter((c) => {
        return c.properties.Region === region?.properties.name
      })
      .sort((c1, c2) => c2.geometry.coordinates[1] - c1.geometry.coordinates[1])
      .map((c) => ({
        url: `/${c.properties.id}`,
        title: `${c.properties.Name} - ${c.properties.Address}` ?? 'EV charger',
      }))
    // eslint-disable-next-line
  }, [region, chargers])

  const [charger, nextId, prevId] = useBoundedList(
    chargers
      .filter((c) => c.properties.Region === region?.properties.name)
      .sort((c1, c2) => c2.geometry.coordinates[1] - c1.geometry.coordinates[1])
      .map((c) => c.properties),
    'id',
    chargerId,
  )

  usePanToItem({ type: charger?.type, id: charger?.id, focus: true })

  useMapEvents({ filters: getFilterItemsList('ev') }, (feature: any) => {
    redirectToEvCharger(history, feature)
  })

  const baseUrl = `/ev-chargers/${region?.properties.slug}`
  const refTitle = useRef(null)
  useEffect(() => {
    if (charger) refTitle.current.focus()
  }, [charger])
  return (
    <NavigationPanel>
      <PanelHeader noHeadingMargin modalContent={<CloneNode selector='#modal-help__ev-chargers' />} title={pageTitle} />
      <PanelSubNavigation title='Regions' navLayout='titleBelow' />
      <PanelSubNavigation
        title={`EV Chargers for ${region?.properties.name}`}
        navLayout='titleBelow'
        prevLabel='Regions'
        prevUrl='/ev-chargers'
        headingLevel={3}
      />
      <div>
        <ul className='l-conditions-list'>
          <LinkList type='nav' links={links} urlPrefix={baseUrl} />
        </ul>
      </div>
      {/* Popout panel below: */}
      <Portal>
        <AnimatePresence exitBeforeEnter={true}>
          {charger && (
            <PopOutPanelContent
              key='selected-charger'
              title={`EV charging station: ${charger.Name} - ${charger.Address}`}
              ref={refTitle}
              closeAction={() => {
                history.push(baseUrl)
                const activeLink = document.getElementsByClassName('c-link-list__link--active')[0]
                activeLink.focus()
              }}
            >
              <EVChargingSitePanel
                item={charger}
                itemLinks={{ nextUrl: `${baseUrl}/${nextId}`, prevUrl: `${baseUrl}/${prevId}` }}
              />
            </PopOutPanelContent>
          )}
        </AnimatePresence>
      </Portal>
    </NavigationPanel>
  )
}
