import { useHistory } from 'react-router'
import useQuery from './use-query'

type DetailProps = {
  type: PanelType
  subType?: PanelItemType | PanelDashboardType
  detailId?: number
}

const PANEL_TYPE_KEY = 'panel-type'
const PANEL_SUBTYPE_KEY = 'panel-subtype'
const PANEL_ID_KEY = 'panel-item-id'

// TODO: Centralise these types / compare with existing types for items
export type PanelType = 'filters' | 'item-details' | 'dashboard'
export type PanelItemType =
  | 'cameras'
  | 'ev'
  | 'closures'
  | 'roadworks'
  | 'hazards'
  | 'warnings'
  | 'tts'
  | 'vms'
  | 'general-warnings'
export type PanelDashboardType = 'traffic' | 'holiday-journeys'

export const validateDetailPanelOptions = ({ type, subType, detailId }: DetailProps) => {
  if (!type) throw Error('Must pass a type to the details panel')
  if (type !== 'filters' && !subType) throw Error('Must pass a subType to the details panel')
  if (type === 'item-details' && !detailId) throw Error('Must pass a detailId to the details panel')
}

export const buildPanelQueryParams = (query: URLSearchParams, props: DetailProps) => {
  validateDetailPanelOptions(props)

  const { type, subType, detailId } = props
  const newQuery = new URLSearchParams(query)

  newQuery.set(PANEL_TYPE_KEY, type)

  if (subType) {
    newQuery.set(PANEL_SUBTYPE_KEY, subType)
  } else {
    newQuery.delete(PANEL_SUBTYPE_KEY)
  }

  if (detailId) {
    newQuery.set(PANEL_ID_KEY, String(detailId))
  } else {
    newQuery.delete(PANEL_ID_KEY)
  }

  return newQuery
}

export default function usePopOutPanel(): [Partial<DetailProps>, (props: DetailProps) => void, () => void] {
  const history = useHistory()
  const [query] = useQuery()

  const state = {
    type: query.get(PANEL_TYPE_KEY) as PanelType,
    subType: query.get(PANEL_SUBTYPE_KEY) as PanelItemType | PanelDashboardType,
    detailId: Number(query.get(PANEL_ID_KEY)) ?? undefined,
  }

  const updateHistory = (query: URLSearchParams) => {
    history.push({ ...history?.location, search: query.toString() })
  }

  const open = (props: DetailProps) => {
    let newQuery = buildPanelQueryParams(query, props)
    updateHistory(newQuery)
  }

  const close = () => {
    // If nothing in url, just return and don't update state
    if (![state.type, state.subType, state.detailId].some((statePart) => statePart != null)) return

    query.delete(PANEL_TYPE_KEY)
    query.delete(PANEL_SUBTYPE_KEY)
    query.delete(PANEL_ID_KEY)

    updateHistory(query)
  }

  return [state, open, close]
}
