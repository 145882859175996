import { AnimatePresence } from 'framer-motion'
import { useEffect, useMemo, useRef } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { redirectToJourney, useOnHolidayJourney } from '.'
import CloneNode from '../../components/clone-inner'
import { LinkItemProps } from '../../components/common/link-list'
import PopOutPanelContent from '../../components/common/pop-out-panel/content'
import HolidayJourneysPanel from '../../components/detail-panels/holiday-journeys-panel'
import NavigationPanel from '../../components/navigation/navigation-panel/index'
import PanelHeader from '../../components/navigation/navigation-panel/panel-header'
import PanelSubNavigation from '../../components/navigation/navigation-panel/panel-sub-navigation'
import Tabs from '../../components/navigation/tabs'
import { Portal } from '../../components/portal'
import { textFromSelector } from '../../components/textFromSelector'
import useBoundedList from '../../hooks/use-bounded-list'
import useMapEvents from '../../hooks/use-map-events'
import useRegion from '../../hooks/use-region'
import { usePanToItem } from '../../services/map/MarkerCollection'
import { getFilterItemsList } from '../../stores/MapFiltersState'
import { useJourneysByRegion ,useSpecificHolidayJourneys } from '../../stores/national-data/hotspots'
import { useActiveTabState, useTabPropsState } from '../../stores/TabNavigationState'
import { defaultTabProps } from '../../types/JourneyPlannerTypes'
import LinksOrNone from './components/links-or-none'

export default function RegionHolidayTrafficJourneys() {
  const history = useHistory()
  const region = useRegion({ panOnLoad: true })
  const { holiday, hotspotId } = useParams<{ holiday: string; hotspotId?: string }>()
  const baseUrl = `/holiday-journeys/${holiday}/${region?.properties?.slug}/`

  const [loaded, byRegion] = useJourneysByRegion()
  const activeTabState = useActiveTabState()
  const tabPropsState = useTabPropsState()
  const isTabletPortraitUp = window.innerWidth > 600

  const onHolidayJourneyState = useOnHolidayJourney()
  useSpecificHolidayJourneys()

  useEffect(() => {
    tabPropsState.set(defaultTabProps)
    activeTabState.set(tabPropsState.get().tabs[0].tabType)
    onHolidayJourneyState.set(true)
    return () => {
      onHolidayJourneyState.set(false)
      activeTabState.set('')
    }
    // eslint-disable-next-line
  }, [])

  const journeys = useMemo(() => {
    if (region?.properties?.name && loaded) {
      return byRegion[region.properties.name]?.journeys.filter((p) => holiday === p.properties.holidaySlug).map((j) => j.properties)
    }
    return []
  }, [region?.properties?.name, byRegion, loaded, holiday])

  const [journey] = useBoundedList(journeys, 'id', hotspotId)

  usePanToItem({ type: journey?.type, id: journey?.id, focus: true })

  useMapEvents({ filters: getFilterItemsList('holiday-journeys') }, (feature: any) => {
    redirectToJourney(history, feature)
  })

  const JourneyLinks: LinkItemProps[] =
    journeys?.map((p) => ({
      title: p.Title,
      url: `/holiday-journeys/${holiday}/${region?.properties.slug}/${p.id}`,
    })) ?? []

  const refTitle = useRef(null)
  useEffect(() => {
    if(journey)
      refTitle.current.focus()
  }, [journey])

  if (!journeys.length && loaded) {
    return <Redirect to={`/holiday-journeys/${holiday}`} />
  }

  return (
    <NavigationPanel>
      <PanelHeader
        modalContent={<CloneNode selector='#modal-help__holiday-traffic-journeys' />}
        title={textFromSelector('#page-title__holiday-traffic-journeys', 'Holiday journeys')}
      />
      <Tabs
        activeTab={activeTabState.get()}
        menuName={tabPropsState.get().menuName}
        tabs={tabPropsState.get().tabs}
        hideOnDesktop
      />
      <PanelSubNavigation
        title={`Holiday traffic journeys for ${region?.properties.name}`}
        navLayout='titleBelow'
        prevLabel='Regions'
        prevUrl={`/holiday-journeys/${holiday}`}
      />

      {isTabletPortraitUp || activeTabState.get() === 'listView' ? (
        <ul className='l-conditions-list'>
          <LinksOrNone
            type='nav'
            links={JourneyLinks}
            fallbackText={loaded ? 'There are not currently any holiday predictions available' : 'Loading'}
          />
        </ul>
      ) : null}

      <Portal>
        <AnimatePresence exitBeforeEnter={true}>
          {journey && (
            <PopOutPanelContent
              title={journey.Title}
              ref={refTitle}
              closeAction={() => {
                history.push(baseUrl)
                const activeLink = document.getElementsByClassName('c-link-list__link--active')[0]
                activeLink.focus()
              }}
            >
              <HolidayJourneysPanel item={journey} />
            </PopOutPanelContent>
          )}
        </AnimatePresence>
      </Portal>
    </NavigationPanel>
  )
}
