import classNames from 'classnames'
import { FormEvent, KeyboardEvent, MouseEvent, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import usePopOutPanel from '../../../../hooks/use-pop-out-panel'
import {
  useActiveSearchState,
  useInvalidSearchState,
  usePlaceStateA,
  usePlaceStateB,
} from '../../../../stores/journey-planner/JourneySearchState'
import Button from '../../../common/button'
import Icon from '../../../common/icon'
import InlineNotification from '../../../common/inline-notification'
import PanelSection from '../../../layout/panel-section'
import PanelHeader from '../../../navigation/navigation-panel/panel-header'
import RouteInput from '../route-input'
import CloneNode from '../../../clone-inner'

export default function RouteJourney({ isCompactOnMobileView = true }) {
  const history = useHistory()
  const hasInvalidSearch = useInvalidSearchState()
  const hasActiveSearch = useActiveSearchState()
  const placeAState = usePlaceStateA()
  const placeBState = usePlaceStateB()
  const [state, open, close] = usePopOutPanel()
  const apiKey = document.getElementById('root')?.dataset?.google || ''

  const inputElARef = useRef<HTMLInputElement>(null)
  const inputElBRef = useRef<HTMLInputElement>(null)

  const inputElARefCurrent = inputElARef.current
  const inputElBRefCurrent = inputElBRef.current

  if (inputElARefCurrent && inputElARefCurrent instanceof HTMLInputElement) {
    const placeA = placeAState.get()
    inputElARefCurrent.value = (placeA && placeA.address) || ''
  }

  if (inputElBRefCurrent && inputElBRefCurrent instanceof HTMLInputElement) {
    const placeB = placeBState.get()
    inputElBRefCurrent.value = (placeB && placeB.address) || ''
  }

  // Handle hitting enter or pressing the submit button
  // If search is invalid show a message
  const handleSubmit = (e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const query = new URLSearchParams(window.location.search)
    if (query.get('a') && query.get('b')) {
      hasInvalidSearch.set(false)
      hasActiveSearch.set(true)
    } else {
      hasInvalidSearch.set(true)
      hasActiveSearch.set(false)
    }
  }

  const autocompleteOptions = {
    types: [],
    componentRestrictions: { country: 'nz' },
  }

  const onPlaceSelected = (position: 'a' | 'b', place: google.maps.places.PlaceResult) => {
    if (place.geometry && place.address_components) {
      updateSingleValue(position, place.place_id || '')
    }
  }

  const updateSingleValue = (position: 'a' | 'b', value: string) => {
    const query = new URLSearchParams(window.location.search)
    const a = position === 'a' ? value : query.get('a') || ''
    const b = position === 'b' ? value : query.get('b') || ''
    updateNavigation(a, b)
  }

  const updateNavigation = (a: string, b: string) => {
    const path = a && b ? '/commute/0' : ''
    if (a && b) {
      hasInvalidSearch.set(false)
      hasActiveSearch.set(true)
    } else {
      hasInvalidSearch.set(true)
      hasActiveSearch.set(false)
    }
    history.replace(`/journey-planner${path}?a=${encodeURIComponent(a)}&b=${encodeURIComponent(b)}`)
  }

  // Check if input has value (display clear field button if so)
  const hasValue = (fieldIdentifier: string) => {
    if (fieldIdentifier === 'a') {
      return !!inputElARef.current?.value
    } else {
      return !!inputElBRef.current?.value
    }
  }

  const switchFromAndTo = () => {
    const query = new URLSearchParams(window.location.search)
    const a = query.get('a') || ''
    const b = query.get('b') || ''
    updateNavigation(b, a)
  }

  const handleKeyDown = (e: KeyboardEvent<Element>, input: string) => {
    if (e.key === 'Enter' && input === 'a') {
      e.preventDefault()
      document.getElementById('journey-planner-form-b')?.focus()
    }
    if (e.key === 'Enter' && input === 'b') {
      e.preventDefault()
    }
  }

  const formInputClasses = classNames('c-journey-form', {
    'c-journey-form--compact-mobile-view': isCompactOnMobileView,
  })

  return (
    <PanelSection hasShadowBottom>
      <form method='GET' onSubmit={handleSubmit} autoComplete='off' className={formInputClasses}>
        <PanelHeader
          title='Enter your journey'
          closeAriaLabel='Close enter your journey info modal'
          borderBottom={false}
          noPadding
          styleClass="journeys-heading"
          modalContent={<CloneNode selector='#modal-help__journey-planner' />}
        />
        <span>*Required fields</span> 
        <div className='c-journey-form__fields'>
          <div className='c-journey-form__inputs'>
            <RouteInput
              labelId='travellingFrom'
              label='Travelling from'
              required
              invalidSearch={(hasInvalidSearch.value && hasValue('a') === false  && hasValue('b') === true)}
              inputId='journey-planner-form-a'
              ref={inputElARef}
              apiKey={apiKey}
              options={autocompleteOptions}
              handleClear={(e) => updateSingleValue('a', '')}
              onPlaceSelected={(place: google.maps.places.PlaceResult) => {
                onPlaceSelected('a', place)
              }}
              hasValue={hasValue('a')}
              markerLabel='a'
              onKeyDown={(e) => handleKeyDown(e, 'a')}
              placeholder={'Enter a location'}
            />
            {hasInvalidSearch.get() && hasValue('a') === false  && hasValue('b') === true  && (
              <div className='c-form-field__required-text'>Required field</div>
            )}
            <RouteInput
              labelId='travellingTo'
              label='Travelling to'
              required
              invalidSearch={(hasInvalidSearch.value && hasValue('b') === false && hasValue('a') === true)}
              inputId='journey-planner-form-b'
              ref={inputElBRef}
              apiKey={apiKey}
              options={autocompleteOptions}
              handleClear={(e) => updateSingleValue('b', '')}
              onPlaceSelected={(place: google.maps.places.PlaceResult) => {
                onPlaceSelected('b', place)
              }}
              hasValue={hasValue('b')}
              markerLabel='b'
              onKeyDown={(e) => handleKeyDown(e, 'b')}
              placeholder={'Enter a destination'}
            />
            {hasInvalidSearch.get() && hasValue('b') === false && hasValue('a') === true && (
              <div className='c-form-field__required-text'>Required field</div>
            )}
          </div>
          <div className='c-journey-form__switch'>
            <button className='c-journey-form__switch-btn' type='button' onClick={() => switchFromAndTo()}>
              <Icon icon='switcheroo' ariaLabel='Switch route direction' />
            </button>
          </div>
        </div>
        {hasInvalidSearch.get() && (
          <InlineNotification messageType='warning'>Please enter both a from and to location</InlineNotification>
        )}

        {/* TODO leave time & map layers */}
        <div className='c-journey-form__filters'>
          <Button
            icon='filter'
            iconAlignment='left'
            buttonStyle='text-action'
            size='small'
            noPadding
            className='c-btn-filter'
            onClick={() => {
              if (state.type === 'filters') {
                close()
              } else {
                open({ type: 'filters' })
              }
            }}
            label='Filter'
            iconAriaLabel='Filter journey data options'
          />
        </div>
      </form>
    </PanelSection>
  )
}
