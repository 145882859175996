import { useHistory, useParams } from 'react-router-dom'
import { getDelays, useDelaysState } from '../../stores/national-data/delays'
import { Delay, GeojsonFeature } from '../../types/JourneyPlannerTypes'
import MapArea from '../../components/MapArea'
import Icon from '../../components/common/icon'
import { MapFilterIconsMap } from '../../stores/MapFiltersState'
import { useTrafficUpdateDetailMap, useTrafficUpdateDetailState } from '../../services/map/TrafficUpdateDetailMap'
import useTitle from '../../hooks/use-title'

const getUpdateById = (delays?: GeojsonFeature<Delay>[], id?: string) => {
  return delays?.find((delay) => delay.properties.id.toString() === id)
}

export default function TrafficUpdateDetail() {
  const history = useHistory()
  const trafficUpdateState = useTrafficUpdateDetailState()

  const { regionSlug, updateId } = useParams<{ regionSlug: string; updateId?: string }>()
  const delaysUpdated = useDelaysState().get()
  const delays = getDelays()
  const update = getUpdateById(delays, updateId)

  update && trafficUpdateState.set(update)
  const properties = update?.properties

  const pageTitle = properties?.LocationArea ? `Traffic update - ${properties?.LocationArea}` : `Traffic update`
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  useTrafficUpdateDetailMap()

  if (delaysUpdated && !update) {
    history.push(`/regions/${regionSlug}/traffic-updates/${updateId}`)
    return
  }

  const addContent = (content: string) => {
    let element = document.getElementById('traffic-update-content')
    while (element?.firstChild) {
      element.removeChild(element.firstChild)
    }
    // ensure image shortcodes used in default SS WYSIWYG are found and replaced.
    // only affects image shortcodes on traffic update details
    content = content.replaceAll('[image', '<img').replaceAll('"]', '"/>')
    element?.insertAdjacentHTML('afterbegin', content)
  }

  return (
    <div className='l-content__wrapper'>
      <div className='c-detail-page'>
        <span id='nzta-main-content'> </span>
        <h1 className='delay__main-heading'>{properties?.LocationArea}</h1>
        <div>
          {update?.geometry && (
            <div
              id='routes-map'
              className='c-detail__map delay-map mobile-collapsed-map map-wrapper panel margin-btm-30'
            >
              <span
                className='hide-panel-btn  ms-tablet-down js-toggle-panel'
                data-target='#routes-map'
                style={{ display: 'none' }}
              >
                <i className='i i-caret-left i--white' aria-hidden='true'></i> View Details
              </span>
              {<MapArea />}
            </div>
          )}

          <div className='delay-sub-heading'>
            <Icon
              icon={MapFilterIconsMap[properties?.type ?? 'warnings']}
              ariaHidden={true}
              className='delay-sub-heading__icon'
            />
            <div className='delay-sub-heading__text-wrapper'>
              <h2 className='delay-sub-heading__title'>
                {properties?.Impact && (
                  <span className='delay-sub-heading__title--highlight'>{properties?.Impact}: </span>
                )}
                {properties?.EventType === 'Other' && properties.EventDescription
                  ? properties.EventDescription
                  : properties?.EventType}
              </h2>
              <h3 className='delay-sub-heading__text'>{properties?.LocationArea}</h3>
            </div>
          </div>

          {properties?.EventDescription && <h3>{properties?.EventDescription}</h3>}

          <div className='detail-event-comments'>{properties?.EventComments}</div>

          <dl className='detail-list'>
            {properties?.Restrictions && properties?.Restrictions !== 'None' && (
              <>
                <dt>Impacts</dt>
                <dd>{properties?.Restrictions}</dd>
              </>
            )}
            {properties?.AlternativeRoute && (
              <>
                <dt>Detour route</dt>
                <dd>{properties?.AlternativeRoute}</dd>
              </>
            )}
            {properties?.StartDateNice && (
              <>
                <dt>Start</dt>
                <dd>{properties?.StartDateNice}</dd>
              </>
            )}
            {properties?.EndDateNice && (
              <>
                <dt>End</dt>
                <dd>{properties?.EndDateNice}</dd>
              </>
            )}
            {properties?.EventTimes && (
              <>
                <dt>Times</dt>
                <dd>{properties?.EventTimes}</dd>
              </>
            )}
            {properties?.ExpectedResolution && (
              <>
                <dt>Expected resolution</dt>
                <dd>{properties?.ExpectedResolution}</dd>
              </>
            )}
            {properties?.LastUpdatedNice && (
              <>
                <dt>Last updated</dt>
                <dd>{properties.LastUpdatedNice}</dd>
              </>
            )}
            {properties?.UpdateDueNice && (
              <>
                <dt>Next update</dt>
                <dd>{properties?.UpdateDueNice}</dd>
              </>
            )}
            {properties?.CompanyName && (
              <>
                <dt>Company</dt>
                <dd>{properties?.CompanyName}</dd>
              </>
            )}
            {properties?.EmergencyContact && (
              <>
                <dt>24 Hour Contact</dt>
                <dd>
                  <a href={`tel:${properties?.EmergencyContact}`}>{properties?.EmergencyContact}</a>
                </dd>
              </>
            )}
            {properties?.CreatedBy === 'TMPforCHCH' ? (
              <>
                <dt>TMP number</dt>
                <dd>{`#${properties?.ExternalId}`}</dd>
              </>
            ) : (
              properties?.TMPNumber && (
                <>
                  <dt>TMP number</dt>
                  <dd>{properties?.TMPNumber}</dd>
                </>
              )
            )}
            {properties?.LayoutOrDeploymentId && (
              <>
                <dt>Reference</dt>
                <dd>{properties?.LayoutOrDeploymentId}</dd>
              </>
            )}
          </dl>

          {properties?.Content && (
            <div id='traffic-update-content' className='margin-btm-30'>
              {addContent(properties?.Content)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
