import { TabTypes, TabLabel, TabMenuName } from './tab-name-types'

export const defaultTabProps = {
  menuName: TabMenuName.HIGHWAYCONDITIONS,
  tabs: [
    {
      tabType: TabTypes.MAP,
      label: TabLabel.MAP,
    },
    {
      tabType: TabTypes.LIST,
      label: TabLabel.LIST,
    },
    {
      tabType: TabTypes.TABLE,
      label: TabLabel.TABLE,
    },
  ],
}
