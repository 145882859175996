import { useReturnMapToDefaultView } from '../../../hooks/use-return-map-to-default-view'
import { useFilterState } from '../../../stores/MapFiltersState'
import { getRegions, useRegionsState } from '../../../stores/national-data/regions'
import { GeojsonFeature, DetailPanelItemTypes, Region } from '../../../types/JourneyPlannerTypes'
import { ConditionItemProps } from '../../common/condition-card'
import { ConditionCard } from '../../common/condition-card/index'
import PanelSection from '../../layout/panel-section'
import PanelSubNavigation from '../../navigation/navigation-panel/panel-sub-navigation'

export default function RegionList() {
  const mapFilters = useFilterState().get()
  useRegionsState()
  const regions = getRegions().filter((r) => r.properties.highways.length > 0)
  const isTabletPortraitUp = window.innerWidth > 600

  useReturnMapToDefaultView()

  const getSummaryForRegion = (region: GeojsonFeature<Region>): ConditionItemProps[] => {
    return DetailPanelItemTypes.filter((type) => {
      // Respect enabled map filters
      const { enabled, applicable } = mapFilters[type]
      return applicable && enabled && region.properties[type]?.length
    }).map((type) => {
      const { label, icon } = mapFilters[type]
      return {
        icon,
        iconLabel: label,
        label: (region.properties[type]?.length || 0) + ' ' + label,
      }
    })
  }

  if (!regions) {
    return <PanelSection>Loading</PanelSection>
  }

  return (
    <>
      <PanelSubNavigation
        title='Regions'
        navLayout='inline'
        nextUrl={isTabletPortraitUp ? '/highway-conditions/traffic-and-travel-list-view/' : undefined}
        nextLabel={isTabletPortraitUp ? 'Table View' : undefined}
      />
      <PanelSection hasVerticalPadding={false} navigation={true}>
        <ul className='l-conditions-list'>
          {regions.map((region) => {
            const regionSummary = getSummaryForRegion(region)

            return (
              <ConditionCard
                key={region.properties.id}
                link={`/highway-conditions/${region.properties.slug}`}
                heading={region.properties.name}
                conditionItems={regionSummary.length ? regionSummary : undefined}
                expandable={false}
              />
            )
          })}
        </ul>
      </PanelSection>
    </>
  )
}
