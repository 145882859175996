import usePopOutPanel from './use-pop-out-panel'
import { useDataLayer, useMapMarkers } from '../services/map/MarkerCollection'
import { useMemo } from 'react'
import { MapDataFeature } from '../types/features'


export default function useActiveMapItem(
  callback?: (arg: MapDataFeature) => void,
): [
  google.maps.Data.Feature | google.maps.Marker | null | any,
  (feature: google.maps.Data.Feature | google.maps.Marker | any | null) => void,
] {
  const [state, open, close] = usePopOutPanel()
  const dataLayer = useDataLayer()
  const markers = useMapMarkers()

  // Pull active feature by comparing URL to the features tracked on the data layer
  let feature: google.maps.Data.Feature | google.maps.Marker | null = useMemo(() => {
    if ([state.type, state.subType, state.detailId].some((stateItem) => stateItem == null)) return null

    if (dataLayer == null) return null

    let feature: google.maps.Data.Feature | null | any = null
    const markerArray = markers ? Object.values(markers).flatMap((x) => x) : []
    markerArray.forEach((m) => {
      const properties = m.get('properties')
      if (properties.id === state.detailId && properties.type === state.subType) {
        feature = m
      }
    })
    return feature
  }, [state.type, state.subType, state.detailId, dataLayer, markers])

  const setActiveItem = (feature: google.maps.Data.Feature | google.maps.Marker | null | any) => {
    if (!feature) {
      if(state.type && state.subType && state.detailId !== 0)
        close()
    } else {
      if (callback) {
        callback(feature)
      } else {
        const type  = typeof feature.getProperty === 'function' ? feature.getProperty('type') : feature?.properties.type;
        const id  = typeof feature.getProperty === 'function' ? feature.getProperty('id') : feature?.properties.id;
        open({ type: 'item-details', subType: type, detailId: id })
      }
    }
  }

  return [feature, setActiveItem]
}
