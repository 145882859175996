import { ReactElement } from 'react'
import LinkList from '../../common/link-list'
import { LinkItemProps } from '../../common/link-list'
import { getRegions, useRegionsState } from '../../../stores/national-data/regions'
import PanelSection from '../../layout/panel-section'
import PanelHeader from '../../navigation/navigation-panel/panel-header'
import CloneNode from '../../clone-inner'
import { textFromSelector } from '../../textFromSelector'
import { useReturnMapToDefaultView } from '../../../hooks/use-return-map-to-default-view'

export default function RegionsList(): ReactElement {
  useReturnMapToDefaultView()
  useRegionsState()
  const regions = getRegions()

  const getRegionList: LinkItemProps[] = regions.map((region) => {
    return {
      title: region.properties.name,
      url: `/regions/${region.properties.slug}`,
    }
  })

  const onClick = () => {
    window.scrollTo(0, 0)
  }

  return (
    <>
      <PanelHeader
        filter
        title={textFromSelector('#page-title__region', 'Regions')}
        closeAriaLabel='Close regions info modal'
        modalContent={<CloneNode selector='#modal-help__region' />}
      />
      <PanelSection hasVerticalPadding={false} navigation={true}>
        <LinkList links={getRegionList} onClick={onClick} />
      </PanelSection>
    </>
  )
}
