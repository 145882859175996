import { useState } from '@hookstate/core'
import { useEffect } from 'react'
import { History } from 'history'

export const useIntegratedNav = (history: History<unknown>) => {
  const addedLinks = useState(false)

  // Hooks into the pre-rendered app navigation to load other SPA routes
  // using history API instead of page loads.
  useEffect(() => {
    if (!history || addedLinks.get()) return

    const menuLinks = document.getElementsByClassName('c-main-menu__link')
    for (let i = 0; i < menuLinks.length; i++) {
      menuLinks[i].classList.remove('c-main-menu__link--active')

      if (history.location.pathname.includes(menuLinks[i].getAttribute('href'))) {
        menuLinks[i].classList.add('c-main-menu__link--active')
      }
    }

    const spaLinks = document.getElementsByClassName('is-spa-route')
    for (let i = 0; i < spaLinks.length; i++) {
      spaLinks[i].addEventListener('click', (e: any) => {
        if (e.currentTarget.href) {
          e.preventDefault()
          const url = new URL(e.currentTarget.href)
          history.push(url.pathname)
          setMenuClasses(e)
        }
      })
    }
    addedLinks.set(true)
  }, [history, addedLinks])
}

const setMenuClasses = (e: any) => {
  const menuLinks = document.getElementsByClassName('c-main-menu__link')
  for (let i = 0; i < menuLinks.length; i++) {
    menuLinks[i].classList.remove('c-main-menu__link--active')
  }
  e.currentTarget.classList.add('c-main-menu__link--active')
}
