import { useMemo } from 'react'
import CloneNode from '../../components/clone-inner'
import LinkList from '../../components/common/link-list'
import PanelSection from '../../components/layout/panel-section'
import NavigationPanel from '../../components/navigation/navigation-panel/index'
import PanelHeader from '../../components/navigation/navigation-panel/panel-header'
import PanelSubNavigation from '../../components/navigation/navigation-panel/panel-sub-navigation'
import { textFromSelector } from '../../components/textFromSelector'
import useMapEvents from '../../hooks/use-map-events'
import useRegions from '../../hooks/use-regions'
import { useReturnMapToDefaultView } from '../../hooks/use-return-map-to-default-view'
import useTitle from '../../hooks/use-title'
import { getFilterItemsList } from '../../stores/MapFiltersState'
import { getCameras, useCamerasState } from '../../stores/national-data/cameras'

export default function TrafficCameras() {
  const pageTitle = textFromSelector('#page-title__traffic-cameras', 'Traffic cameras')
  useTitle(`${pageTitle} | NZTA Journey Planner`)
  const { regions } = useRegions()
  const camerasLoaded = useCamerasState()
  // Get all cameras:
  const cameras = useMemo(() => {
    return getCameras()
    // eslint-disable-next-line
  }, [camerasLoaded])

  useMapEvents({ filters: getFilterItemsList('cameras') })

  useReturnMapToDefaultView()

  // Get only regions that have traffic cameras:
  const regionsWithCameras = regions.filter((r) => {
    return cameras.find(
      (c) => c.properties.TasRegionId === (parseInt(r.properties.tasId) || parseInt(r?.properties?.parentRegionId)),
    )
  })
  const regionList = regionsWithCameras.map((r) => ({
    title: r.properties.name,
    url: `/traffic-cameras/${r.properties.slug}`,
  }))

  return (
    <NavigationPanel>
      <PanelHeader
        noHeadingMargin
        modalContent={<CloneNode selector='#modal-help__traffic-cameras' />}
        title={textFromSelector('#page-title__traffic-cameras', 'Traffic cameras')}
      />
      <PanelSubNavigation title='Regions' navLayout='titleBelow' />
      <PanelSection hasVerticalPadding={false} navigation={true}>
        <LinkList links={regionList} />
      </PanelSection>
    </NavigationPanel>
  )
}
