import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { RouteResult } from '../route-result'
import { useJourneyResultState, useSelectedRouteIndexState } from '../../../stores/journey-planner/JourneySearchState'

interface RouteListProps {
  showSelectedOnly: boolean
}

export const RouteResults = ({ showSelectedOnly }: RouteListProps) => {
  const itemClasses = classNames('c-route-results__list', {
    'c-route-results__list--mobile-only': showSelectedOnly,
  })
  const journeyResult = useJourneyResultState().get()
  const selectedRouteId = useSelectedRouteIndexState().get()
  const search = useLocation().search

  if (!journeyResult?.routes?.length || (showSelectedOnly && !journeyResult?.routes?.[selectedRouteId])) {
    return null
  }
  return (
    <ul className={itemClasses}>
      {journeyResult.routes.map((route, i) => {
        if (showSelectedOnly && selectedRouteId !== i) {
          return null
        }
        return (
          <RouteResult
            key={i}
            isSelected={selectedRouteId === i}
            heading={route.properties.name}
            comparativeTime={route.properties.comparativeTime}
            currentTime={route.properties.currentTime}
            detailsPanelLink={`/journey-planner/commute/${i}/details${search.toString()}`}
            routeLink={`/journey-planner/commute/${i}${search.toString()}`}
            saveRouteLink={`/journey-planner/commute/${i}${search.toString()}`}
          />
        )
      })}
    </ul>
  )
}
