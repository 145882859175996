import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'

export interface LinkProps extends React.ComponentPropsWithRef<'a'> {
  path: string
  exact?: boolean
  label?: string
  activeClassName?: string
  isExternal?: boolean
  customComponent?: any
  linkRef?: React.ForwardedRef<any>
}

export default function Link({
  path,
  exact = true,
  label,
  activeClassName,
  isExternal = false,
  target = undefined,
  customComponent,
  children,
  linkRef,
  ...props
}: LinkProps) {
  if (isExternal || path.startsWith('http')) {
    return (
      <a href={path} target={target} ref={linkRef} {...props}>
        {children ?? label}
      </a>
    )
  } else if (customComponent) {
    // Custom link component (for implementing router link on custom element).
    // See https://reactrouter.com/web/api/Link/component-reactcomponent for implementation details
    return (
      <RouterLink
        to={path}
        exact={exact}
        component={customComponent}
        activeClassName={activeClassName}
        ref={linkRef}
        {...props}
      />
    )
  } else {
    return (
      <RouterLink to={path} exact={exact} activeClassName={activeClassName} ref={linkRef} {...props}>
        {children ?? label}
      </RouterLink>
    )
  }
}
