import { ReactElement, useEffect } from 'react'
import Icon from '../../components/common/icon'
import WeatherWidget from '../../components/highway-conditions/weather-widget'
import PanelSection from '../../components/layout/panel-section'
import PanelHeader from '../../components/navigation/navigation-panel/panel-header'
import PanelSubNavigation from '../../components/navigation/navigation-panel/panel-sub-navigation'
import useMapEvents from '../../hooks/use-map-events'
import useQuery from '../../hooks/use-query'
import useRegion from '../../hooks/use-region'
import useTitle from '../../hooks/use-title'
import { removeFilter, useFilterState } from '../../stores/MapFiltersState'
import { LinkItem } from '../../types/JourneyPlannerTypes'
import OverviewItem from './components/overview-item'

export default function RegionPanel(): ReactElement {
  const isPhoneDown = window.innerWidth < 600
  const region = useRegion({ panOnLoad: true })
  const [params, createLocation] = useQuery()
  const filters = useFilterState().get()
  useMapEvents({ filters })
  useTitle(`${region?.properties.name} | NZTA Journey Planner`)

  const expand = params.get('expand') === '1'

  useEffect(() => {
    removeFilter('holiday-journeys', !region?.properties.config.hideHolidaysInMap)
    removeFilter('roadworks', !region?.properties.config.hideRoadworksInMap)
    removeFilter('closures', !region?.properties.config.hideRoadClosuresInMap)
    removeFilter('warnings', !region?.properties.config.hideWarningsInMap)
    removeFilter('hazards', !region?.properties.config.hideHazardsInMap)
    // eslint-disable-next-line
  }, [region])

  if (!region) {
    return <PanelSection>Loading</PanelSection>
  }

  const items: LinkItem[] = [
    {
      icon: 'cameras',
      url: `/traffic-cameras/${region.properties.slug}/`,
      label: 'Traffic cameras',
      isSpaRoute: true,
    },
    {
      icon: 'update',
      url: `/regions/${region.properties.slug}/traffic-updates`,
      label: 'Latest traffic updates',
      isSpaRoute: true,
    },
    {
      icon: 'ev-charging',
      url: `/ev-chargers/${region.properties.slug}/`,
      label: 'EV charging stations',
      isSpaRoute: true,
    },
  ]

  if (region.properties.config.enableTrafficDashboard) {
    items.push({
      icon: 'dashboard',
      url: `/regions/${region.properties.slug}/traffic-dashboard`,
      label: 'Traffic dashboard',
      isSpaRoute: true,
    })
  }

  if (region.properties.config.enableHolidayJourneys && region.properties['holiday-journeys']?.length) {
    items.push({
      icon: 'holiday-journeys',
      url: `/holiday-journeys/${region.properties['holiday-journeys'][0].properties.holidaySlug}/${region.properties.slug}`,
      label: 'Holiday journeys',
      isSpaRoute: true,
    })
  }

  let renderedItems = [...items, ...(region?.properties.links ?? [])].map((i) => <OverviewItem {...i} />)

  if (renderedItems.length > 6) {
    if (!expand) {
      renderedItems = renderedItems.slice(0, 5)
    }

    renderedItems.push(
      <OverviewItem
        icon='more'
        label={expand ? 'Show less' : 'Show more'}
        isSpaRoute
        isExtRoute
        url={createLocation({ expand: expand ? '0' : '1' })}
      />,
    )
  }

  return (
    <div className='c-navigation-panel'>
      <PanelSubNavigation prevUrl='/regions' prevLabel='Regions' />
      <PanelHeader styleClass='inline' filter title={region.properties.name} />
      <PanelSection hasBorderBottom>
        <div className='l-region-overview'>{renderedItems}</div>
      </PanelSection>
      <PanelSection>
        <a className='l-region-overview__save-notifications' href={`/my-journeys/region/new/${region.properties.slug}`}>
          <Icon icon='notifications' ariaLabel='' />
          Save region alerts
        </a>
      </PanelSection>
      {isPhoneDown && (
        <div className='l-weather-widget-wrapper'>
          <WeatherWidget days='days-3' />
        </div>
      )}
    </div>
  )
}
