import { createState, useState } from '@hookstate/core'
import { FiltersSet, MapFilterIcons, MapFilterType } from '../types/JourneyPlannerTypes'
import { getCameras } from './national-data/cameras'
import { getClosures, getGeneralWarnings, getHazards, getRoadworks, getWarnings } from './national-data/delays'
import { getChargingSites } from './national-data/ev-chargers'
import { getAllMessageSigns, getHighwayMessageSigns } from './national-data/message-signs'
import { getHolidayJourneys, getRegionalHolidayJourneys } from './national-data/hotspots'
import { getTrafficTimeSigns } from './national-data/traffic-time-signs'

const filterOpenState = createState(false)
export const useFilterOpenState = () => {
  return useState(filterOpenState)
}
export const closeFiltersIfOpen = () => {
  if (filterOpenState.get()) {
    filterOpenState.set(false)
  }
}

export const MapFilterIconsMap: MapFilterIcons = {
  closures: 'road-closures',
  roadworks: 'road-works',
  hazards: 'road-hazards',
  warnings: 'area-warnings',
  cameras: 'cameras',
  tts: 'traffic-time-signs',
  vms: 'message-signs',
  ev: 'ev-charging',
  'holiday-journeys': 'holiday-journeys',
  'traffic-layer': 'traffic-layer',
  'general-warnings': 'road-hazards',
}

// export const MapFilterLabels = {
//   closures: 'Road closures',
//   roadworks: 'Road works',
//   hazards: 'Road hazards',
//   warnings: 'Area warnings',
//   cameras: 'Traffic cameras',
//   tts: 'Traffic time signs',
//   vms: 'Message signs',
//   ev: 'EV charging locations',
//   'holiday-journeys': 'Holiday journeys',
// }

type MapMode = 'journey-planner' | 'highway-conditions' | 'regions' | 'cameras' | 'ev' | 'holiday-journeys'

export const getFilterItemsList = (mode: MapMode) => {
  const filterItemsList: FiltersSet = {
    closures: {
      label: 'Road closures',
      icon: MapFilterIconsMap['closures'],
      enabled: mode !== 'cameras' && mode !== 'ev' && mode !== 'holiday-journeys',
      applicable: true,
      retrieveFn: getClosures,
    },
    roadworks: {
      label: 'Road works',
      icon: MapFilterIconsMap['roadworks'],
      enabled: mode !== 'cameras' && mode !== 'ev' && mode !== 'holiday-journeys',
      applicable: true,
      retrieveFn: getRoadworks,
    },
    hazards: {
      label: 'Road hazards',
      icon: MapFilterIconsMap['hazards'],
      enabled: mode !== 'cameras' && mode !== 'ev' && mode !== 'holiday-journeys',
      applicable: true,
      retrieveFn: getHazards,
    },
    warnings: {
      label: 'Area warnings',
      icon: MapFilterIconsMap['warnings'],
      enabled: mode !== 'cameras' && mode !== 'ev' && mode !== 'holiday-journeys',
      applicable: true,
      retrieveFn: getWarnings,
    },
    cameras: {
      label: 'Traffic cameras',
      icon: MapFilterIconsMap['cameras'],
      enabled: mode !== 'holiday-journeys' && mode !== 'journey-planner' && mode !== 'ev',
      applicable: true,
      retrieveFn: getCameras,
    },
    tts: {
      label: 'Traffic time signs',
      icon: MapFilterIconsMap['tts'],
      enabled: mode !== 'cameras' && mode !== 'ev' && mode !== 'holiday-journeys' && mode !== 'journey-planner',
      applicable: true,
      retrieveFn: getTrafficTimeSigns,
    },
    vms: {
      label: 'Message signs',
      icon: MapFilterIconsMap['vms'],
      enabled: mode !== 'cameras' && mode !== 'ev' && mode !== 'holiday-journeys' && mode !== 'journey-planner',
      applicable: true,
      retrieveFn: mode !== 'highway-conditions' ? getAllMessageSigns : getHighwayMessageSigns,
    },
    ev: {
      label: 'EV charging locations',
      icon: MapFilterIconsMap['ev'],
      enabled: mode === 'ev',
      applicable: mode !== 'highway-conditions',
      retrieveFn: getChargingSites,
    },
    'holiday-journeys': {
      label: 'Holiday journeys',
      icon: MapFilterIconsMap['holiday-journeys'],
      enabled: mode === 'holiday-journeys' || (mode !== 'cameras' && mode !== 'ev'),
      applicable: true,
      retrieveFn: mode === 'holiday-journeys' ? getRegionalHolidayJourneys : getHolidayJourneys,
    },
    'traffic-layer': {
      label: 'Traffic layer',
      icon: MapFilterIconsMap['traffic-layer'],
      enabled: false,
      applicable: true,
      retrieveFn: () => [],
    },
    'general-warnings': {
      label: 'General warnings',
      icon: MapFilterIconsMap['general-warnings'],
      enabled: mode === 'journey-planner' || mode === 'highway-conditions',
      applicable: true,
      retrieveFn: getGeneralWarnings,
    },
  }
  return filterItemsList
}

// TODO update state when mode changes (traffic dashboard etc)
const currentModeState = createState('')
const filterState = createState(getFilterItemsList('journey-planner'))
export const useFilterState = () => {
  return useState(filterState)
}

export const resetFiltersForView = (mode: MapMode) => {
  window.scrollTo(0, 0)
  if (mode !== currentModeState.value) {
    currentModeState.set(mode)
    filterState.set(getFilterItemsList(mode))
  }
}

export const updateFilter = (id: MapFilterType, enabled: boolean) => {
  const mapFilters = filterState.get()
  if (mapFilters[id].enabled !== enabled && mapFilters[id].applicable) {
    filterState[id].merge({ enabled })
  }
}

export const removeFilter = (id: MapFilterType, applicable: boolean) => {
  const mapFilters = filterState.get()
  if (mapFilters[id].applicable !== applicable) {
    filterState[id].merge({ applicable, enabled: applicable })
  }
}
