import { createState, useState } from '@hookstate/core'
import { useEffect } from 'react'
import { loadDataFromAssetFile } from '../../services/map-data-loader'
import { GeojsonFeature, DetailPanelItemTypes, Region } from '../../types/JourneyPlannerTypes'
import { getCameras, useCamerasState } from './cameras'
import { getDelays, useDelaysState } from './delays'
import { getChargingSites, useEvChargersState } from './ev-chargers'
import { getHighways, useHighwaysState } from './highways'
import { getHolidayJourneys, useJourneysState } from './hotspots'
import { getAllMessageSigns, useMessageSignState } from './message-signs'
import { getTrafficTimeSigns, useTrafficTimeState } from './traffic-time-signs'

const regionsState = createState(0)
let allRegions: GeojsonFeature<Region>[] = []
let loadStarted = false
export const useRegionsState = () => {
  const delaysState = useDelaysState()
  const camerasState = useCamerasState()
  const highwaysState = useHighwaysState()
  const journeysState = useJourneysState()
  const messageState = useMessageSignState()
  const timeState = useTrafficTimeState()
  const evChargerState = useEvChargersState()

  useEffect(() => {
    // TODO initiate a refresh every 5 minutes?
    if (!regionsState.get() && !loadStarted) {
      loadData()
    }
  }, [])

  useEffect(() => {
    aggregateData()
  }, [
    delaysState.value,
    camerasState.value,
    highwaysState.value,
    evChargerState.value,
    timeState.value,
    messageState.value,
    journeysState.value,
  ])
  return useState(regionsState)
}
export const getRegions = () => {
  let publishedRegions = allRegions.filter((r) => {
    return r.properties.publishedPage === true
  })
  return publishedRegions
}

async function loadData() {
  loadStarted = true
  const regionsData = await loadDataFromAssetFile('regions.json')
  loadStarted = false
  if (regionsData) {
    allRegions = regionsData.features ?? []
    aggregateData()
  }
  return []
}

const aggregateData = () => {
  if (!allRegions) return
  const regionMap: any = {}
  const regionByTasId: any = {}
  allRegions.forEach((region) => {
    DetailPanelItemTypes.forEach((type) => {
      region.properties[type] = []
    })
    region.properties.highways = []
    regionMap[region.properties.id] = region
    const tasId = parseInt(region.properties.tasId, 10)
    if (!isNaN(tasId)) {
      regionByTasId[tasId] = region
    }
  })
  const delays = getDelays()
  if (delays) {
    delays.forEach((delay) => {
      delay.properties.regions.forEach((regionId) => {
        regionMap[regionId]?.properties?.delays?.push(delay)
        regionMap[regionId]?.properties?.[delay.properties.type]?.push(delay)
      })
    })
  }
  const cameras = getCameras()
  if (cameras) {
    cameras.forEach((camera) => {
      regionMap[camera.properties.RegionID]?.properties?.cameras?.push(camera)
    })
  }
  const highways = getHighways()
  if (highways) {
    highways.forEach((highway) => {
      highway.properties.regions.forEach((region) => {
        regionByTasId[region.id]?.properties?.highways?.push(highway)
      })
    })
  }

  const holidayJourneys = getHolidayJourneys()
  if (holidayJourneys) {
    holidayJourneys.forEach((hp) => {
      let region = allRegions.find((r) => {
        return r.properties.name === hp.properties.RegionTitle
      })
      if (region) regionMap[region.properties.id]?.properties?.['holiday-journeys']?.push(hp)
    })
  }

  const messageSigns = getAllMessageSigns()
  if (messageSigns) {
    messageSigns.forEach((ms) => {
      let region = allRegions.find((r) => {
        if (ms.properties.ClassName !== 'ChchVms') {
          return parseInt(r.properties.tasId) === parseInt(ms.properties?.regions[0]?.id)
        } else {
          return r.properties.id === 21
        }
      })
      if (region) regionMap[region.properties.id]?.properties?.vms?.push(ms)
    })
  }

  const evChargers = getChargingSites()
  if (evChargers) {
    evChargers.forEach((ev) => {
      regionMap[ev.properties.RegionID]?.properties?.ev?.push(ev)
    })
  }

  const trafficTimeSigns = getTrafficTimeSigns()
  if (trafficTimeSigns) {
    trafficTimeSigns.forEach((tts) => {
      regionByTasId[tts.properties.region?.id]?.properties?.tts?.push(tts)
    })
  }

  regionsState.set(new Date().getTime())
}
