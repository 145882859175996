import { useTrafficLayer } from '../../../../services/map/TrafficLayer'
import { updateFilter, useFilterState } from '../../../../stores/MapFiltersState'
import { getHolidayJourneys } from '../../../../stores/national-data/hotspots'
import { IconType } from '../../../../types/icon-name-types'
import { mapFilterItemTypes, MapFilterType } from '../../../../types/JourneyPlannerTypes'
import Icon from '../../../common/icon'
import CheckableField from '../../../forms/checkable-fields/checkable-field/index'

interface FilterItemProps {
  label: string
  icon: IconType
  id: MapFilterType
  checked: boolean
}

const FilterItem = ({ label, icon, id, checked }: FilterItemProps) => {
  return (
    <li className='c-map-filter__item'>
      <div className='c-map-filter__icon'>
        <Icon icon={icon} ariaHidden={false} ariaLabel={label} />
      </div>
      <div className='c-map-filter__label'>
        <CheckableField
          id={id}
          label={label}
          checked={checked}
          name='mapFilterOptions'
          onChange={(e) => updateFilter(id, e.currentTarget.checked)}
          type='checkbox'
        />
      </div>
    </li>
  )
}

const MapFilter = () => {
  const mapFilters = useFilterState().get()
  const holidayData = getHolidayJourneys()
  useTrafficLayer()

  // TODO allow scrolling panel if too tall for screen
  return (
    <div className='c-map-filter'>
      <ol className='c-map-filter__list'>
        {mapFilterItemTypes.map((id) => {
          const { label, icon, enabled, applicable } = mapFilters[id]
          if (!applicable) return null
          if (id === 'holiday-journeys' && !Object.entries(holidayData).length) {
            return null
          }
          return <FilterItem key={id} id={id} label={label} icon={icon} checked={enabled} />
        })}
      </ol>
    </div>
  )
}

export default MapFilter
