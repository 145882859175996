import { createState, useState } from '@hookstate/core'
import { RecentJourney } from '../../types/JourneyPlannerTypes'

const RECENT_JOURNEY_LIMIT = 6

const loadJourneysFromStorage = () => {
  try {
    const cache = window.localStorage.getItem('recent-journeys')
    return (cache && JSON.parse(cache)) || []
  } catch (e) {
    console.error('Failed to load recent journeys', e)
  }
  return []
}
const saveJourneysToStorage = (journeys: Array<RecentJourney>) => {
  try {
    window.localStorage.setItem('recent-journeys', JSON.stringify(journeys))
  } catch (e) {
    console.error('Failed to save recent journeys', e)
  }
}

let loaded = false
const recentJourneysState = createState<Array<RecentJourney>>([])
export const useRecentJourneysState = () => {
  return useState(recentJourneysState)
}

export const loadRecentJourneys = () => {
  if (!loaded) {
    loaded = true
    recentJourneysState.set(loadJourneysFromStorage())
  }
}

export const addRecentJourney = (title: string, query: string) => {
  const journeys = JSON.parse(JSON.stringify(recentJourneysState.get()))
  const existingRecentJourney = journeys.find((c: RecentJourney) => c.query === query)
  if (existingRecentJourney) {
    existingRecentJourney.date = new Date().getTime()
  } else {
    journeys.push({
      title,
      query,
      date: new Date().getTime(),
    })
  }
  const limitedJourneys = journeys
    .sort((a: RecentJourney, b: RecentJourney) => b.date - a.date)
    .slice(0, RECENT_JOURNEY_LIMIT)
  saveJourneysToStorage(limitedJourneys)
  recentJourneysState.set(limitedJourneys)
}
