import { ReactElement, Fragment } from 'react'
import { usePanToItem } from '../../../services/map/MarkerCollection'
import { VmsSign, ChchVmsSign } from '../../../types/JourneyPlannerTypes'
import PanelSection from '../../layout/panel-section'
import PanelFooter from '../panel-footer/index'

interface Props {
  item: VmsSign | ChchVmsSign
  itemLinks?: {
    prevUrl: string
    nextUrl: string
  }
}

const formattedDate = (date: any) => {
  const dateObject = new Date(date)
  var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return dateObject.getDate() + ' ' + month[dateObject.getMonth()] + ' ' + dateObject.getFullYear()
}

const VmsGeneric = ({ item, itemLinks }: Props) => {
  return (
    <Fragment>
      {item.description && <p>{item.description}</p>}
      {item.lastUpdated && <p>{formattedDate(item.lastUpdated)}</p>}
      {item.currentMessage &&
        item.currentMessage.split('[np]').map((para: any) => (
          <p>
            {' '}
            {para.split('[nl]').map((line: any) => (
              <Fragment>
                {line.replaceAll('[jl4]', ' ')}
                <br />
              </Fragment>
            ))}{' '}
          </p>
        ))}
      <PanelFooter footerLinks={itemLinks} />
    </Fragment>
  )
}

const VmsChch = ({ item, itemLinks }: Props) => {
  return (
    <Fragment>
      {item.Name && <p>{item.Name}</p>}

      {item.LastEditedNice && <p>{item.LastEditedNice}</p>}
      {item.IncidentMessage && <p>{item.IncidentMessage === '[]' ? 'No incidents reported' : item.IncidentMessage}</p>}
      <ul className='c-info-list'>
        {item.JourneyTimes.map((line: any) => {
          const condition = `condition condition--${line.condition}`

          return (
            line.text &&
            line.condition &&
            line.time && (
              <li className='c-info-list__item'>
                <p className='c-info-list__label'>{line.text}</p>
                <p className='c-info-list__data'>
                  <span className={condition}></span>
                  {line.time} MINS
                </p>
              </li>
            )
          )
        })}
      </ul>
    </Fragment>
  )
}

export default function MessageSignsPanel({ item, itemLinks }: Props): ReactElement {
  usePanToItem({ type: item?.type, id: item?.id, focus: true })
  return (
    <PanelSection>
      {item.ClassName === 'ChchVms' ? (
        <VmsChch item={item} itemLinks={itemLinks} />
      ) : (
        <VmsGeneric item={item} itemLinks={itemLinks} />
      )}
    </PanelSection>
  )
}
