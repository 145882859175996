import classNames from 'classnames'
import { format, getDay, parseISO } from 'date-fns'
import parse from 'html-react-parser'
import { ReactElement, useState } from 'react'
import { HolidayHotspotTime, HolidayJourney } from '../../../types/features'
import Button from '../../common/button'
import { Key } from '../../common/key'
import PanelSection from '../../layout/panel-section'
import HotspotChart from './visualisation'

interface Props {
  item: HolidayJourney
}

enum JourneyView {
  CHART = 'chart',
  LIST = 'list',
}

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const formatDate = (date: string) => {
  const dateFormatted = format(parseISO(date), 'dd MMM')
  const day = DAYS[getDay(parseISO(date))]
  return `${day} ${dateFormatted}`
}

export default function HolidayJourneysPanel({ item }: Props): ReactElement {
  const [journeyView, setJourneyView] = useState(JourneyView.CHART)

  if (!item) return <>No holiday traffic information available</>

  const timesByDirection = item.HolidayHotspotTimes.reduce((acc: { [key: string]: HolidayHotspotTime[] }, val) => {
    if (acc[val.Direction]) {
      acc[val.Direction].push(val)
    } else {
      acc[val.Direction] = [val]
    }

    return acc
  }, {})

  const convertTimeToAMorPM = (val: string) => {
    // Check correct time format and split into components
    let time = val.match(/^([01]\d|2[0-3]|[0-9])(:)([0-5]\d)(:[0-5]\d)?$/) || [val]

    if (time.length > 1) {
      time = time.slice(1)
      time[5] = +time[0] < 12 ? 'am' : 'pm'
      time[0] = `${+time[0] % 12 || 12}`
    }
    return time.join('')
  }

  let groupedTimes: Record<string, any> = {}
  item.HolidayHotspotTimes.forEach(function (times) {
    groupedTimes[times.Direction] = groupedTimes[times.Direction] || []
    groupedTimes[times.Direction].push(times)
  })

  const handleViewChange = (view: JourneyView) => {
    if (journeyView !== view) {
      setJourneyView(view)
      document.querySelectorAll('[class^="c-hotspot-panel__chart-area"]').forEach((result) => result.replaceChildren())
    }
  }

  return (
    <PanelSection>
      <div className='c-hotspot-btn-group'>
        <Button
          label='Chart view'
          size='medium'
          className={classNames('c-hotspot-btn-group__btn c-btn c-btn--secondary', {
            'c-hotspot-btn-group__btn--active': journeyView === JourneyView.CHART,
          })}
          onClick={() => handleViewChange(JourneyView.CHART)}
        />
        <Button
          label='List view'
          size='medium'
          className={classNames('c-hotspot-btn-group__btn c-btn c-btn--secondary', {
            'c-hotspot-btn-group__btn--active': journeyView === JourneyView.LIST,
          })}
          onClick={() => handleViewChange(JourneyView.LIST)}
        />
      </div>
      {journeyView === JourneyView.CHART && (
        <div className='c-hotspot-keys'>
          <Key colour='light-green' label='Freeflowing' shape='square' />
          <Key colour='orange' label='Busy' shape='square' />
          <Key colour='red' label='Heaviest' shape='square' />
        </div>
      )}
      <div className='c-hotspot-panel'>
        {Object.entries(timesByDirection).map(([direction, timeDetails]) => {
          return (
            <div className='c-hotspot-panel__direction-group'>
              {journeyView === JourneyView.CHART ? (
                <>
                  <h3 className='c-hotspot-panel__direction-heading'>{direction}</h3>
                  <HotspotChart data={groupedTimes[direction]} direction={direction} />
                </>
              ) : (
                <>
                  <table className='c-hotspot-panel__table'>
                    <caption className='c-hotspot-panel__direction-heading'>{direction}</caption>
                    <tr>
                      <th className='visible-hidden'>Date</th>
                      <th className='visible-hidden'>Times</th>
                    </tr>
                    {timeDetails.map((t) => (
                      <tr className='c-hotspot-panel__row'>
                        <td data-header='Date' className='c-hotspot-panel__cell'>
                          {formatDate(t.Date)}
                        </td>
                        <td data-header='Times' className='c-hotspot-panel__cell'>
                          {t.BusyTimeStart && t.BusyTimeEnd && (
                            <div>
                              Busy: {convertTimeToAMorPM(t.BusyTimeStart)} - {convertTimeToAMorPM(t.BusyTimeEnd)}
                            </div>
                          )}
                          {t.HeaviestTimeStart && t.HeaviestTimeEnd && (
                            <div>
                              <b>
                                Heaviest: {convertTimeToAMorPM(t.HeaviestTimeStart)} -{' '}
                                {convertTimeToAMorPM(t.HeaviestTimeEnd)}
                              </b>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                </>
              )}
            </div>
          )
        })}
        <div className='c-hotspot-panel__footer-text'>{parse(item.Disclaimer)}</div>
      </div>
    </PanelSection>
  )
}
