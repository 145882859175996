import classNames from 'classnames'
import React from 'react'
import { useActiveTabState } from '../../../stores/TabNavigationState'

export interface TabItemProps extends React.ComponentPropsWithRef<'button'> {
  tabType: string
  label: string
  active?: boolean
}

export interface TabsProps extends React.ComponentPropsWithRef<'div'> {
  menuName: string
  tabs: Array<TabItemProps>
  activeTab?: string
  hideOnDesktop?: boolean
}

export default function Tabs({ menuName, tabs, activeTab, hideOnDesktop }: TabsProps) {
  const settingActiveTab = useActiveTabState()

  const tabsClasses = classNames('c-tabs', {
    'c-tabs--hide-desktop': hideOnDesktop,
  })

  return (
    <div className={tabsClasses} aria-label={menuName}>
      <ul className='c-tabs__items'>
        {tabs &&
          tabs.map(({ label, tabType }) => {
            const tabItemClasses = classNames('c-tabs__item', {
              'c-tabs__item--active': activeTab === tabType,
            })

            return (
              <li key={label} className={tabItemClasses}>
                <button className='c-tabs__tab-btn' onClick={() => settingActiveTab.set(tabType)}>
                  {label}
                </button>
              </li>
            )
          })}
      </ul>
    </div>
  )
}
