import * as d3 from 'd3'
import { useEffect, Fragment } from 'react'
import { formatDate } from '.'

const HOURS = ['6am', '', '8am', '', '10am', '', '12 noon', '', '2pm', '', '4pm', '', '6pm', '', '8pm', '', '10pm']
const HOUR_VALUES = HOURS.map(function (h, i) {
  return 360 + i * 60
})
const START_TIME = '06:00'
const END_TIME = '22:00'

/**
 * Converts time strings to minutes for the x scale
 * e.g. '08:00' becomes 480
 * @param  {string} time in 24H format e.g. '14:30'
 * @return {number}      minutes from start of day
 */
function timeToX(time: any) {
  const bits = time.split(':')
  const hour = parseInt(bits[0], 10)
  const minutes = parseInt(bits[1], 10)
  return hour * 60 + minutes
}

/**
 * Creates 2-3 data points for each travel time (normal, busy, [heaviest])
 * Creates a travelTimes array of data points, and dates array of y-scale values
 * @param  {array} travelTimes From SilverStripe
 * @return {object}            With 'travelTimes' and 'dates' arrays
 */
function convertToD3Data(travelTimes: any) {
  const data: Record<string, any> = {
    travelTimes: [],
    dates: [],
  }
  travelTimes.forEach(function (travelTime: any) {
    let date = formatDate(travelTime.Date)
    data.dates.push(date)
    let tooltips = travelTime
    data.travelTimes.push({
      start: timeToX(START_TIME),
      end: timeToX(END_TIME),
      condition: 'normal',
      date: date,
      tooltip: tooltips.busy + ' ' + tooltips.heaviest,
    })
    if (travelTime.BusyTimeStart && travelTime.BusyTimeEnd) {
      data.travelTimes.push({
        start: timeToX(travelTime.BusyTimeStart),
        end: timeToX(travelTime.BusyTimeEnd),
        condition: 'busy',
        date: date,
        tooltip: tooltips.busy,
      })
    }
    if (travelTime.HeaviestTimeStart && travelTime.HeaviestTimeEnd) {
      data.travelTimes.push({
        start: timeToX(travelTime.HeaviestTimeStart),
        end: timeToX(travelTime.HeaviestTimeEnd),
        condition: 'heaviest',
        date: date,
        tooltip: tooltips.heaviest,
      })
    }
  })

  return data
}

// Compute the width/height for the chart
function getSizes(selector: string, itemCount: number) {
  var sizes: Record<string, any> = {}
  sizes.margin = { top: 30, right: 15, bottom: 0, left: 60 }
  sizes.outerWidth = document.querySelector(selector)?.clientWidth ?? 400
  sizes.width = sizes.outerWidth - sizes.margin.left - sizes.margin.right
  sizes.yItemHeight = 30
  sizes.height = sizes.yItemHeight * itemCount
  sizes.outerHeight = sizes.height + sizes.margin.top + sizes.margin.bottom
  return sizes
}

export default function HotspotChart({ data, direction }: { data: any; direction: string }) {
  useEffect(() => {
    createTravelTimeChart(data, `.c-hotspot-panel__chart-area--${direction}`)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    d3.select(`.c-hotspot-panel__chart-area--${direction}`).selectAll('*').remove()
    createTravelTimeChart(data, `.c-hotspot-panel__chart-area--${direction}`)
  }, [data, direction])
  /**
   * Creates a travel time visualisation using d3
   * @param  {array} travelTimes  HolidayHotspotTime objects from SilverStripe
   * @param  {string} selector    jQuery/d3 selector, where to append chart to
   */

  function createTravelTimeChart(travelTimes: any, selector: string) {
    travelTimes = convertToD3Data(travelTimes)

    const timeDomainStart = timeToX(START_TIME)
    const timeDomainEnd = timeToX(END_TIME)

    let hours = HOURS
    let element = document.querySelector(selector)?.clientWidth
    if (element && element < 500) {
      hours = hours.map(function (h, i) {
        return i % 4 === 2 ? h : ''
      })
    }

    const sizes = getSizes(selector, travelTimes.dates.length)

    var x = d3.scaleLinear().domain([timeDomainStart, timeDomainEnd]).range([0, sizes.width]).clamp(true)
    var xAxis = d3
      .axisTop(x)
      .tickSizeOuter(0)
      .tickSizeInner(sizes.height + 4)
      .tickValues(HOUR_VALUES)
      .tickFormat(function (t: any) {
        return hours[HOUR_VALUES.indexOf(t)]
      })
      .tickPadding(8)

    var y = d3.scaleBand().domain(travelTimes.dates).range([0, sizes.height]).paddingInner(0.25).paddingOuter(0)
    var yAxis = d3.axisRight(y).tickSize(0).tickPadding(-sizes.margin.left)

    var svg = d3
      .select(selector)
      .append('svg')
      .attr('class', 'hotspot-chart')
      .attr('width', sizes.outerWidth)
      .attr('height', sizes.outerHeight)

    var parent = svg.append('g').attr('transform', 'translate(' + sizes.margin.left + ',' + sizes.margin.top + ')')

    parent
      .selectAll('rect')
      .data(travelTimes.travelTimes)
      .enter()
      .append('rect')
      .attr('class', function (d: any) {
        return d.condition
      })
      .attr('y', function (d: any) {
        return y(d.date)
      })
      .attr('x', function (d: any) {
        return x(d.start)
      })
      .attr('height', function () {
        return y.bandwidth()
      })
      .attr('width', function (d: any) {
        return x(d.end) - x(d.start)
      })

    parent
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0, ' + sizes.height + ')')
      .transition()
      .call(xAxis)
      .selectAll('.tick line')
      .filter(function (t: any) {
        return hours[HOUR_VALUES.indexOf(t)] !== ''
      })
      .attr('class', 'highlight')

    parent.append('g').attr('class', 'y axis').transition().call(yAxis)
  }

  return (
    <Fragment>
      <svg height='10' width='10' xmlns='http://www.w3.org/2000/svg' version='1.1'>
        <defs>
          <pattern id='diagonal-stripe-1' patternUnits='userSpaceOnUse' width='10' height='10'>
            <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'>
              <rect width='10' height='10' fill='none' />
              <path d='M-1,1 l2,-2            M0,10 l10,-10            M9,11 l2,-2' stroke='#b4c656' stroke-width='5' />
            </svg>
          </pattern>
        </defs>
      </svg>
      <div className={`c-hotspot-panel__chart-area--${direction}`}></div>
    </Fragment>
  )
}
