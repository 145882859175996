// import { DevTools } from '@hookstate/core'
import { createState, useState } from '@hookstate/core'
import { parseAddressParts } from '../../util/address-formatter'
import { JourneySearchPoint, JourneyResult } from '../../types/JourneyPlannerTypes'
import routeLoader from '../../util/google-route-loader'
import { addRecentJourney } from './RecentJourneysState'
import { sortByDistanceAlongLine } from '../../util/geometry-utils'

// TODO: Have discussion around using context and reducers instead of using hookstate

const aPlaceState = createState<JourneySearchPoint | null>(null)
// DevTools(aPlaceState).label('journey-search-state-place-a')
export const usePlaceStateA = () => {
  return useState(aPlaceState)
}
const bPlaceState = createState<JourneySearchPoint | null>(null)
// DevTools(bPlaceState).label('journey-search-state-place-b')
export const usePlaceStateB = () => {
  return useState(bPlaceState)
}
const placeASearchFailedState = createState(false)
// DevTools(placeASearchFailedState).label('journey-search-place-a-failed')
export const usePlaceASearchFailedState = () => {
  return useState(placeASearchFailedState)
}
const placeBSearchFailedState = createState(false)
// DevTools(placeBSearchFailedState).label('journey-search-place-b-failed')
export const usePlaceBSearchFailedState = () => {
  return useState(placeBSearchFailedState)
}

let placesService: google.maps.places.PlacesService

const queryDebounce = {
  a: 1,
  b: 1,
}

export const updatePlaceFromQuery = (position: 'a' | 'b', search: string) => {
  if (!placesService) {
    placesService = new google.maps.places.PlacesService(document.createElement('div'))
  }
  queryDebounce[position] += 1
  const placeStateToUpdate = position === 'a' ? aPlaceState : bPlaceState
  const errorStateToUpdate = position === 'a' ? placeASearchFailedState : placeBSearchFailedState
  if (search.length < 1) {
    if (!!placeStateToUpdate.get()) {
      placeStateToUpdate.set(null)
    }
    if (errorStateToUpdate.get()) {
      errorStateToUpdate.set(false)
    }
    return
  }
  const requestValue = queryDebounce[position]
  placesService.getDetails(
    {
      placeId: search,
      fields: ['address_component', 'geometry', 'name', 'place_id'],
    },
    (place) => {
      // Check this is the latest request for this position
      if (requestValue !== queryDebounce[position]) {
        return
      }
      if (place && place.geometry?.location && place.address_components) {
        const addressParts = parseAddressParts(place)
        placeStateToUpdate.set({
          title: addressParts.name,
          search: search,
          address: addressParts.formatted,
          parts: parseAddressParts(place),
          geometry: place.geometry.location,
        })
        if (errorStateToUpdate.get()) {
          errorStateToUpdate.set(false)
        }
      } else if (!errorStateToUpdate.get()) {
        errorStateToUpdate.set(true)
      }
    },
  )
}

export const updatePlacesFromQuery = (query: URLSearchParams) => {
  const aQuery = query.get('a') || ''
  const bQuery = query.get('b') || ''
  const aPlace = aPlaceState.get()
  const bPlace = bPlaceState.get()

  // TODO fall back to address lookup if place_id isn't supplied or doesn't work? (can't use placeService though)
  if (!aPlace || aPlace.search !== aQuery) {
    updatePlaceFromQuery('a', aQuery)
  }
  if (!bPlace || bPlace.search !== bQuery) {
    updatePlaceFromQuery('b', bQuery)
  }
}

const journeyResultState = createState<JourneyResult | null>(null)
// Saving the DirectionsResult object outside of State because the proxy object seems to break Google maps (at least when trying to remove the DirectionsResult from the map)
let loadedDirections: google.maps.DirectionsResult | null = null
export const getJourneyDirections = () => {
  return loadedDirections
}
export const useJourneyResultState = () => {
  return useState(journeyResultState)
}
let routeLoaderDebounce = ''
export const clearJourneyResults = () => {
  routeLoaderDebounce = ''
  journeyResultState.set(null)
  loadedDirections = null
}
// Simple check to not bother reloading directions for points we already have/are
const hashPointsForUpdateCheck = (a: google.maps.LatLng, b: google.maps.LatLng) => {
  return `${a.lat()}-${a.lng()}-${b.lat()}-${b.lng()}`
}
export const loadJourneyResults = (a: JourneySearchPoint, b: JourneySearchPoint) => {
  const locationHash = hashPointsForUpdateCheck(a.geometry, b.geometry)
  if (locationHash !== journeyResultState.get()?.locationHash && locationHash !== routeLoaderDebounce) {
    routeLoaderDebounce = locationHash

    // TODO: Pull this out into a helper
    const onDone = (directions: google.maps.DirectionsResult | null, routes: any) => {
      if (locationHash === routeLoaderDebounce) {
        if (directions) {
          // See note above about why these are separate
          loadedDirections = directions
          journeyResultState.set({
            routes,
            locationHash,
          })
          addRecentJourney(`${a.title} to ${b.title}`, `a=${a.search}&b=${b.search}`)
        } else if (journeyResultState.get()) {
          loadedDirections = null
          journeyResultState.set(null)
        }
        // Load related data
        if (routes.length) {
          const gRoutes: any = {}
          // Send simplified geometries
          routes.forEach((route: any) => {
            gRoutes[route.properties.id] = route.properties.simpleGeometry
          })
          const host = document.getElementById('root')?.dataset?.host || ''
          // TODO: Convert this to using async/await
          fetch(host + '/api/related', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ routes: gRoutes }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (locationHash !== routeLoaderDebounce) {
                return
              }
              if (data && Object.keys(data).length > 0) {
                Object.keys(data).forEach((routeIndex: any) => {
                  if (routes[routeIndex]) {
                    if (Array.isArray(data[routeIndex]) && data[routeIndex].length) {
                      // TODO: Look at all the other places sorting happens on this content and group
                      routes[routeIndex].properties.related = sortByDistanceAlongLine(
                        routes[routeIndex],
                        data[routeIndex],
                      )
                    }
                    routes[routeIndex].properties.hasLoadedRelated = true
                  }
                })
                journeyResultState.set({
                  routes,
                  locationHash,
                })
              }
            })
            .catch((err) => {
              console.error('Failed to load related data', err)
            })
        }
      }
    }
    routeLoader.loadRoutes(a.geometry, b.geometry, onDone)
  }
}

const hasInvalidSearchState = createState(false)
export const useInvalidSearchState = () => {
  return useState(hasInvalidSearchState)
}

const hasActiveSearchState = createState(false)
export const useActiveSearchState = () => {
  return useState(hasActiveSearchState)
}

const selectedRouteIndexState = createState(0)
export const useSelectedRouteIndexState = () => {
  return useState(selectedRouteIndexState)
}
export const setSelectedRouteIndex = (index: number) => {
  selectedRouteIndexState.set(index)
}

const mapFullscreenState = createState(false)
export const useMapFullscreenState = () => {
  return useState(mapFullscreenState)
}
