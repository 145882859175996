import { createState, useState } from '@hookstate/core'
import { useEffect } from 'react'
import { useGoogleLoadState, getMap } from '../../stores/GoogleMapState'
import { MapFilterIconsMap } from '../../stores/MapFiltersState'
import { iconPaths } from '../../types/icon-name-types'
import { Delay, GeojsonFeature } from '../../types/JourneyPlannerTypes'
import { clearMarkerCollection, createDataLayer, styleMultiLine } from './MarkerCollection'


const trafficUpdateDetailState = createState<GeojsonFeature<Delay> | null>(null)

export const useTrafficUpdateDetailState = () => {
  return useState(trafficUpdateDetailState)
}

let pointMarker: google.maps.Marker
let dataLayer: google.maps.Data
let bounds: google.maps.LatLngBounds
let pointMarkerPos: google.maps.LatLng | null | undefined

export const useTrafficUpdateDetailMap = () => {
  const googleLoadState = useGoogleLoadState()
  const updateApi = trafficUpdateDetailState.get()

  useEffect(() => {
    const googleLoaded = googleLoadState.get()
    const map = getMap()

    clearMarkerCollection()

    if (googleLoaded && map && updateApi) {
      const iconName = MapFilterIconsMap[updateApi?.properties.type]
      const iconPath = iconName && iconPaths[iconName]

      if (updateApi.geometry.type === 'Point') {
        pointMarker = new google.maps.Marker({
          position: new google.maps.LatLng({
            lng: updateApi.geometry.coordinates[0],
            lat: updateApi.geometry.coordinates[1],
          }),
          icon: iconPath || iconPaths['marker'],
          map: map,
        })
      }
      pointMarkerPos = pointMarker?.getPosition()

      if (pointMarkerPos) {
        bounds = new google.maps.LatLngBounds()

        bounds.extend(pointMarkerPos)

        // Zoom map to fit markers with padding of 50:
        map.fitBounds(bounds, 50)
        map.setZoom(18)
      } else {
        const styleFn = (feature: google.maps.Data.Feature) => styleMultiLine(null, feature)

        const coords = updateApi.geometry.coordinates.flatMap((x: any) => x)

        const startPosition = new google.maps.LatLng({
          lng: coords?.[0][0],
          lat: coords?.[0][1],
        })
        const endPosition = new google.maps.LatLng({
          lng: coords?.[coords.length - 1][0],
          lat: coords?.[coords.length - 1][1],
        })

        bounds = new google.maps.LatLngBounds()

        bounds.extend(startPosition)
        bounds.extend(endPosition)

        if (!dataLayer) {
          dataLayer = createDataLayer(map, styleFn, () => {})
        }

        map.fitBounds(bounds, 20)

        dataLayer.addGeoJson(updateApi)
      }
    }
  }, [googleLoadState, updateApi])
}
