import React, { ReactElement, useCallback, useEffect, useState, useRef } from 'react'
import { getDelays, loadData, useDelaysState } from '../../../stores/national-data/delays'
import { getRegions, useRegionsState } from '../../../stores/national-data/regions'
import { InputWidth } from '../../../types/input-width-types'
import { Delay, GeojsonFeature, Region } from '../../../types/JourneyPlannerTypes'
import FormField from '../../forms/form-field'
import Select from '../../forms/select'
import Table from './table'
import TableRow from './table-row'
import { useInterval } from 'usehooks-ts'

export default function TrafficTable(): ReactElement {
  // Initiate delays data refresh every 5 minutes:
  const timer = 1000 * 60 * 5
  useInterval(() => {
    loadData()
  }, timer)

  // Initialise states:
  const delaysUpdatedState = useDelaysState()
  const regionsUpdatedState = useRegionsState()
  const [delaysState, setDelaysState] = useState<GeojsonFeature<Delay>[]>()
  const [regionsState, setRegionsState] = useState<GeojsonFeature<Region>[]>()

  // Group/Sort state:
  const [groupByValue, setGroupByValue] = useState('EventIsland')
  const [sortByValue, setSortByValue] = useState('EventType')

  // Group/Sort options:
  const sortByOptions = [
    { label: 'Event Type', value: 'EventType' },
    { label: 'Impact', value: 'Impact' },
  ]
  const groupByOptions = [
    { label: 'Island', value: 'EventIsland' },
    { label: 'Region', value: 'Region' },
  ]

  const delayIslands = [
    { name: 'North Island' },
    { name: 'South Island' },
  ]

  // Switch for sorting by Event or Impact:
  const sortValues = useCallback((a: any, b: any) => {
    switch (sortByValue) {
      case 'EventType':
        if (a.properties.EventType < b.properties.EventType) {
          return -1
        }
        if (a.properties.EventType > b.properties.EventType) {
          return 1
        }
        return 0
      case 'Impact':
        if (b.properties.Impact === undefined || a.properties.Impact < b.properties.Impact) {
          return -1
        }
        if (a.properties.Impact > b.properties.Impact) {
          return 1
        }
        return 0
      default:
        return 1
    }
  }, [sortByValue])

  // Sorts when changes made to sortByValue in above CB:
  delaysState?.sort(sortValues)

  useEffect(() => {
    const delays = getDelays()
    const regions = getRegions()
    setDelaysState(delays)
    setRegionsState(regions)
  }, [delaysUpdatedState.value, regionsUpdatedState.value])

  // Update sorting/grouping on change:
  useEffect(() => {
    setDelaysState(delaysState)
  }, [delaysState])


  const titleRef = useRef(null)
  useEffect(() => {
    if (titleRef.current) titleRef.current.focus()
  }, [titleRef])

  return (
    <div className='l-traffic-table'>
      <div className='l-traffic-table__wrapper'>
        <div className='remove-outline' aria-live='polite' ref={titleRef} tabIndex={0}>
          <h1>Traffic and travel table</h1>
        </div>
        <div className='l-traffic-table__filters'>
          <FormField htmlFor='traffic_group-by' label='Group by'>
            <Select
              onChange={(e) => setGroupByValue(e.target.value)}
              id='traffic_group-by'
              placeholderType='none'
              options={groupByOptions}
              defaultValue={groupByOptions[0].value}
              fieldSize={InputWidth.large}
            />
          </FormField>
          <FormField htmlFor='traffic_sort-by' label='Sort by'>
            <Select
              onChange={(e) => setSortByValue(e.target.value)}
              id='traffic_sort-by'
              placeholderType='none'
              options={sortByOptions}
              defaultValue={sortByOptions[0].value}
              fieldSize={InputWidth.large}
            />
          </FormField>
        </div>
          {
            delaysState && (
              (groupByValue === "EventIsland")
              ?
              delayIslands.map((i) => {
                return (
                  <Table heading={i.name}>
                    {delaysState.map((item) => {
                      if (item.properties.EventIsland === `${i.name}`) {
                        return <TableRow item={item} />
                      }else{
                        return null;
                      }
                    })}
                  </Table>
                )
              })
              :
              regionsState?.map((r) => {
                const regionDelays = delaysState.filter((d) => d.properties.regions.includes(Number(r.properties.id)))
                if(regionDelays.length) {
                  return (
                    <Table heading={r.properties.name}>
                      {
                        regionDelays.map((item) => {
                          return (
                            <TableRow item={item} />
                          )
                        })
                      }
                  </Table>
                  )
                }else{
                  return null;
                }
              })
            )}
      </div>
    </div>
  )
}
