const GeoJsonUtils =  {
  createFeature: function createFeature(props: object, geoType: string, coords: Array<any>) {
    const feature: any = {
      type: 'Feature',
      properties: props,
      geometry: {
        type: geoType,
        coordinates: coords,
      },
    }
    return feature
  },

  createFeatureCollection: function createFeatureCollection(features: Array<any>) {
    return {
      type: 'FeatureCollection',
      features: features,
    }
  },

  combineCollections: function (geojson1: any, geojson2: any) {
    return this.createFeatureCollection(geojson1.features.concat(geojson2.features))
  },
}
export default GeoJsonUtils