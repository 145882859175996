import React, { ReactElement, useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import PanelSection from '../../../layout/panel-section/index'
import Heading from '../../../common/heading'
import Button from '../../../common/button'
import Modal from '../../../common/modal'
import usePopOutPanel from '../../../../hooks/use-pop-out-panel'
import { useFilterState } from '../../../../stores/MapFiltersState'
import { mapFilterItemTypes } from '../../../../types/JourneyPlannerTypes'

interface PanelHeaderProps {
  title: string
  subTitle?: string
  styleClass?: 'default' | 'inline' | string
  filter?: boolean
  modalContent?: React.ReactNode
  noPadding?: boolean
  borderBottom?: boolean
  closeAriaLabel?: string
  noHeadingMargin?: boolean
}

export default function PanelHeader({
  title,
  subTitle,
  filter,
  modalContent,
  noPadding,
  noHeadingMargin,
  borderBottom = true,
  closeAriaLabel,
  styleClass = 'default',
}: PanelHeaderProps): ReactElement {
  const [modalOpen, setModalOpen] = useState(false)
  const [state, open, close] = usePopOutPanel()

  const closeModal = () => {
    setModalOpen(false)
  }
  const refPageTitle = useRef(null)
  useEffect(() => {
      if(title && refPageTitle && refPageTitle.current){             
        refPageTitle.current.focus()
      }
  }, [title])

  const panelSectionClasses = classNames('c-panel-header', {
    'c-navigation-panel__section--no-padding': noPadding,
    'c-panel-header--no-heading-margin': noHeadingMargin,
    [`c-panel-header--${styleClass}`]: !!styleClass,
  })

  // Set active filter number:
  const mapFilters = useFilterState().get()
  let activeNum = 0
  mapFilterItemTypes.map((id) => {
    const { enabled } = mapFilters[id]
    if (enabled) activeNum += 1
    return null
  })

  return (
    <PanelSection className={panelSectionClasses} hasBorderBottom={borderBottom}>
      <h1  className='c-panel-header__heading' >
        <span ref={refPageTitle} tabIndex='0'>{title}</span>
        {modalContent && (
          <span className='c-panel-header__info'>
            <Button
              buttonStyle='icon'
              icon='info'
              iconAriaLabel='More information'
              onClick={() => setModalOpen(true)}
            />
          </span>
        )}
      </h1>
      {modalContent && (
        <Modal
          closeAriaLabel={closeAriaLabel || 'close modal'}
          closeModal={closeModal}
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          contentLabel='About this page'
          heading={title}
        >
          {modalContent}
        </Modal>
      )}

      {filter && (
        <div className='c-panel-header__actions'>
          <Button
            icon='filter'
            iconAlignment='left'
            buttonStyle='text-action'
            size='small'
            noPadding
            className='c-btn-filter'
            onClick={() => {
              if (state.type === 'filters') {
                close()
              } else {
                open({ type: 'filters' })
              }
            }}
            label={`Filters (${activeNum})`}
          />
        </div>
      )}

      {subTitle && (
        <Heading level={2} className='c-panel-header__heading'>
          {subTitle}
        </Heading>
      )}
    </PanelSection>
  )
}
