import { AnimatePresence } from 'framer-motion'
import { useEffect, useMemo, useRef } from 'react'
import usePopOutPanel from '../../../hooks/use-pop-out-panel'
import { usePanToItem } from '../../../services/map/MarkerCollection'
import { useFilterState } from '../../../stores/MapFiltersState'
import { DetailPanelType, RelatedItem } from '../../../types/JourneyPlannerTypes'
import EVChargingSitePanel from '../../detail-panels/ev-charging-site-panel'
import HolidayJourneysPanel from '../../detail-panels/holiday-journeys-panel'
import MessageSignsPanel from '../../detail-panels/message-signs-panel'
import RoadWorksPanel from '../../detail-panels/road-works-panel'
import GeneralWarningsPanel from '../../detail-panels/general-warnings'
import TrafficCamerasPanel from '../../detail-panels/traffic-cameras-panel'
import TrafficTimePanel from '../../detail-panels/traffic-time-panel'
import MapFilter from '../../journey-planner/route-form/map-filter'
import { Portal } from '../../portal'
import PopOutPanelContent from './content'

export const PANEL_COMPONENT_MAP = {
  cameras: TrafficCamerasPanel,
  ev: EVChargingSitePanel,
  closures: RoadWorksPanel,
  roadworks: RoadWorksPanel,
  hazards: RoadWorksPanel,
  warnings: RoadWorksPanel,
  'holiday-journeys': HolidayJourneysPanel,
  tts: TrafficTimePanel,
  vms: MessageSignsPanel,
  'general-warnings': GeneralWarningsPanel,
}

const PopOutPanel = () => {
  const [state, , close] = usePopOutPanel()
  const { type, subType, detailId } = state

  const filters = useFilterState()

  const [item, itemName, evName] = useMemo(() => {
    const itemNameKeys = ['name', 'Name', 'Title']
    let items: RelatedItem[] = []
    let item: any
    let itemName
    let evName

    if (detailId && subType && type === 'item-details') {
      items = filters[subType as DetailPanelType].get().retrieveFn()
    }
    if (items.length > 0 && detailId) {
      item = items.find((i) => parseInt(i?.properties?.id) === detailId)
      if (item) {
        itemNameKeys.forEach((key) => {
          if (item.properties[key]) {
            if (subType === 'tts' || subType === 'vms') {
              itemName = 'Travel Information'
            } else {
              itemName = item.properties?.[key] ?? ''
            }
          }
        })
        if (item && subType === 'ev') {
          evName = `EV charging station: ${item.properties.Name} - ${item.properties.Address}` ?? ''
        }
      }
    }
    return [item, itemName, evName]
  }, [detailId, filters, subType, type])

  const DetailPanelComponent = PANEL_COMPONENT_MAP[subType as DetailPanelType]

  usePanToItem({ type: item?.properties?.type, id: item?.properties?.id, focus: true })

  const refTitle = useRef(null)

  useEffect(() => {
    if (item || type === 'filters') refTitle.current.focus()
  }, [item, type])

  const closeFilterAction = () => {
    let filterButton = document.getElementsByClassName('c-btn-filter')

    if (filterButton.length > 0) {
      filterButton[0].focus()
    }
    close()
  }

  const closeAction = () => {
    let routeDetailList = document.getElementsByClassName('c-route-detail--selected')
    if (routeDetailList.length > 0) {
      const activeLink = routeDetailList[0].getElementsByClassName('c-route-detail__name')[0]
      if (activeLink) activeLink.focus()
    }
    close()
  }

  return (
    <Portal>
      <AnimatePresence exitBeforeEnter={true}>
        {type === 'filters' && (
          <PopOutPanelContent key='filters' title='Select filters' closeAction={closeFilterAction} ref={refTitle}>
            <MapFilter />
          </PopOutPanelContent>
        )}

        {type === 'item-details' && item && DetailPanelComponent ? (
          <PopOutPanelContent
            key='selected-panel-item'
            title={evName ?? itemName}
            closeAction={closeAction}
            ref={refTitle}
          >
            <DetailPanelComponent item={item.properties as never} />
          </PopOutPanelContent>
        ) : null}
      </AnimatePresence>
    </Portal>
  )
}

export default PopOutPanel
