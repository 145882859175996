import { ReactElement } from 'react'
import { usePanToItem } from '../../../services/map/MarkerCollection'
import { TimeSignLine, TimSign } from '../../../types/JourneyPlannerTypes'
import PanelSection from '../../layout/panel-section'
import PanelFooter from '../panel-footer/index'

interface Props {
  item: TimSign
  itemLinks?: {
    prevUrl: string
    nextUrl: string
  }
}

export default function TrafficTimePanel({ item, itemLinks }: Props): ReactElement {
  const tConvert = (time: String) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1) // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
      time[0] = +time[0] % 12 || 12 // Adjust hours
    }
    return time.join('') // return adjusted time or original string
  }

  const parseTimeInfo = (signInfo: Array<TimeSignLine>) => {
    // eslint-disable-next-line array-callback-return
    return signInfo.map((line: any) => {
      if (line.center && line.center !== '') {
        return (
          <li className='c-info-list__item'>
            <p className='c-info-list__label'>{line.center}</p>
          </li>
        )
      }
      if (line.left && line.right) {
        return (
          <li className='c-info-list__item'>
            <p className='c-info-list__label'>{line.left}</p>
            {/* Sometimes the time comes through as a string which already has MINS to indicate time, so conditionally add this */}
            <p className='c-info-list__data'>{typeof line.right === 'string' ? line.right : `${line.right} MINS`}</p>
          </li>
        )
      }
    })
  }

  usePanToItem({ type: item?.type, id: item?.id, focus: true })

  return (
    <PanelSection>
      {item.name && <p>From {item.name}</p>}
      {item.updated && <p>Update time: {tConvert(item.updated)}</p>}
      <ul className='c-info-list'>{parseTimeInfo(item.page.line)}</ul>
      <PanelFooter footerLinks={itemLinks} />
    </PanelSection>
  )
}
