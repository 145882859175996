import { useEffect, useRef } from 'react'
import { Switch, Route, useParams } from 'react-router-dom'
import NavigationPanel from '../../navigation/navigation-panel/index'
import PanelSection from '../../layout/panel-section'
import LinkList, { LinkItemProps } from '../../common/link-list'
import { useRegionsState } from '../../../stores/national-data/regions'
import PanelSubNavigation from '../../navigation/navigation-panel/panel-sub-navigation'
import PanelHeader from '../../navigation/navigation-panel/panel-header'
import { useKeyJourneysInRegion } from '../../../stores/national-data/key-journeys'
import { AnimatePresence } from 'framer-motion'
import PopOutPanelContent from '../../common/pop-out-panel/content'
import { Portal } from '../../portal'
import useRegion from '../../../hooks/use-region'
import TrafficDashboardPanel from '../traffic-dashboard-panel'
import { JourneyLinkFeature, KeyJourney } from '../../../types/features'
import { prepareTimeForDisplay } from '../../traffic-dashboard'
import { useKeyJourneyMap, useKeyJourneyState } from '../../../services/map/KeyJourneyMap'
import { createState, useState as useHookState } from '@hookstate/core'
import useBoundedList from '../../../hooks/use-bounded-list'
import useTitle from '../../../hooks/use-title'

const isPanelOpen = createState(false)
export const useIsPanelOpen = () => {
  return useHookState(isPanelOpen)
}

export default function KeyJourneysPanel() {
  const keyJourneyState = useKeyJourneyState()

  useRegionsState()
  const region = useRegion({ panOnLoad: true })
  const { regionSlug, routeUrl } = useParams<{ regionSlug: string; routeUrl?: string }>()
  const pageTitle = region ? `Traffic information for ${region?.properties.name}` : 'Traffic information'
  useTitle(`${pageTitle} | NZTA Journey Planner`)

  const [loaded, keyJourneys] = useKeyJourneysInRegion(regionSlug)
  const isPanelOpenState = useIsPanelOpen()

  const journeyGroups: { SortOrder: number; Title: string; GroupID: number }[] = []

  keyJourneys?.forEach((journey) => {
    if (!journeyGroups.some((group) => group.GroupID === journey.properties.GroupID)) {
      journeyGroups.push({ GroupID: journey.properties.GroupID, ...journey.properties.JourneyGroup })
    }
  })

  const journeysByGroup = (GroupID: number) => {
    const groupedJourneys = keyJourneys.filter((journey) => journey.properties.GroupID === GroupID)
    const sortedJourneys = groupedJourneys.sort((a, b) => (a.properties.SortOrder > b.properties.SortOrder ? 1 : -1))

    const journeysToDisplay: LinkItemProps[] = sortedJourneys.map((kj) => {
      return {
        title: kj.properties.Title,
        url: kj.properties.URLSlug,
      }
    })
    return journeysToDisplay
  }

  const keyJourneyProperties = keyJourneys.map((x) => x.properties)
  const sortedJourneys = keyJourneyProperties.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1))

  const [selectedJourney] = useBoundedList(sortedJourneys, 'URLSlug', routeUrl)

  useEffect(() => {
    keyJourneyState.set(selectedJourney)
    // eslint-disable-next-line
  }, [selectedJourney, loaded])

  const scrollElementIntoView = (className: string, text?: string) => {
    const elements = document.getElementsByClassName(className)
    const arr = Array.from(elements)
    const listItem = arr?.find((node: any) => node.textContent.includes(text))
    text && listItem?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  useEffect(() => {
    const isTabletPortraitUp = window.innerWidth > 600
    isPanelOpenState.set(isTabletPortraitUp ? true : false)
    scrollElementIntoView('c-link-list__item', selectedJourney?.Title)
    // eslint-disable-next-line
  }, [routeUrl, selectedJourney])

  useKeyJourneyMap()

  const baseUrl = `/regions/${regionSlug}/traffic-dashboard/`

  const assembleTrafficSegments = (selectedJourney: KeyJourney) => {
    // If there aren't LinkIds (JourneyLink) to use to assemble "segments",
    // use properties from the KeyJourney to assemble one for the panel
    const journeyIds = selectedJourney?.LinkIds?.split(',') ?? [selectedJourney.ID]

    let links = selectedJourney.JourneyLinks
    if (!Array.isArray(selectedJourney.JourneyLinks)) {
      links = Object.values(selectedJourney.JourneyLinks)
    }

    let remaining = selectedJourney.CurrentTime

    const result = journeyIds.map((id: string) => {
      let timeRemaining = remaining > 0 ? prepareTimeForDisplay(remaining) : ''

      // If links, assemble segment for each link
      if (links.length > 0) {
        const link = links.find((l: JourneyLinkFeature) => l.properties.LinkId === parseInt(id))

        remaining -= link?.properties?.CurrentTime || 0

        const linkProperties = link?.properties
        return {
          journeyTitle: linkProperties?.Title ?? '',
          segmentTime: prepareTimeForDisplay(linkProperties?.CurrentTime),
          segmentDistance: `${linkProperties?.NiceTotalLength}km`,
          journeyTimeRemaining: timeRemaining,
          trafficChanges: linkProperties?.Trend ?? '',
          delayStatus: linkProperties?.Condition ?? 'green',
        }
      }
      // Else, return KeyJourney props to assemble one segment
      return {
        journeyTitle: selectedJourney?.Title ?? '',
        segmentTime: prepareTimeForDisplay(selectedJourney?.CurrentTime),
        segmentDistance: `${selectedJourney?.NiceLength}`,
        journeyTimeRemaining: timeRemaining,
        trafficChanges: '',
        delayStatus: selectedJourney?.FreeFlowState ?? 'green',
      }
    })
    return result
  }

  const switchDirectionUrl = () => {
    const returnUrl = keyJourneys.find((j) => j.properties.ID === selectedJourney?.ReturnJourneyID)?.properties.URLSlug

    return returnUrl ? `${baseUrl}${returnUrl}` : null
  }

  const refTitle = useRef(null)
  useEffect(() => {
    if (selectedJourney && isPanelOpenState.get()) refTitle.current.focus()
  }, [selectedJourney, isPanelOpenState])

  return (
    <NavigationPanel className='c-key-journey__nav-panel'>
      <PanelHeader noHeadingMargin title={`Traffic dashboards`} borderBottom={false} />
      <Switch>
        <Route path={`/regions/${regionSlug}/traffic-dashboard`}>
          <PanelSubNavigation
            title={`Traffic information for ${region?.properties.name}`}
            navLayout='titleBelow'
            prevLabel={`Traffic dashboard for ${region?.properties.name}`}
            prevUrl={`/regions/${regionSlug}/traffic-dashboard`}
          />
          <PanelSection hasVerticalPadding={false} hasHorizontalPadding={false}>
            {journeyGroups
              .sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1))
              .map((group) => (
                <LinkList
                  onClick={() => isPanelOpenState.set(true)}
                  links={journeysByGroup(group.GroupID)}
                  className='c-key-journey__list'
                  type='nav'
                />
              ))}
          </PanelSection>
        </Route>
      </Switch>
      <Portal>
        <AnimatePresence exitBeforeEnter={true}>
          {!!selectedJourney && isPanelOpenState.get() && (
            <PopOutPanelContent
              key='selected-journey'
              title={selectedJourney.Title ?? ''}
              ref={refTitle}
              closeAction={() => {
                isPanelOpenState.set(false)
                const activeLink = document.getElementsByClassName('c-link-list__link--active')[0]
                activeLink.focus()
              }}
            >
              <TrafficDashboardPanel
                trafficSegments={assembleTrafficSegments(selectedJourney)}
                totalLength={selectedJourney.NiceLength}
                currentTime={selectedJourney.CurrentTime}
                speed={selectedJourney.NiceSpeed}
                returnJourneyUrl={switchDirectionUrl()}
              />
            </PopOutPanelContent>
          )}
        </AnimatePresence>
      </Portal>
    </NavigationPanel>
  )
}
