import React from 'react'
import classNames from 'classnames'

interface PanelSection {
  className?: string
}

/*
  Applies consistent padding around page content
  There should only be one <Content> component per page
*/
export default function Content({ children, className }: React.PropsWithChildren<PanelSection>) {
  const classes = classNames('l-content', className ?? '')

  return (
    <main className={classes}>
      <div className='l-content__wrapper'>{children}</div>
    </main>
  )
}
