import { useEffect, useMemo } from 'react'
import { createState, useState } from '@hookstate/core'
import useRegions from '../../hooks/use-regions'
import { KeyJourneyFeature } from '../../types/features'

let loadStarted = false

const useLoadIfNeeded = () => {
  useEffect(() => {
    // TODO initiate a refresh every 5 minutes?
    if (!journeyState.get() && !loadStarted) {
      loadData()
    }
  }, [])
}

const journeyState = createState(0)

let keyJourneyData: KeyJourneyFeature[] = []

export const useKeyJourneysState = () => {
  useLoadIfNeeded()
  return useState(journeyState)
}
export const getKeyJourneys = () => {
  return keyJourneyData
}

async function loadData() {
  loadStarted = true
  const response = await fetch('/api/keyjourneys', { headers: { Accept: 'application/json' } })
  const data = await response.json()
  loadStarted = false
  // TODO what is returned here if load fails? And how should we handle that?
  if (data) {
    keyJourneyData = Object.values(data.features)
    journeyState.set(1)
  }
}

export const useKeyJourneysInRegion = (regionSlug: string): [boolean, KeyJourneyFeature[]] => {
  const { regions } = useRegions()
  const journeysLoaded = useKeyJourneysState()
  const region = regions.find((r) => r.properties.slug === regionSlug)

  const keyJourneys = useMemo(() => {
    if (journeysLoaded.get()) {
      const data = getKeyJourneys()

      if (region?.properties?.id) {
        return data.filter((journey) => journey.properties.RegionID === region?.properties?.id)
      }
      return []
    }
    return []
  }, [journeysLoaded, region])

  return [!!journeysLoaded.get(), keyJourneys]
}
