import React, { ReactElement, useEffect, useState } from 'react'
import { useDelaysState } from '../../../../stores/national-data/delays'
import { fromUnixTime, formatDistanceToNow } from 'date-fns'

interface TableHeaderProps {
  heading: string
}

export default function TableHeader({ heading }: TableHeaderProps): ReactElement {
  const delaysUpdatedState = useDelaysState()
  const [updatedTime, setUpdatedTime] = useState(delaysUpdatedState.value)
  useEffect(() => {
    setUpdatedTime(delaysUpdatedState.value)
  }, [delaysUpdatedState.value])

  const lastUpdatedTime = formatDistanceToNow(fromUnixTime(updatedTime))

  return (
    <div className='l-traffic-table__heading'>
      <caption>{heading}</caption>
      <p>Last updated: {lastUpdatedTime} ago</p>
    </div>
  )
}
