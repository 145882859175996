import useRegion from '../../../hooks/use-region'

type WeatherWidgetProps = {
  days?: string
}

export default function WeatherWidget({ days = 'days-1' }: WeatherWidgetProps) {
  const region = useRegion()
  const weatherLocation = region?.properties?.weatherLocation

  return (
    <>
      {weatherLocation && (
        <iframe
          title='regions-weather-widget'
          className='l-weather-widget'
          src={`https://www.metservice.com/weather-widget/widget?params=white|small|landscape|${days}|classic&loc=${weatherLocation}&domain=${document.location.hostname}`}
          allowTransparency={true}
          aria-hidden='true'
        />
      )}
    </>
  )
}
