import React from 'react'
import Icon from '../../../common/icon'

interface RouteClearProps {
  handleClear: React.MouseEventHandler<HTMLButtonElement> | undefined,
  isVisible: boolean
}
export const RouteClear = ({ handleClear, isVisible}: RouteClearProps) => {
  return (
    <button className={ isVisible ? `c-input-container__icon-btn` : `c-input-container__icon-btn c-input-container__icon-btn--hidden`} title='Clear field' type='button' onClick={handleClear}>
      <Icon icon='cross-circle' ariaLabel={'Clear field'} />
    </button>
  )
}

export default RouteClear
