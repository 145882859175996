import classNames from 'classnames'
import { useOnHighwayConditions } from './app/pages/HighwayConditions'
import { useOnHolidayJourney } from './app/routes/holiday-journeys'
import Router from './app/routes/router'
import { useOnTrafficUpdate } from './app/routes/traffic-update'
import {
  useActiveSearchState,
  useInvalidSearchState,
  useMapFullscreenState,
} from './app/stores/journey-planner/JourneySearchState'
import { useKeyJourneysState } from './app/stores/national-data/key-journeys'
import { useActiveTabState } from './app/stores/TabNavigationState'
import { TabTypes } from './app/types/tab-name-types'
import './styles/app.scss'

/**
 * Initialise basic app content
 * Conditionally set app styles depending on some state
 */
const App = () => {
  const activeTabState = useActiveTabState().get()
  const invalidSearchState = useInvalidSearchState().get()
  const activeSearchState = useActiveSearchState().get()
  const mapFullscreenState = useMapFullscreenState().get()
  const keyJourneyState = useKeyJourneysState().get()
  const onHolidayJourneystate = useOnHolidayJourney().get()
  const onTrafficUpdateState = useOnTrafficUpdate().get()
  const onHighwayConditions = useOnHighwayConditions().get()

  const mapToolWrapperClasses = classNames('l-map-tool__wrapper', {
    'l-map-tool--map-view-active': activeTabState === TabTypes.MAP,
    'l-map-tool--list-view-active': activeTabState === TabTypes.LIST,
    'l-map-tool--table-view-active': activeTabState === TabTypes.TABLE,
    'l-map-tool--map-area-fullscreen': mapFullscreenState,
    'l-map-tool--activeSearch': !invalidSearchState && activeSearchState,
    'l-map-tool--selected-search':
      keyJourneyState || !!onHolidayJourneystate || !!onTrafficUpdateState || !!onHighwayConditions,
  })

  return (
    <div className={mapToolWrapperClasses}>
      <Router />
      {/* TODO add a map heading/title for a11y, and work out how to give the iframe a title attr */}
      {/* TODO worth adding a keyboard-only "skip" link to jump to footer? */}
    </div>
  )
}

export default App
